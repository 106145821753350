import React,{ Dispatch,SetStateAction,useState,useMemo } from 'react';
import { Modal } from './Modal';
import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';
import { Body } from './Body';
import { addProject } from "services/project";
import { InputTextField } from "components/InputField";
import Textarea from "../Textarea";
import Toast from "../Toast";

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}

const AddProjectModal = ({ isShow,setIsShow,setToast,setRefreshData }: Iprops) => {
    const [toggle,setToggle] = useState(1);
    const [option,setOption] = useState('find covered product');
    const [isOpen,setIsOpen] = useState(false);
    const [projectName,setProjectName] = useState('');
    const [projectDescription,setProjectDescription] = useState('');

    const selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

    // Validation check for project name
    const isProjectNameValid = useMemo(() => {
        return projectName.trim().length > 0;
    },[projectName]);

    const onCloseModal = () => {
        setIsShow(false);
    };

    const handleProjectNameChange = (e: any) => {
        setProjectName(e.target.value);
    };

    const handleProjectDescriptionChange = (e: any) => {
        setProjectDescription(e.target.value);

        let getTextArea = document.getElementById("project-desc");
        if (getTextArea) {
            if (e.target.value.length === 0) {
                e.target.style.height = "24px";
            } else {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
            }
        }
    };

    const handleSubmit = async (event: any) => {
        event?.preventDefault();

        if (!isProjectNameValid) {
            return;
        }

        const payload = {
            name: projectName.trim(),
            description: projectDescription.trim(),
            type: !toggle ? 'Find Prior Art' : 'Find Covered Products',
            team_id: selectedTeam.currentTeamId
        };

        const { status } = await addProject(payload);

        setProjectName('');
        setProjectDescription('');
        setToggle(0);
        setIsShow(false);
        setRefreshData(prev => !prev);
        Toast.success('Success','Project added successfully');
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[480px]'} isShow={isShow}>
                <form onSubmit={handleSubmit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between pb-7'>
                            <img className='h-10 w-10' src={folderPlus} alt='remove' />
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Create New Project</h2>
                        <p className='text-sm text-[#4F4D55] font-interRegular'>Choose the type of project you want to create.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-7'>
                        <div className='pb-5'>
                            <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block pb-2'>
                                Project Name
                            </label>
                            <InputTextField
                                className='placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full'
                                name={'name'}
                                placeholder={'Enter Project Name'}
                                onChange={handleProjectNameChange}
                                value={projectName}
                            />
                        </div>
                        <div className='flex flex-col gap-y-[5px] gap-x-6'>
                            <h5 className='text-[#4F4D55] text-sm font-interMedium pb-1'>I want to</h5>
                            <div className='flex gap-4 items-center pb-3'>
                                <label className='cursor-default pointer-events-none opacity-50 flex gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-1'>
                                        <div
                                            className={`w-4 h-4 rounded-full border-2 transition-colors cursor-default pointer-events-none ${option === 'prior art' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-[#DCDCDE] bg-white hover:border-[#1751D0]'}`}>
                                            {option === 'prior art' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            disabled
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(0);
                                                setOption('prior art');
                                            }}
                                            checked={option === 'prior art'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Find prior art</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Check if my claims are safe to file</h6>
                                    </div>
                                </label>
                                <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-1'>
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                            ${option === 'find covered product' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-[#DCDCDE] bg-white hover:border-[#1751D0]'}`}>
                                            {option === 'find covered product' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(1);
                                                setOption('find covered product');
                                            }}
                                            checked={option === 'find covered product'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Find covered products</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Find products/claims similar to my claims</h6>
                                    </div>
                                </label>
                            </div>
                            <div className=''>
                                <div className='flex justify-between pb-2'>
                                    <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block'>
                                        Description
                                    </label>
                                    <label htmlFor='' className='font-interRegular text-xs text-[#7F7D83]'>
                                        Optional
                                    </label>
                                </div>
                                <Textarea
                                    name="project-desc"
                                    placeholder="Enter description"
                                    value={projectDescription}
                                    onChange={handleProjectDescriptionChange}
                                    id='project-desc'
                                />
                            </div>
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button
                                onClick={onCloseModal}
                                className='text-[#4F4D55] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'
                            >
                                Cancel
                            </button>
                            <button
                                className={`!text-base text-white font-interSemiBold bg-[#1E0AF5] hover:bg-[#113B98] rounded-lg px-3.5 py-2.5 flex-1 ${isProjectNameValid
                                    ? ''
                                    : 'button-disabled'
                                    }`}
                                type='submit'
                                disabled={!isProjectNameValid}
                            >
                                Create
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default AddProjectModal;