const ClaimElement = ({ claim, type = null }: any) => {
  return (
    <div className="">
      {type === "vijilize" && (
        <div className="px-4 py-3 border-b border-[#CED2DA]">
          <p className="text-base font-interSemiBold text-[#0A090B]">
            Claim Features
          </p>
          <p className="leading-none opacity-0">
            <span className="text-xs text-[#0A090B] font-interMedium">
              Similarity Score:
            </span>
            <span className="text-xs font-interSemiBold text-[#1D7C4D]">
              {" "}
              80%
            </span>
          </p>
        </div>
      )}

      <div className="px-5 py-4">
        {claim.preamble && (
          <div>
            <span style={{ color: claim.hex_code }}>{claim.preamble}</span>
          </div>
        )}
        {claim.elements &&
          claim.elements.map((element: any, elemIndex: number) => (
            <div key={elemIndex} className="ml-7">
              <span style={{ color: element.hex_code }}>
                {element.description}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClaimElement;
