import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {Modal} from './Modal';
import {HeaderModal} from './Header';
import {Body} from './Body';
import {FooterModal} from './Footer';

import allImgPaths from 'assets/images';

import {InputTextField} from 'components/InputField';
import {uploadFileToS3} from 'services/auth/auth';
import {createUserPreSignedUrl, getUserProfileImage, updateUserProfile} from 'services/user';
import {ALLOWED_IMAGE_MIME_TYPES, PROFILE_IMAGE_MAX_FILE_SIZE} from 'utils/constant';
import {cloneDeep, isEqual} from 'lodash';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setFetchNewData: any;
}

let updatedFileName = '';

const UserProfile = ({isShow, setIsShow, setFetchNewData}: Iprops) => {
    const actualBtnRef = useRef<any>(null);

    const onCloseModal = () => {
        setIsShow(false);
    };

    const [error, setError] = useState('');

    const userData = JSON.parse(localStorage.getItem('userData') as string);

    const [formData, setFormData] = useState({
        name: userData?.name || '',
        imageUrl: userData?.profile_image || allImgPaths.defaultProfile,
    });
    const clonedFormData = useRef<any>(
        cloneDeep({
            name: userData?.name || '',
            imageUrl: userData?.profile_image || allImgPaths.defaultProfile,
        })
    );
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);

    useEffect(() => {
        areArraysEqual(formData, clonedFormData.current);
    }, [formData]);

    const areArraysEqual = (original: any, cloned: any) => {
        const isSame = isEqual(original, cloned);
        setSaveBtnDisabled(isSame);
    };

    const updateImage = async (selectedFiles: FileList) => {
        if (!selectedFiles.length) {
            return;
        }

        const file = selectedFiles[0];

        if (!ALLOWED_IMAGE_MIME_TYPES.includes(file.type)) {
            setError('Only .jpg, .jpeg and .png files are allowed');
            actualBtnRef.current.value = '';
            return;
        } else {
            setError('');
        }

        if (file?.size > PROFILE_IMAGE_MAX_FILE_SIZE) {
            setError('Max file length should be 5 MB');
            actualBtnRef.current.value = '';
            return;
        } else {
            setError('');
        }

        try {
            const fileData = {
                file_name: file.name,
                content_type: file.type,
                category: 'user_profile',
            };

            const {data} = await createUserPreSignedUrl(fileData);

            const payload = {
                url: data.url,
                file: file,
                type: file.type,
            };

            if (data) {
                const response = await uploadFileToS3(payload);

                updatedFileName = data?.fileName || '';

                if (response.status === 200) {
                    const getUrl: any = await getUserProfileImage({category: 'user_profile', file_name: data.fileName});

                    setFormData({
                        ...formData,
                        imageUrl: getUrl,
                    });
                }
            }
        } catch (error) {
            console.log('Error while generating the presigned url =', error);
        }
    };

    const handleNameChange = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            name: value,
        });
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        updateImage(droppedFiles);
    };

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleUploadError = (e: any) => {
        e.target.src = allImgPaths.defaultProfile;
    };

    const submitForm = async (event: any) => {
        event?.preventDefault();

        let payload: any = {
            name: formData.name,
        };

        if (updatedFileName) {
            payload['profile_image'] = updatedFileName;
        }

        try {
            await updateUserProfile(payload);

            updatedFileName = '';
            onCloseModal();
            setFetchNewData(true);
        } catch (error) {
            console.log({error});
        }
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[480px]'} isShow={isShow}>
                <form onSubmit={submitForm}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='pb-5'>
                            <h2 className='text-lg text-[#101828] font-interSemiBold leading-none pb-1.5'>Your Profile</h2>
                            <p className='text-sm text-[#475467]'> View or update your profile.</p>
                        </div>
                    </HeaderModal>
                    <Body title='' isShowBody={true} className='modal-body px-6'>
                        <div className='flex gap-x-5 pb-4' onDrop={handleDrop} onDragOver={onDragOver}>
                            <img
                                className='w-16 h-16 rounded-full object-cover'
                                // src={userProfile?.profile_image || allImgPaths.dummyImg}
                                // src={formData?.imageUrl || allImgPaths.dummyImg}
                                src={formData?.imageUrl}
                                alt=''
                                onError={(e: any) => handleUploadError(e)}
                            />
                            <div className='w-full'>
                                <input
                                    type='file'
                                    id='actual-btn'
                                    ref={actualBtnRef}
                                    // onChange={updateImage}
                                    onChange={(e: any) => updateImage(e.target.files)}
                                    maxLength={PROFILE_IMAGE_MAX_FILE_SIZE}
                                    hidden
                                    accept='.svg,.jpg,.jpeg,.png'
                                />
                                <label className='file-upload block border border-[#EAECF0] hover:border-[#113B98] rounded-xl py-4 px-6 text-center cursor-pointer' htmlFor='actual-btn'>
                                    <img className='mb-3 mx-auto' src={allImgPaths.uploadFileIcon3} alt='' />
                                    <div className=''>
                                        <span className='text-sm text-[#1751D0] font-interSemiBold'>Click to upload</span>
                                        <span className='text-sm text-[#475467]'> or drag and drop</span>
                                        <p className='text-sm text-[#475467]'>JPG, JPEG OR PNG (max. 1 MB)</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                        {error && <p className='text-[#E12121] text-sm mb-2'>{error}</p>}
                        <div className=''>
                            <span className='text-[#344054] text-sm font-interMedium pb-1.5 inline-block'>Profile Name*</span>
                            <InputTextField
                                className='placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full'
                                name={'name'}
                                placeholder={'Enter Profile Name'}
                                onChange={handleNameChange}
                                // value={userProfile?.name}
                                value={formData.name}
                            />
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-8 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-end'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] hover:bg-[#f2f4f7] rounded-lg tracking-wide py-2.5 px-4 !text-base'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg tracking-wide px-5 py-2.5 ${saveBtnDisabled ? 'button-disabled' : ''}`}>Save Profile</button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default UserProfile;
