import React, { useEffect, useRef, useState } from 'react';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ConfigureScoring from 'components/common/modals/ConfigureScoring';
import { getPatentVijilizedDetails } from "services/project";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import ClaimAnalysisShimmerLoader from "pages/loaders/ClaimAnalysisLoader";
import useAppState from 'context/useAppState';
import CreateClaimChart from "components/common/modals/CreateClaimChart";
import CustomSelect from 'components/common/CustomSelect';
import ClaimData from 'components/ClaimData';
import CitationsSidebar from 'components/CitationsSidebar';


interface Claim {
    elements: {
        description: string;
        figure: string;
        hex_code: string;
        legend: { [key: string]: string }[];
        figurePreSignedUrl: string;
    }[];
    similarityScore: number | null;
    confidenceScore: number | null;
}

interface Patent {
    patentId: string;
    patentNumber: string;
    claims: { [key: string]: Claim };
    structure: string;
}

interface APIData {
    patents: Patent[];
    similarity_results: { [key: string]: any[] };
    name: string;
    description: string;
}

interface ActiveIndex {
    patentId: string | null;
    claimIndex: number | null;
}

const ClaimAnalysisVijilize: React.FC = () => {
    const [toggle, setToggle] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<ActiveIndex>({ patentId: null, claimIndex: null });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { id } = useParams<{ id: string; }>();
    const [showConfidence, setShowConfidence] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedClaim, setSelectedClaim] = useState<Claim | null>(null);
    const [selectedSimilarity, setSelectedSimilarity] = useState<any[]>([]);
    const [tempData, setTempData] = useState<any[]>([]);
    const [claimSelectionLoader, setClaimSelectionLoader] = useState<boolean>(false);
    const [isShowSideBar, setIsShowSideBar] = useState<boolean>(false);
    const [isShowSideBarCitations, setIsShowSideBarCitations] = useState<boolean>(false);
    const [toggledDataUsed, setToggledDataUsed] = useState<any>(0);

    const [selectedSimilarityItems, setSelectedSimilarityItems] = useState<{ [key: string]: boolean }>({});

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [createClaimChartData, setCreateClaimChartData] = useState<any[]>([])

    const [patentClaimPairs, setPatentClaimPairs] = useState<any>([]);
    

    const [persistedSelections, setPersistedSelections] = useState<{
        [claimKey: string]: {
            [checkboxId: string]: boolean
        }
    }>({});

    const itemsRef = useRef<Map<string, HTMLInputElement>>(new Map());

    const [configurationobj, setConfigurationobj] = useState<any>({
        "specification": 0,
        "prosecution": 90,
        "precedential": 50,
        "dictionary": 55,
        "articleAccuracy": 65
    });
    const { getVijilizeParamsData, setHeaderDataLocal, clickedHeatmapData } = useAppState('vijilize');

    const filterRef = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(filterRef, () => {
        setShowConfidence(false);
        setIsOpen(false);
    });

    const [toast, setToast] = useState<{ show: boolean; heading: string; message: string }>({
        show: false,
        heading: '',
        message: '',
    });

    const [openConfigureScoreModal, setConfigureScoreModal] = useState<boolean>(false);
    const [openCreateClaim, setOpenCreateClaim] = useState<boolean>(false);

    const [APIData, setAPIData] = useState<any | null>(null);
    const [summarizedAPIData, setSummarizedAPIData] = useState<any | null>(null);
    const [claimsData, setClaimsData] = useState<{ [key: string]: Patent }>({});
    const [filteredClaimsData, setFilteredClaimsData] = useState<{ [key: string]: Patent }>({});
    const [claimStructureData, setClaimStructureData] = useState<string | null>(null);
    const [chartButtonText, setChartButtonText] = useState<{ [key: string]: string }>({});
    const [sourceOptions, setSourceOptions] = useState<any[]>([]);
    const [claimOptions, setClaimOptions] = useState<any[]>([]);
    const [selectedSourceOption, setSelectedSourceOption] = useState<string>('all');
    const [selectedClaimOption, setSelectedClaimOption] = useState<string>('all');
    const [isButtonEnabled, setIsButtonEnabled] = useState<{ [key: string]: boolean }>({});    


    const toggleAccordion = (patentId: string, claimIndex: number, claimKey: string) => {    
        const persistedSelectionsKey = `${patentId}_${claimKey}`
        const storedSelections = persistedSelections[persistedSelectionsKey] || {};
        setSelectedSimilarityItems(storedSelections);
    
        const currentId = `${patentId}_id${claimKey}check`;
        const checkboxElement: any = itemsRef.current.get(currentId);
    
        if (checkboxElement) {
            if (!checkboxElement.checked) {
                checkboxElement.checked = !checkboxElement.checked;
            }
    
            itemsRef.current.forEach((element, key) => {
                const eleId = element.id;
                const elePid = eleId.split("_id")[0];
                const eleClaim = eleId.split("_id")[1].split("check")[0];
                const trueFlag = generateSelectedProd(eleClaim, elePid);
    
                if (key.startsWith(patentId) && key !== currentId && !trueFlag) {
                    element.checked = false;
                }
            });
        }
    
        setActiveIndex(prev => ({
            patentId: prev.patentId === patentId && prev.claimIndex === claimIndex ? null : patentId,
            claimIndex: prev.patentId === patentId && prev.claimIndex === claimIndex ? null : claimIndex
        }));
    
        setClaimSelectionLoader(true);
        setSelectedClaim(claimsData[patentId]?.claims[claimKey]);
    
        const summarizedData = summarizedAPIData.find((item: any) => item[patentId]);
        const tempDataValue = summarizedData[patentId][claimKey] || [];
        setTempData(tempDataValue);
    
        const filteredData = tempDataValue.filter((obj: any) => {
            return obj.score >= configurationobj.specification;
        });
    
        setSelectedSimilarity(filteredData);
    
        setTimeout(() => {
            setClaimSelectionLoader(false);
        }, 1000);
    
        // Clear selectedSimilarityItems when navigating away
        if (activeIndex.claimIndex !== null && activeIndex.claimIndex !== claimIndex) {
            setSelectedSimilarityItems({});
        }
    };

    // handleScoring =
    const handleScoring = (data: any) => {
        let filteredData = tempData.filter((obj: any) => {
            return obj.score >= data.specification;
        })
        setSelectedSimilarity(filteredData);
    }    

    const handleAddToChart = (patentId: string, claimIndex: number, claimKey:any) => {
        const currentClaim = `claim${claimIndex + 1}`;
        const currentClaimWithPatentId = `${patentId}_claim${claimIndex + 1}`;
        const storedSelections = selectedSimilarityItems;
        const selectedItems = Object.keys(storedSelections).filter(key => storedSelections[key]);
    
        let tempPatentClaimPairs: any[] = [...patentClaimPairs];
        const existingPairIndex = tempPatentClaimPairs.findIndex(pair => pair.patentId === patentId && pair.claimKey === claimKey);
    
        if (selectedItems.length === 0) {
            if (existingPairIndex !== -1) {
                tempPatentClaimPairs.splice(existingPairIndex, 1);
            }
            setChartButtonText(prev => ({
                ...prev,
                [currentClaimWithPatentId]: 'Add to Vijil Chart',
            }));
            setIsButtonEnabled(prev => ({ ...prev, [currentClaimWithPatentId]: false }));
        } else {
            if (existingPairIndex !== -1) {
                tempPatentClaimPairs[existingPairIndex].targetDocumentId = selectedItems.map(key => key.split('_id')[1].split('check')[0]);
            } else {
                tempPatentClaimPairs.push({
                    patentId: patentId,
                    claimKey: claimKey,
                    targetDocumentId: selectedItems.map(key => key.split('_id')[1].split('check')[0]),
                });
            }
    
            setChartButtonText(prev => ({
                ...prev,
                [currentClaimWithPatentId]: 'Add to Vijil Chart',
            }));
            setIsButtonEnabled(prev => ({ ...prev, [currentClaimWithPatentId]: false }));
        }
    
        setPatentClaimPairs(tempPatentClaimPairs);
        setPersistedSelections(prev => ({
            ...prev,
            [currentClaimWithPatentId]: storedSelections,
        }));
    };
    
    const handleSelect = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
        event.stopPropagation();
        const isChecked = event.target.checked;
        const currentClaim = `${activeIndex.patentId}_claim${(activeIndex.claimIndex || 0) + 1}`;
        const checkboxId = `claim${(activeIndex.claimIndex || 0) + 1}cid${data.patentId}_id${data.targetDocumentId}check`;
    
        setSelectedSimilarityItems(prev => ({
            ...prev,
            [checkboxId]: isChecked,
        }));
    
        const updatedSelectedItems = {
            ...selectedSimilarityItems,
            [checkboxId]: isChecked,
        };
    
        const selectedItems = Object.keys(updatedSelectedItems).filter(key => updatedSelectedItems[key]);
        const previousSelections = persistedSelections[currentClaim] || {};
    
        const isSelectionChangedForPersistedData = Object.keys(previousSelections).some(
            key => updatedSelectedItems[key] !== previousSelections[key]
        );
    
        if (
            Object.keys(previousSelections).length > 0 && 
            (isSelectionChangedForPersistedData || selectedItems.some(item => !previousSelections[item])) 
        ) {
            setIsButtonEnabled(prev => ({ ...prev, [currentClaim]: true }));
            setChartButtonText(prev => ({
                ...prev,
                [currentClaim]: 'Update Vijil Chart', 
            }));
        } else {
            setIsButtonEnabled(prev => ({ ...prev, [currentClaim]: selectedItems.length > 0 }));
            setChartButtonText(prev => ({
                ...prev,
                [currentClaim]: 'Add to Vijil Chart', 
            }));
        }

        
    };

    const handleDropdownSelect = (event: React.ChangeEvent<HTMLInputElement>, type: 'source' | 'claim', data: any) => {
        if (type === 'source') {
            setSelectedSourceOption(data.value);
            if(data.value !== 'all'){
                activeIndex.claimIndex = 0;
                activeIndex.patentId = data.value;
            }
            updateClaimOptions((data.value === 'all' ? 'all' : data.value),claimsData);
        } else if (type === 'claim') {
            setSelectedClaimOption(data.value)
        }
    };

    const updateClaimOptions = (patentId: string,claimsData:any) => {
        if (patentId === 'all') {            
            setClaimOptions(createClaimOptions('all',claimsData));
        } else {
            setClaimOptions(createClaimOptions(patentId,claimsData));
        }
    };

    const filterClaimsData = (sourceOption: string,type:string, claimOption:string) => {
        setClaimSelectionLoader(true);

        if(type === 'source'){
            setSelectedClaimOption('all')
            if (sourceOption === 'all') {
                setFilteredClaimsData(claimsData);
                if((Object.keys(claimsData).length > 0)){
                    setActiveIndex(prev => ({
                        patentId: activeIndex.patentId ,
                        claimIndex: activeIndex.claimIndex
                    }));
                setTimeout(()=>{
                        const element:any = document.getElementById(`${activeIndex.patentId}_${activeIndex.claimIndex}`);
                        element.scrollIntoView({ behavior: 'smooth' });
                    },1000)
                }
            } else {
                const filtered = {
                    [sourceOption]: claimsData[sourceOption] 
                };
                setFilteredClaimsData(filtered);
                setTimeout(()=>{
                        const element:any = document.getElementById(`${selectedSourceOption}_0`);
                        element.scrollIntoView({ behavior: 'smooth' });
                },1000)
            }
            
        }else if(type === 'claim'){
            if (claimOption === 'all') {
                filterClaimsData(selectedSourceOption,'source',selectedClaimOption);
                
                // setFilteredClaimsData(claimsData); 
            }else {
                const claimOptionData :any= claimOption.split('_');
                const claimKey = claimOptionData && claimOptionData[1];
                const sourceId = claimOptionData && claimOptionData[0];

                if(claimKey && sourceId){

                    const filteredClaims: any = {}; 
                    
                    if (claimsData[sourceId]) {
                    const patentClaims = claimsData[sourceId].claims;
                    
                    if (patentClaims[claimKey]) {
                        filteredClaims[sourceId] = {
                            claims: {
                                [claimKey]: patentClaims[claimKey],
                            },
                            patentId : claimsData[sourceId].patentId,
                            patentNumber : claimsData[sourceId].patentNumber,
                            structure : claimsData[sourceId].structure
                        };
                    }
                }

                const claimIndex = 0; 
                setActiveIndex(prev => ({
                    patentId: sourceId ,
                    claimIndex: claimIndex
                }));
                setSelectedClaim(claimsData[sourceId]?.claims[claimKey]);
                setTimeout(()=>{
                    setFilteredClaimsData(filteredClaims);
                    const element:any = document.getElementById(`${sourceId}_${claimIndex}`);
                element.scrollIntoView({ behavior: 'smooth' });
                },1000)
            }
        }
        setTimeout(() => {
            setClaimSelectionLoader(false);
        }, 1000);
    }
};
    

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const vijilizeParamsData = await getVijilizeParamsData()
            let patentData = vijilizeParamsData && await getPatentVijilizedDetails(vijilizeParamsData?.patent_ids || [], vijilizeParamsData?.compare_id || '0');
            setAPIData(patentData);
            setHeaderDataLocal((prev: any) => {
                return {
                    ...prev,
                    vijilize: { title: patentData?.name, id: patentData?._id },
                };
            });
            const claimsD=patentData?.patents?.reduce((acc: { [key: string]: Patent }, patent: Patent) => {
                acc[patent.patentId] = patent;
                return acc;
            }, {});
            setClaimsData(claimsD);
            
            let patentId = clickedHeatmapData?.sourceId || patentData?.patents[0]?.patentId
            
            let claimKey = clickedHeatmapData?.claimKey || Object.keys(patentData.patents[0]?.claims)[0];
            const claimIndex = Number(claimKey.split('claim')[1]) - 1;
            
            setActiveIndex({
                patentId: patentId,
                claimIndex: claimIndex
            })
            setSummarizedAPIData(patentData?.summarized_similarity_results);

            const summarizedData = patentData?.summarized_similarity_results?.find((item: any) => item[patentId])
            setSelectedClaim(patentData?.patents?.find((patent: Patent) => patent.patentId === patentId).claims[claimKey]);

            setTempData(summarizedData[patentId][claimKey]);

            let filteredData = summarizedData[patentId][claimKey].filter((obj: any) => {
                return obj.score >= configurationobj.specification;
            })

            setSelectedSimilarity(filteredData);
            const sourceOptionsData = createSourceOptions(patentData.patents,);
            setSourceOptions(sourceOptionsData);
            if (patentData.patents.length > 0) {
                updateClaimOptions(patentData.patents[0].patentId,claimsD);
            }
            setIsLoading(false);
            setClaimSelectionLoader(true);
            setPersistedSelections({
                [claimKey]: {}
            });
            setIsDataLoaded(true);

            setTimeout(() => {
                setClaimSelectionLoader(false);
                const element:any = document.getElementById(`${patentId}_${claimIndex}`);
                element.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        })();
    }, []);

    // Second useEffect to handle refs after data is loaded
    useEffect(() => {
        if (!isDataLoaded || !APIData?.patents[0]?.patentId) return;

        const firstPatentId = APIData.patents[0].patentId;
        const firstCheckboxId = `${firstPatentId}_idclaim1check`;

        itemsRef.current.forEach((element, key) => {
            element.checked = key === firstCheckboxId;
        });

        setClaimSelectionLoader(false);
    }, [isDataLoaded, APIData]);

    useEffect(() => {
        
        if (activeIndex.claimIndex !== null) {
            const currentClaim = `${activeIndex.patentId}_claim${activeIndex.claimIndex + 1}`;
            const storedSelections = persistedSelections[currentClaim] || {};
            setSelectedSimilarityItems(storedSelections);
        }
    }, [activeIndex.claimIndex, persistedSelections]);       

    useEffect(() => {
        filterClaimsData(selectedSourceOption,'source',selectedClaimOption);
    }, [claimsData, selectedSourceOption]);

    useEffect(() => {
        filterClaimsData(selectedSourceOption,'claim',selectedClaimOption);
    }, [claimsData, selectedClaimOption]);

    const handleCreateClaimChart = async (e: any) => {
        const vijilizeParamsData = await getVijilizeParamsData()
        let compareId = vijilizeParamsData?.compare_id || '0';

        patentClaimPairs.map((item: any) => { return item.compareId = compareId })

        // const filteredres: any = filterDataBothWithTarget(APIData.patents,APIData.summarized_similarity_results,patentClaimPairs);
        setCreateClaimChartData(patentClaimPairs);
        setOpenCreateClaim(true);
    }
    const setRef = (id: string) => (element: HTMLInputElement | null) => {
        if (element) {
            itemsRef.current.set(id, element);
        } else {
            itemsRef.current.delete(id);
        }
    };

    const generateSelectedProd = (claimKey: string, patentId: string) => {
        if (!patentClaimPairs.length) {
            return 0;
        }
        const matchingPair = patentClaimPairs.find((pair: any) =>
            pair.patentId === patentId &&
            pair.claimKey === claimKey
    );

        if (!matchingPair || !matchingPair.targetDocumentId) {
            return 0;
        }

        return matchingPair.targetDocumentId.length;
    }

    const createSourceOptions:any =(data:any) =>{
        const patentOptions = data.map((item:any) => ({
            value: item.patentId, 
            label: item.patentNumber 
        }));
        
        const allOption = { value: 'all', label: 'All' };
        
        return [allOption, ...patentOptions];
    }

    const createClaimOptions = (patentId: any,claimsData:any) => {
        const claimOptions = [];
    
        claimOptions.push({
            value: 'all',
            label: 'All'
        });

        if (patentId === 'all'){
        const allClaimsData:any = Object.values(claimsData).flatMap(patent => patent);
        allClaimsData.forEach((patent:any) => {
            Object.keys(patent.claims).forEach(claimKey => {
                claimOptions.push({
                    value: `${patent.patentId}_${claimKey}`, 
                    label: claimKey 
                });
            });
        });
        } else {
            if((Object.keys(claimsData).length > 0)){
                const selectedPatentClaims = claimsData[patentId]?.claims;
                Object.keys(selectedPatentClaims).forEach(claimKey => {
                    claimOptions.push({
                        value: `${patentId}_${claimKey}`, 
                        label: claimKey
                    });
                });
            }
        }
        
        return claimOptions;
    };

    const handleViewCitations = (patentId:any,claimsKey:any) => {
        setIsShowSideBarCitations(true);
    }

    const handleClaimClick = (event:any ,item: any, index: number, claimKey: string) => {
        if (event.target.id === "add-to-chart") {
            handleAddToChart(item.patentId, index, claimKey);
        }else if(event.target.id === "viewCitations"){
            handleViewCitations(item.patentId,claimKey);
        }else {
            toggleAccordion(item.patentId, index, claimKey);
        }
    };

    const onClaimChartCreated = () => {
        setPatentClaimPairs([]);
        setPersistedSelections({});
        setSelectedSimilarityItems({});
    }

    return (
        <div className='dashboard-wrapper flex'>
            <Sidebar />
            <div className='w-[calc(100%_-_72px)] ml-auto'>
                <Header title='Vijilize' count={0} isChildEle={true} />
                {isLoading ? <ClaimAnalysisShimmerLoader /> : null}
                {!isLoading ?
                    <div className='pt-4 px-4 h-[calc(100vh_-_69px)] overflow-y-auto scrollbar-adjust bg-[#f9fafb]'>
                        <div className='flex justify-between items-center w-full pb-4'>
                            <div className='flex items-start gap-x-2 pl-1'>
                                <img className='inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1' src={allImgPaths.goBack} alt='return' onClick={() => navigate(-1)} />
                                <div className=''>
                                    <h2 className='text-[#101828] text-xl font-interSemiBold'>{APIData?.name}</h2>
                                    <p className='text-sm text-[#4F4D55] font-interRegular'>{APIData?.description}</p>
                                </div>
                            </div>
                            <div className='flex gap-x-4 items-center'>
                                {/* <p className='text-sm text-[#344051] font-interRegular'> */}
                                <div onClick={() => { setIsShowSideBar(true); }} className="w-fit flex-shrink-0 flex items-center gap-x-1">
                                    <span className="font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer">
                                        {APIData?.source?.length} Source
                                    </span>
                                    <span className='font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer'> | </span>
                                    <span className='font-interMedium group-hover:text-[#113B98] text-sm text-[#1751D0] inline-block cursor-pointer'>{APIData?.compare?.length} Reference</span>
                                </div>

                                <button disabled={patentClaimPairs.length === 0} onClick={handleCreateClaimChart} className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 ${patentClaimPairs.length === 0 && 'button-disabled'}`}>
                                    <img className={`${patentClaimPairs.length === 0 && 'contrast-50 w-5'}`} src={allImgPaths.saveClaimChartIcon} alt='' />
                                    Save Vijil Chart
                                </button>

                                {/* <button disabled={patentClaimPairs.length === 0} onClick={handleCreateClaimChart} className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 ${patentClaimPairs.length === 0 && 'button-disabled'}`}>
                                    <img className={`${patentClaimPairs.length === 0 && 'contrast-50'}`} src={allImgPaths.saveClaimChartIcon} alt='' />
                                    Save Claim Chart
                                </button> */}

                                <button className={`px-3.5 py-2.5 rounded-md border border-[#E6E6E6] group bg-white`} onClick={() => { setConfigureScoreModal(true); }}>
                                    <span className={`text-sm font-interMedium text-[#4F4D55] group-hover:text-[#000] flex items-center gap-x-2 ${isOpen ? 'text-[#000]' : ''}`}>
                                        <img src={allImgPaths.configure} alt='' />
                                        Configure Scoring
                                    </span>
                                </button>
                            </div>
                        </div>

                        <div className="py-2 px-3 flex gap-x-4 bg-white mb-2">
                            {sourceOptions.length > 0  && <div className=''>
                                <span className='text-[#2D2B32] text-sm font-interMedium block pb-1'>
                                    Source
                                </span>
                                <div className="modal-select-box fete-settings-modals">
                                    <CustomSelect
                                        defaultValue={sourceOptions[0]}
                                        options={sourceOptions}
                                        onChange={(event:any) => handleDropdownSelect(event, 'source', sourceOptions.find(option => option.value === event.value))}
                                        extendedOption={false}
                                        className='w-80'
                                    />
                                </div>
                            </div>}
                            

                            <div className="">
                                <span className='text-[#2D2B32] text-sm font-interMedium block pb-1'>
                                    Claim
                                </span>
                                <div className="modal-select-box fete-settings-modals">
                                    <CustomSelect
                                        isDisabled = {selectedSourceOption === 'all'}
                                        defaultValue={claimOptions[0]}
                                        options={claimOptions}
                                        onChange={(event:any) => handleDropdownSelect(event, 'claim', claimOptions.find(option => option.value === event.value))}
                                        extendedOption={false}
                                        className={`w-80 ${selectedSourceOption === 'all'?'cursor-not-allowed':''}`}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* main div */}
                        <div className=''>
                            <ClaimData type="vijilize" APIData={APIData} filteredClaimsData={filteredClaimsData} activeIndex={activeIndex} handleClaimClick={handleClaimClick} summarizedAPIData={summarizedAPIData} generateSelectedProd={generateSelectedProd} isButtonEnabled={isButtonEnabled} chartButtonText={chartButtonText}  claimSelectionLoader={claimSelectionLoader} toggle={toggle} selectedClaim={selectedClaim} selectedSimilarity={selectedSimilarity}   selectedSimilarityItems={selectedSimilarityItems} handleSelect={handleSelect}/>
                        </div>

                        {openConfigureScoreModal && <ConfigureScoring selectedSimilarity={selectedSimilarity} isShow={openConfigureScoreModal} configurationobj={configurationobj} setConfigurationobj={setConfigurationobj} setIsShow={setConfigureScoreModal} setToast={setToast} setScoringValue={handleScoring} />}
                        {openCreateClaim && <CreateClaimChart
                            isShow={openCreateClaim}
                            setIsShow={setOpenCreateClaim}
                            setToast={setToast}
                            projectId={id + ''}
                            createClaimChartData={createClaimChartData}
                            onClaimChartCreated={onClaimChartCreated}
                        />}
                    </div> : null}
            </div>
            {/* Sidebar */}
            <div className={`w-full h-full fixed bg-[#07031154] z-[100] ${isShowSideBar ? "right-0" : "right-full"}`}>
                <div onClick={() => setIsShowSideBar(false)} className="h-full w-[calc(100%_-_360px)]"></div>
                <div className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${isShowSideBar ? "right-0" : "right-full"}`}>
                    <div className="pb-6 px-6 pt-4">
                        <div className="pb-0.5 flex items-center justify-between">
                            <span className="text-lg font-interSemiBold text-[#101828] inline-block ">Data Used</span>
                            <img onClick={() => setIsShowSideBar(false)} className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1" src={allImgPaths.closeIcon} alt="" />
                        </div>

                        <p className="text-[#475467] text-sm ">List of source and compare with data used for this vijilization.</p>
                    </div>

                    <div className="h-[calc(100vh_-_130px)]">
                        <div className="">
                            <div className="bg-[#F8F8F8] rounded-lg p-1.5 mx-6 mb-4">
                                <div className="flex items-center gap-x-1">
                                    <p onClick={() => setToggledDataUsed(0)} className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${toggledDataUsed === 0 ? "bg-white shadow-customShadow12 text-[#0A090B]" : "text-[#4F4D55]"}`}>Source ({APIData?.source?.length})</p>
                                    <p onClick={() => setToggledDataUsed(1)} className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${toggledDataUsed === 1 ? "bg-white shadow-customShadow12 text-[#0A090B]" : "text-[#4F4D55]"}`}>Compare With ({APIData?.compare?.length})</p>
                                </div>
                            </div>
                            <div className="overflow-y-auto h-[calc(100vh_-_180px)] px-6">
                                {/* Source */}
                                {
                                    toggledDataUsed === 0 && APIData?.source &&
                                    (
                                        <>
                                            {
                                                APIData?.source.map((item: any, id: number) => {
                                                    return (
                                                        <>
                                                            <div key={id + item.file_url} className='border-b px-5 py-3'>
                                                                <div className='flex items-center gap-x-4'>
                                                                    <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                                                                        <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                                                                    </Link>
                                                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                                                                </div>
                                                                {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )

                                }

                                {/* Compare with */}
                                {
                                    toggledDataUsed === 1 && APIData?.compare &&
                                    (
                                        <>
                                            {
                                                APIData?.compare.map((item: any, id: number) => {
                                                    return (
                                                        <>
                                                            <div key={id} className='border-b px-5 py-3'>
                                                                <div className='flex items-center gap-x-4'>

                                                                    <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                                                                        <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                                                                    </Link>
                                                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                                                                </div>
                                                                {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )

                                }
                            </div>
                        </div>
                    </div>

                    {/* <div className="">
                    <div className="flex gap-x-3 justify-end z-10 mt-4 px-5 py-4 border-t border-[#E4E7EC]">
                        <button onClick={() => setIsShowSideBar(false)} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide w-40 py-2.5 px-2 !text-base hover:bg-[#f2f4f7]'>Cancel</button>
                        <button className={`font-interSemiBold text-base text-[#667085] py-2 px-2 w-40 border border-[#1E0AF5] rounded-lg flex items-center justify-center cursor-pointer shadow-customShadow3 bg-white hover:bg-[#E8E6FE]`}>
                            <span className="text-[15px] text-[#1E0AF5] font-interSemiBold">Apply</span>
                        </button>
                    </div>
                </div> */}
                </div>
            </div>

            {/* Sidebar Citations */}
            <CitationsSidebar isShowSideBarCitations={isShowSideBarCitations} setIsShowSideBarCitations={setIsShowSideBarCitations}/>
        </div>
    );
};
export default ClaimAnalysisVijilize;
