import React from 'react';

const ClaimAnalysisShimmerLoader = () => {
    return (
        <div className="w-full animate-pulse">
            {/* Header section */}
            <div className="flex justify-between items-center w-full pb-10">
                <div className="flex items-start gap-x-2">
                    <div className="w-5 h-5 bg-gray-200 rounded"></div>
                    <div>
                        <div className="h-6 w-64 bg-gray-200 rounded mb-2"></div>
                        <div className="h-4 w-48 bg-gray-200 rounded"></div>
                    </div>
                </div>
                <div className="flex gap-x-4">
                    <div className="w-24 h-10 bg-gray-200 rounded"></div>
                    <div className="w-36 h-10 bg-gray-200 rounded"></div>
                </div>
            </div>

            {/* Content section */}
            <div className="flex gap-4">
                {/* Left panel */}
                <div className="w-1/3 space-y-4">
                    {[1,2,3].map((item) => (
                        <div key={item} className="border rounded-lg p-4">
                            <div className="flex justify-between items-center mb-4">
                                <div className="h-6 w-24 bg-gray-200 rounded"></div>
                                <div className="flex gap-x-4">
                                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="h-4 w-full bg-gray-200 rounded"></div>
                                <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
                                <div className="h-4 w-4/6 bg-gray-200 rounded"></div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Middle panel */}
                <div className="w-1/3 space-y-4">
                    {[1,2,3].map((item) => (
                        <div key={item} className="border rounded-lg p-4">
                            <div className="flex justify-between items-center mb-4">
                                <div className="h-6 w-24 bg-gray-200 rounded"></div>
                                <div className="flex gap-x-4">
                                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                                    <div className="h-4 w-32 bg-gray-200 rounded"></div>
                                </div>
                            </div>
                            <div className="space-y-2">
                                <div className="h-4 w-full bg-gray-200 rounded"></div>
                                <div className="h-4 w-5/6 bg-gray-200 rounded"></div>
                                <div className="h-4 w-4/6 bg-gray-200 rounded"></div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Right panel */}
                <div className="w-1/3 border rounded-lg p-4">
                    <div className="flex justify-center mb-4">
                        <div className="flex">
                            <div className="w-32 h-10 bg-gray-200 rounded-l"></div>
                            <div className="w-32 h-10 bg-gray-200 rounded-r"></div>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="h-48 bg-gray-200 rounded"></div>
                        <div className="h-48 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClaimAnalysisShimmerLoader;