import React,{ Dispatch,SetStateAction,useEffect,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import create from 'assets/images/create-claim.svg';
import close from 'assets/images/close-icon.svg';




import { Body } from './Body';
import { InputTextField } from 'components/InputField';
import CustomSelect from '../CustomSelect';
import { createNewClaimChart,createToExistingClaimChart,getClaimChartByProjectId } from "services/project";
import Toast from "../Toast";

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    projectId: string;
    createClaimChartData: any;
    onClaimChartCreated:any;
}

const CreateClaimChart = ({ isShow, setIsShow, setToast, projectId, createClaimChartData, onClaimChartCreated  }: Iprops) => {
    const [toggle,setToggle] = useState(0);
    const [selectedClaimChart,setSelectedClaimChart] = useState<any>(null)
    const [option,setOption] = useState('add new');

    const [name,setName] = useState('');

    const [claimChartList,setClaimChartList] = useState<any[]>([]);
    const [formattedList,setFormattedList] = useState<any[]>([]);

    const onCloseModal = (e: any) => {
        e.preventDefault();
        setIsShow(false);
    };


    useEffect(() => {
        (async () => {
            console.log("🚀 ~ createClaimChartData:",createClaimChartData);
            let list = await getClaimChartByProjectId(projectId);

            setClaimChartList(list?.data);
            const formattedOptions = list?.data.map((chart: any) => ({
                label: chart.name,
                value: chart._id
            }));
            setFormattedList(formattedOptions);

        })();


    },[])

    const submit = async (e: any) => {


        const selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);
        e.preventDefault();
        if (option === 'add new') {
            let payload = { name: name,project_id: projectId,team_id: selectedTeam.currentTeamId,claim_chart_data: createClaimChartData };
            let res: any = await createNewClaimChart(payload);
            console.log(res,'RES');
            setIsShow(false);
            setName('');
            setSelectedClaimChart({});
            setOption('add new');

            Toast.success('Success',res.message);

        } else if (option === 'add to existing') {
            console.log(selectedClaimChart,'selectedClaimChart');
            let payload = { claim_chart_data: createClaimChartData };
            let res: any = await createToExistingClaimChart(selectedClaimChart.value,payload);
            console.log(res,'RES');
            setIsShow(false);
            setName('');
            setSelectedClaimChart({});
            setOption('add new');
            Toast.success('Success',res.message);
        }
        onClaimChartCreated();
    }

    return (
        <>
            <Modal className={'modal-wrapper max-w-[440px]'} isShow={isShow}>
                <form onSubmit={submit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between mb-5'>
                            <img className='w-10 h-10' src={create} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Create Claim Chart</h2>
                        <p className='text-sm text-[#4F4D55] font-interRegular'>Choose the type of claim chart you want to create.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-5 flex flex-col gap-2'>
                        <div className='flex flex-col gap-y-[5px] gap-x-6'>
                            <h5 className='text-[#4F4D55] text-sm font-interMedium pb-1'>I want to</h5>
                            <div className='flex gap-x-10 items-center pb-3'>
                                <label className='flex gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-0.5'>
                                        <div
                                            className={`w-4 h-4 rounded-full border-2 transition-colors cursor-default pointer-events-none ${option === 'add new' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-[#DCDCDE] bg-white hover:border-[#1751D0]'}`}>
                                            {option === 'add new' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(0);
                                                setOption('add new');
                                            }}
                                            checked={option === 'add new'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Create New</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Create a new file</h6>
                                    </div>
                                </label>
                                <label className='flex my-3cursor-pointer gap-x-2 justify-start items-start'>
                                    <div className='relative flex items-center justify-center mt-0.5'>
                                        <div
                                            className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                            ${option === 'add to existing' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-[#DCDCDE] bg-white hover:border-[#1751D0]'}`}>
                                            {option === 'add to existing' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div>
                                        <input
                                            type='radio'
                                            name=''
                                            className='sr-only'
                                            onClick={(e) => {
                                                setToggle(1);
                                                setOption('add to existing');
                                            }}
                                            checked={option === 'add to existing'}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <h3 className='text-sm text-[#0A090B] font-interMedium pb-1'>Add to existing</h3>
                                        <h6 className='text-[#7F7D83] text-xs font-interMedium'>Append to existing file</h6>
                                    </div>
                                </label>
                            </div>

                            {option === 'add new' && <div className='pb-2'>
                                <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block pb-2'>
                                    Name
                                </label>
                                <InputTextField
                                    className='placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full'
                                    name={'name'}
                                    placeholder={'For Company 1'}
                                    onChange={(e: any) => { setName(e.target.value) }}
                                    value={name}
                                />
                            </div>}

                            {option === 'add to existing' && <div className='pb-2'>
                                <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block pb-2'>
                                    Select existing claim chart
                                </label>
                                <div className="modal-select-box fete-settings-modals">
                                    <CustomSelect
                                        options={formattedList}
                                        onChange={(selectedOption) => {
                                            // Handle the selection change here
                                            setSelectedClaimChart(selectedOption);
                                        }}
                                        extendedOption={false}
                                        selectedValue={selectedClaimChart}
                                    />

                                    {/* <p className='text-[#D92D20] text-sm pt-0.5'>error</p> */}
                                </div>
                            </div>}

                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Save
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default CreateClaimChart;
