import React,{ Dispatch,SetStateAction,useState,useMemo } from 'react';
import { Modal } from './Modal';
import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';
import { Body } from './Body';
import { addProject,editProject } from "services/project";
import { InputTextField } from "components/InputField";
import Textarea from "../Textarea";
import Toast from "../Toast";
import allImgPaths from 'assets/images';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
    projectId: string;
    projectName: string;
    projectDesc: string;
}

const EditProject = ({ isShow,setIsShow,setToast,setRefreshData,projectId,projectName,projectDesc }: Iprops) => {
    const [name,setName] = useState<string>(projectName);
    const [description,setDescription] = useState<string>(projectDesc);


    // Validation check for project name
    const isProjectNameValid = useMemo(() => {
        return projectName.trim().length > 0;
    },[projectName]);

    const onCloseModal = () => {
        setIsShow(false);
    };

    const handleProjectNameChange = (e: any) => {
        setName(e.target.value);
    };

    const handleProjectDescriptionChange = (e: any) => {
        setDescription(e.target.value);

        let getTextArea = document.getElementById("project-desc");
        if (getTextArea) {
            if (e.target.value.length === 0) {
                e.target.style.height = "24px";
            } else {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
            }
        }
    };

    const handleSubmit = async (event: any) => {
        event?.preventDefault();

        if (!isProjectNameValid) {
            return;
        }

        const payload = {
            name: name.trim(),
            description: description.trim()
        };

        const { status } = await editProject(projectId,payload);

        setName('');
        setDescription('');

        setIsShow(false);
        setRefreshData(true);
        Toast.success('Success','Project updated successfully');
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[580px]'} isShow={isShow}>
                <form onSubmit={handleSubmit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between pb-3'>
                            <img className='h-10 w-10' src={allImgPaths.editModal2} alt='remove' />
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Edit Project</h2>
                        <p className='text-sm text-[#4F4D55] font-interRegular'>Edit/Update the basic details of project</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-7'>
                        <div className='pb-7'>
                            <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block pb-2'>
                                Project Name
                            </label>
                            <InputTextField
                                className='placeholder:text-[#7F7D83] placeholder:text-[15px] text-base text-[#344054] w-full'
                                name={'name'}
                                placeholder={'Enter Project Name'}
                                onChange={handleProjectNameChange}
                                value={name}
                            />
                        </div>

                        <div className=''>
                            <div className='flex justify-between pb-2'>
                                <label htmlFor='' className='text-[#2D2B32] text-sm font-interMedium block'>
                                    Description
                                </label>
                                <label htmlFor='' className='font-interRegular text-xs text-[#7F7D83] leading-[1.7]'>
                                    Optional
                                </label>
                            </div>
                            <Textarea
                                name="project-desc"
                                placeholder="One line description entered by the user"
                                value={description}
                                onChange={handleProjectDescriptionChange}
                                id='project-desc'
                            />
                            <p className="text-sm text-[#475467] pt-2">Max. length 200 characters</p>
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button
                                onClick={onCloseModal}
                                className='text-[#4F4D55] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'
                            >
                                Cancel
                            </button>
                            <button
                                className={`!text-base text-white font-interSemiBold bg-[#1751D0] hover:bg-[#113B98] rounded-lg px-3.5 py-2.5 flex-1 ${isProjectNameValid
                                    ? ''
                                    : 'button-disabled'
                                    }`}
                                type='submit'
                                disabled={!isProjectNameValid}
                            >
                                Update
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default EditProject;