import React,{ Dispatch,SetStateAction,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';

import { Body } from './Body';
import allImgPaths from "assets/images";
import CustomSelect from "../CustomSelect";

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
}

const AddToPortfolioModal = ({ isShow,setIsShow,setToast }: Iprops) => {
    const [toggle,setToggle] = useState(0);
    const [option,setOption] = useState('with patent');
    const [selectedFiles,setSelectedFiles] = useState<any[]>([]);
    const [filePreviews,setFilePreviews] = useState<any[]>([]);

    const projectList: any = [
        {
            label: "Project 1",value: "1",
        },{
            label: "Project 2",value: "2"

        },{ label: "Project 3",value: "3" }
    ]

    const onCloseModal = () => {

        setIsShow(false);
        setSelectedFiles([]);
        setFilePreviews([]);


    };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let recievedFiles: any[] = (e.target.files || []) as any[];
        const files = Array.from(recievedFiles);
        handleFiles(files);
    };

    const handleFiles = async (files: any[]) => {
        // Filter files if needed (e.g., specific file types)
        const allowedTypes = ['application/pdf','image/jpeg','image/png']; // Example file types
        let validFiles = files;

        const previewPromises: Promise<string>[] = [];

        validFiles.forEach((file) => {
            const promise = new Promise<string>((resolve) => {
                displayPreview(file,(preview) => {
                    resolve(preview);
                });
            });
            previewPromises.push(promise);
        });
        setSelectedFiles(prevFiles => [...prevFiles,...validFiles]);
        Promise.all(previewPromises).then((previews) => {
            setFilePreviews(prevFiles => [...prevFiles,...previews]);
        });
    };
    const displayPreview = (file: File,callback: (preview: string) => void) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (reader.result) {
                callback(reader.result as string);
            } else {
                callback('');
            }
        };
    };


    return (
        <>
            <Modal className={'modal-wrapper max-w-[480px]'} isShow={isShow}>
                <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                    <div className='flex items-center justify-between pb-7'>
                        <img className='h-10 w-10' src={folderPlus} alt='remove' />
                        {/* Close Icon */}
                        <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                    </div>

                    <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Add to Portfolio</h2>
                    <p className='text-sm text-[#4F4D55] font-interRegular'>Add Files & patents to create a comprehensive Portfolio</p>
                </HeaderModal>

                <Body title='' isShowBody={true} className='modal-body px-6 pt-7'>
                    <div className="modal-container flex flex-col gap-y-[24px] mx-[24px] my-[16px]">
                        {/* Top Radio Part */}
                        <div className="flex gap-x-[24px]">

                            <label className='flex  cursor-pointer justify-start items-center'>

                                <div className='relative flex items-center justify-center mt-1'>
                                    {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'with patent' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                    <div
                                        className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'with patent' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                        {option === 'with patent' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                    </div>
                                    <input
                                        type='radio'
                                        name=''
                                        className='sr-only'
                                        onClick={(e) => {
                                            setToggle(0);
                                            setOption('with patent');
                                        }}
                                        checked={option === 'with patent'}
                                    />
                                </div>
                                <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>With Patent</h5>

                            </label>

                            <label className='flex  cursor-pointer justify-start items-center'>

                                <div className='relative flex items-center justify-center mt-1'>
                                    {/* <div
                                            className={`w-6 h-6 rounded-full border-2 transition-colors
                                        ${option === 'external upload' ? 'border-indigo-600 bg-indigo-600' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                            {option === 'external upload' && <div className='w-4 h-4 rounded-full bg-white mx-auto mt-[2px]' />}
                                        </div> */}
                                    <div
                                        className={`w-4 h-4 cursor-pointer rounded-full border-2 transition-colors
                                        ${option === 'external upload' ? 'border-[#1751D0] bg-[#1751D0]' : 'border-gray-300 bg-white group-hover:border-gray-400'}`}>
                                        {option === 'external upload' && <div className='w-2 h-2 rounded-full bg-white mx-auto mt-[2px]' />}
                                    </div>
                                    <input
                                        type='radio'
                                        name=''
                                        className='sr-only'
                                        onClick={(e) => {
                                            setToggle(1);
                                            setOption('external upload');
                                        }}
                                        checked={option === 'external upload'}
                                    />
                                </div>
                                <h5 className='text-base text-[#0A090B] font-interRegular leading-[24px] text-left px-2'>External Upload</h5>

                            </label>
                        </div>

                        {toggle == 0 && <div className="">
                            <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                Enter Patent #
                                <img src={allImgPaths.questionCircle} alt='help' />
                            </div>
                            <div className="">
                                <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Enter patent numbers separated by commas'} />
                            </div>
                        </div>}



                        {toggle == 1 && <div className="bg-[#F9FAFB]">
                            <div
                                className='relative border border-[#C9C9CC] hover:border-gray-400 border-dashed rounded-lg p-2 w-full'
                                id='dropzone'
                                onDragOver={(e) => e.preventDefault()}
                                onDragEnter={(e) => e.preventDefault()}
                                onDragLeave={() => { }}
                                onDrop={handleDrop}>
                                <input id='photo-dropbox' type='file' multiple accept=".png,.jpg,.pdf,.jpeg,.svg" className='absolute inset-0 w-full h-full opacity-0 z-50' onChange={handleFileChange} />
                                <label className='flex cursor-pointer gap-1 w-full px-3 py-2 bg-[#F9FAFB] rounded-lg' htmlFor='photo-dropbox'>
                                    <input id='photo-dropbox' type='file' multiple accept=".png,.jpg,.pdf,.jpeg,.svg" className='sr-only' onChange={(e) => console.log(e.target.files)} />
                                    <img src={allImgPaths.uploadIcon} alt='upload.svg' />
                                    <span className='px-2'>
                                        Drag & drop patent file or <span className='text-[#1751D0]'>browse files</span>
                                    </span>
                                </label>

                            </div>
                            {/* {filePreviews.length > 0 && filePreviews.map((file) => (
                                    <>
                                        <img id={'preview'} src={file || ''} className={`mt-4 mx-auto max-h-4 ${file ? '' : 'hidden'}`} alt='Preview' />
                                    </>
                                )
                                )} */}
                            {/* {selectedFiles.length > 0 &&

                                    <FileUploadProgress files={selectedFiles} />} */}
                        </div>}
                    </div>
                </Body>

                <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                    <div className='flex items-center gap-x-3 justify-center'>
                        <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                            Cancel
                        </button>
                        <button className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                            Continue
                        </button>
                    </div>
                </FooterModal>
            </Modal>
        </>
    );
};

export default AddToPortfolioModal;
