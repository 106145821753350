import Mermaid from "assets/js/Mermaid";
import React, { useState } from "react";

const ClaimStructure = ({ data }: any) => {
  const [isPanZoomEnabled,setIsPanZoomEnabled] = useState(false);

  const mermaidProps: any = {
    text: data,
    panZoomEnabled: isPanZoomEnabled,
  };

  return (
    <div className="mx-auto w-[95%]">
      <div className="flex justify-end items-center">
      <label className="inline-flex items-center cursor-pointer">
        <input type="checkbox" className="sr-only peer" onClick={() => setIsPanZoomEnabled(!isPanZoomEnabled)} />
        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
        <span className="ms-3 text-sm font-interMedium text-[#101828]">
          Pan & Zoom
        </span>
      </label>
      </div>

      <Mermaid {...mermaidProps} />
    </div>
  );
};

export default ClaimStructure;
