import allImgPaths from "assets/images";
import ClaimElement from "./ClaimElement";
import ClaimGraphics from "./ClaimGraphics";
import ClaimStructure from "./ClaimStructure";
import { useState } from "react";

interface ClaimDataProps {
  type: string;
  APIData: any;
  filteredClaimsData: any;
  activeIndex: any;
  handleClaimClick: any;
  claimSelectionLoader: any;
  selectedClaim: any;
  summarizedAPIData?: any;
  selectedSimilarity?: any;
  claimStructureData?: any;
  setClaimStructureData?: any;
  isButtonEnabled?: any;
  generateSelectedProd?: any;
  selectedSimilarityItems?: any;
  handleSelect?: any;
  toggle?: any;
  setToggle?: any;
  chartButtonText?: any;
}

const ClaimData = ({
  type = "vijilize",
  APIData,
  filteredClaimsData,
  activeIndex,
  handleClaimClick,
  claimSelectionLoader,
  selectedClaim,
  claimStructureData,
  setClaimStructureData,
  summarizedAPIData = null,
  selectedSimilarity = [],
  isButtonEnabled = {},
  generateSelectedProd = null,
  selectedSimilarityItems = {},
  handleSelect = null,
  toggle = 0,
  setToggle = null,
  chartButtonText = {},
}: ClaimDataProps) => {
  const [claimFiguresTotal, setClaimFiguresTotal] = useState<any>(0);

  const generateMaxPercentageData: any = (patentId: any, claimKey: any) => {
    const summarizedData: any =
      summarizedAPIData &&
      summarizedAPIData.find((item: any) => item[patentId]);

    const summarizedDataValues: any = Object.values(summarizedData)[0];

    const claimData: any = summarizedDataValues[claimKey];

    const maxScore = claimData
      ? claimData.sort((a: any, b: any) => b.score - a.score)[0].score
      : null;
    return maxScore;
  };

  return (
    <>
      {Object.keys(filteredClaimsData).length > 0 && (
        <div className="overflow-y-scroll hide-scrollbar3 h-[calc(100vh_-_261px)]">
          {Object.entries(filteredClaimsData).map(([patentId, patent]: any) => (
            // loop
            <div className="mb-2 last:mb-0 bg-white px-3 pb-2" key={patentId}>
              {/* Claim Info */}
              <div className="py-3">
                <div className="pb-2">
                  <h3 className="text-base font-interSemiBold text-[#0A090B] mb-0.5">
                    {patent.patentNumber}
                  </h3>
                  {/* <p className='text-sm font-interRegular text-[#7F7D83]'>Cover and grip for handheld devices</p> */}
                </div>
                <div className="flex items-center gap-x-4">
                  <span className="text-sm font-interMedium text-[#000000]">
                    {Object.keys(patent.claims).length} matching claims found
                  </span>
                  {/* <span className='text-sm font-interMedium text-[#1A75FF] cursor-pointer hover:text-[#113B98]'>Expand All</span> */}
                </div>
              </div>

              {Object.entries(patent.claims).map(
                ([claimKey, claim], claimIndex) => (
                  <div
                    key={patentId + "_" + claimIndex}
                    id={patentId + "_" + claimIndex}
                    className={`mb-3 last:mb-2 ${
                      activeIndex.patentId === patentId &&
                      activeIndex.claimIndex === claimIndex
                        ? "active-accordion"
                        : "accordion-hide"
                    }`}
                  >
                    <div
                      className={`border border-[#CCE0FF] px-5 py-5 bg-[#FDFDFD] cursor-pointer accordion-heading flex items-center justify-between`}
                      onClick={(event) =>
                        handleClaimClick(event, patent, claimIndex, claimKey)
                      }
                    >
                      <div className="flex items-center gap-x-3">
                        <span className="font-interSemiBold text-base text-[#101828] flex items-center gap-x-2">
                          Claim {claimKey.replace("claim", "")}
                        </span>

                        {
                          type !== "analysis" &&
                            (() => {
                              const percentageData = generateMaxPercentageData(
                                patentId,
                                claimKey
                              );
                              return typeof percentageData === "number" &&
                                percentageData >= 0 ? (
                                <span className="px-1.5 py-0.5 rounded-md bg-[#019939] text-white font-interRegular text-sm">
                                  {percentageData}% Match
                                </span>
                              ) : null; 
                            })() 
                        }
                      </div>

                      <div className="flex items-center gap-x-3.5">
                        {type === "vijilize" && (
                          <>
                            {generateSelectedProd(claimKey, patentId) !== 0 && (
                              <span className="text-sm text-[#344051] font-interSemiBold">
                                {" "}
                                {generateSelectedProd(claimKey, patentId)}{" "}
                                Selected{" "}
                              </span>
                            )}

                            <button
                              id="add-to-chart"
                              className={`!text-xs text-white bg-[#0A090B] hover:bg-[#0a090b] font-interMedium rounded-md px-2 py-1.5 mr-1 ${
                                isButtonEnabled[
                                  `${patentId}_claim${claimIndex + 1}`
                                ]
                                  ? ""
                                  : "button-disabled"
                              }`}
                              disabled={
                                !isButtonEnabled[
                                  `${patentId}_claim${claimIndex + 1}`
                                ]
                              }
                            >
                              {chartButtonText[
                                `${patentId}_claim${claimIndex + 1}`
                              ] || "Add to Vijil Chart"}
                            </button>
                          </>
                        )}

                        <span
                          id="viewCitations"
                          className="text-sm text-[#1751D0] font-interSemiBold"
                        >
                          View Citations
                        </span>

                        <img
                          className="accordion-arrow cursor-pointer hover:bg-[#f2f4f7] rounded"
                          src={allImgPaths.dropdownArrow}
                          alt=""
                        />
                      </div>
                    </div>

                    {activeIndex.patentId === patentId &&
                      activeIndex.claimIndex === claimIndex && (
                        <div className={`accordion-content`}>
                          <div className="flex">
                            {/* First */}
                            <div
                              className={`${
                                type === "analysis" ? "w-1/2" : "w-1/3"
                              } border border-[#CED2DA] border-t-0 border-r-0 rounded-bl-lg`}
                            >
                              <ClaimElement claim={claim} type={"vijilize"} />
                            </div>

                            {/* Middle */}
                            <div
                              className={`${
                                type === "analysis" ? "w-1/2" : "w-1/3"
                              } border border-[#CED2DA] border-t-0`}
                            >
                              {claimSelectionLoader && (
                                <div className="border rounded-lg p-4 animate-pulse">
                                  <div className="flex justify-center mb-4">
                                    <div className="flex">
                                      <div className="w-32 h-10 bg-gray-200 rounded-l"></div>
                                      <div className="w-32 h-10 bg-gray-200 rounded-r"></div>
                                    </div>
                                  </div>
                                  <div className="space-y-4">
                                    <div className="h-48 bg-gray-200 rounded"></div>
                                    <div className="h-48 bg-gray-200 rounded"></div>
                                  </div>
                                </div>
                              )}
                              {!claimSelectionLoader && (
                                <div className="">
                                  <div className="px-4 py-3 border-[#CED2DA] border-b">
                                    <p className="text-base font-interSemiBold text-[#0A090B]">
                                      Claim Figures
                                    </p>
                                    <p className="text-xs font-interMedium leading-[1.5]">
                                      {claimFiguresTotal} found
                                    </p>
                                  </div>

                                  <div className="py-4">
                                    {type === "analysis" && (
                                      <>
                                        <ul className="flex items-center justify-center pb-4">
                                          <li
                                            onClick={(e) => {
                                              setToggle(0);
                                            }}
                                            className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] text-[#4F4D55] rounded-tl-lg rounded-bl-lg flex items-center gap-x-2 cursor-pointer ${
                                              toggle === 0
                                                ? "bg-[#F1F1F1] pointer-none"
                                                : "bg-[#fff]"
                                            }`}
                                          >
                                            <span className="text-sm font-interMedium text-[#4F4D55]">
                                              Claim Graphics
                                            </span>
                                          </li>
                                          {APIData?.structure && (
                                            <li
                                              onClick={(e) => {
                                                setToggle(1);
                                                setClaimStructureData(
                                                  APIData?.structure
                                                );
                                              }}
                                              className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] text-[#4F4D55] rounded-tr-lg rounded-br-lg flex items-center gap-x-2 cursor-pointer ${
                                                toggle === 1
                                                  ? "bg-[#F1F1F1] pointer-none"
                                                  : "bg-[#fff]"
                                              }`}
                                            >
                                              <span className="text-sm font-interMedium text-[#4F4D55]">
                                                Claim Structure
                                              </span>
                                            </li>
                                          )}
                                        </ul>
                                      </>
                                    )}
                                    {toggle === 0 && (
                                      <div className="">
                                        <div className="">
                                          <ClaimGraphics
                                            claim={selectedClaim}
                                            setClaimFiguresTotal={
                                              setClaimFiguresTotal
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {toggle === 1 && (
                                      <div className="claim-strcture-wrapper">
                                        <ClaimStructure
                                          data={claimStructureData}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>

                            {/* Last */}
                            {type !== "analysis" && (
                              <>
                                <div className="w-1/3 border border-[#CED2DA] border-t-0 border-l-0 rounded-br-lg">
                                  <div className="px-4 py-3 border-b border-[#CED2DA]">
                                    <p className="text-base font-interSemiBold text-[#0A090B]">
                                      Covered Patents
                                    </p>
                                    {selectedSimilarity && (
                                      <p className="text-xs font-interMedium leading-[1.5]">
                                        {selectedSimilarity?.length} matches
                                        found
                                      </p>
                                    )}
                                  </div>
                                  <div className="">
                                    {selectedSimilarity &&
                                    selectedSimilarity.length > 0 ? (
                                      selectedSimilarity.map(
                                        (item: any, index: any) => (
                                          <div
                                            key={item.targetDocumentId + index}
                                            className="mb-4 last:mb-0"
                                          >
                                            <div className="">
                                              <div className="px-3 py-3 relative">
                                                <div className="relative flex items-center gap-x-2 mb-3">
                                                  {type === "vijilize" && (
                                                    <>
                                                      <input
                                                        type="checkbox"
                                                        id={
                                                          "claim" +
                                                          ((activeIndex.claimIndex ||
                                                            0) +
                                                            1) +
                                                          "cid" +
                                                          item.patentId +
                                                          "_id" +
                                                          item.targetDocumentId +
                                                          "check"
                                                        }
                                                        name="checkbox-group"
                                                        checked={
                                                          selectedSimilarityItems[
                                                            `claim${
                                                              (activeIndex.claimIndex ||
                                                                0) + 1
                                                            }cid${
                                                              item.patentId
                                                            }_id${
                                                              item.targetDocumentId
                                                            }check`
                                                          ] || false
                                                        }
                                                        onChange={(e) =>
                                                          handleSelect(e, item)
                                                        }
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                        className="peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer"
                                                      />
                                                      <svg
                                                        className="absolute w-5 h-5 pointer-events-none hidden peer-checked:block inset-0 stroke-white"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      >
                                                        <polyline points="4 8 7 11 12 5"></polyline>
                                                      </svg>
                                                    </>
                                                  )}
                                                  <p className="text-sm text-[#71717A]">
                                                    {item.targetDocumentId}
                                                  </p>
                                                </div>
                                                {item?.matched_items &&
                                                  item?.matched_items.length >
                                                    0 && (
                                                    <>
                                                      <p className="text-sm font-interSemiBold text-[#71717A] mb-3">
                                                        Matched Items :{" "}
                                                      </p>
                                                      {item.matched_items.map(
                                                        (
                                                          matchedItem: any,
                                                          index: number
                                                        ) => (
                                                          <p
                                                            key={
                                                              matchedItem +
                                                              index
                                                            }
                                                            className="text-sm text-[#71717A] mb-3"
                                                          >
                                                            {matchedItem}
                                                          </p>
                                                        )
                                                      )}
                                                    </>
                                                  )}

                                                {item?.non_matched_items &&
                                                  item?.non_matched_items
                                                    .length > 0 && (
                                                    <>
                                                      <p className="text-sm font-interSemiBold text-[#71717A] mb-3 mt-3">
                                                        Unmatched Items :{" "}
                                                      </p>
                                                      {item.non_matched_items.map(
                                                        (
                                                          unmatchedItem: any,
                                                          index: number
                                                        ) => (
                                                          <p
                                                            key={
                                                              unmatchedItem +
                                                              index
                                                            }
                                                            className="text-sm text-[#71717A] mb-3"
                                                          >
                                                            {unmatchedItem}
                                                          </p>
                                                        )
                                                      )}
                                                    </>
                                                  )}
                                                <div className="bg-[#FAFAFA] pb-3 pt-1 px-2 mb-4 rounded-md">
                                                  <span className="text-xs text-[#7E8C9A] font-interMedium inline-block pb-3">
                                                    Overlap score
                                                  </span>
                                                  <div className="flex items-center gap-x-3 justify-between">
                                                    <div className="bg-[#E4E4E7] h-1.5 w-[97%] relative rounded-lg">
                                                      <div
                                                        className="bg-[#005BC4] h-full absolute top-0 left-0 rounded-lg"
                                                        style={{
                                                          width: `${Math.round(
                                                            item.score
                                                          )}%`,
                                                        }}
                                                      ></div>
                                                    </div>
                                                    <span className="font-interSemiBold text-black text-sm">
                                                      {Math.round(item.score)}%
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <>
                                        <div className="text-center pt-24">
                                          <img
                                            className="mb-2.5 ml-auto mr-auto"
                                            src={allImgPaths.searchIcon3}
                                            alt=""
                                          />
                                          <p className="font-interSemiBold text-base text-[#101828]">
                                            No Covered Products found
                                          </p>
                                          <p className="font-interRegular text-sm text-[#475467]">
                                            There are no Covered Products for
                                            this claim
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                )
              )}
            </div>
          ))}
          {Object.entries(filteredClaimsData).length === 0 && (
            <p>No claims found</p>
          )}
        </div>
      )}
    </>
  );
};

export default ClaimData;
