import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import { Body } from './Body';
import allImgPaths from "assets/images";
import FileExplorer from "../Explorer";
import FileUploadProgress from "../FileUploadProgress";
import { createPatent, createPatentPresignedUrl, patentUploadStatus, s3PutUrlWithProgress } from "services/project";
import { uploadFileToS3 } from "services/auth/auth";
import Toast from "../Toast";


interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    projectId: string;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}



function AddComparisonData({ isShow, setIsShow, setToast, projectId, setRefreshData }: Iprops) {

    const [toggle, setToggle] = useState(2);
    const [option, setOption] = useState('external upload');
    const [previewSrc, setPreviewSrc] = useState<string | null>(null);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [filePreviews, setFilePreviews] = useState<any[]>([]);
    const [isAPICalled, setIsAPICalled] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(true);
    // const [fileProgress,setFileProgress] = useState<number>(0)

    // Update these state declarations in AddComparisonData
    const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>({});
    const [uploadingFiles, setUploadingFiles] = useState<boolean>(false);

    const onCloseModal = () => {

        setIsShow(false);
        setSelectedFiles([]);
        setFilePreviews([]);
        setIsAPICalled(false);

    };

    // const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const file = e.dataTransfer.files[0];
    //     displayPreview(file);
    // };
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let recievedFiles: any[] = (e.target.files || []) as any[];
        const files = Array.from(recievedFiles);
        handleFiles(files);
    };
    const handleFiles = async (files: any[]) => {
        const allowedTypes = ['application/pdf'];
        let validFiles = files.filter(file => allowedTypes.includes(file.type));

        if (validFiles.length !== files.length) {
            Toast.error('Error', 'Only PDF files are allowed');
        }

        const previewPromises: Promise<string>[] = [];
        validFiles.forEach((file) => {
            const promise = new Promise<string>((resolve) => {
                displayPreview(file, (preview) => {
                    resolve(preview);
                });
            });
            previewPromises.push(promise);
        });

        setSelectedFiles(prevFiles => [...prevFiles, ...validFiles]);
        setBtnDisabled(false);

        Promise.all(previewPromises).then((previews) => {
            setFilePreviews(prevFiles => [...prevFiles, ...previews]);
        });


    };

    // const displayPreview = (file: File) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onloadend = () => {
    //         if (reader.result) {
    //             // setPreviewSrc(reader.result as string);
    //             return (reader.result as string);
    //         }
    //         else {
    //             return '';
    //         }
    //     };
    // };

    const resetModal = () => {
        setIsShow(false);
        setSelectedFiles([]);
        setFilePreviews([]);
        setIsAPICalled(false);
        setFileProgress({});
        setUploadingFiles(false);
    };

    const displayPreview = (file: File, callback: (preview: string) => void) => {

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (reader.result) {
                callback(reader.result as string);
            } else {
                callback('');
            }
        };
    };


    // const submitOld = async (e: any) => {
    //     const userData: any = JSON.parse(localStorage.getItem('userData') as string);
    //     let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

    //     e?.preventDefault();
    //     setIsAPICalled(true);
    //     if (toggle === 2) {
    //         let currentFile: any = selectedFiles.slice(0,1)[0];

    //         // step 1
    //         let payload = { 'patent_id': currentFile.name.split('.pdf')[0] }
    //         const { data,status } = await createPatent(payload);

    //         // step 2
    //         if (data) {

    //             let payload2 = {
    //                 "team_id": selectedTeam.currentTeamId,
    //                 "project_id": projectId,
    //                 "patent_id": data._id,
    //                 "file_name": currentFile.name,
    //                 "content_type": currentFile.type,
    //                 "patent_category": "compare",
    //                 "patent_type": "external"
    //             }
    //             const step2Response: any = await createPatentPresignedUrl(payload2);



    //             const step3payload: any = {
    //                 url: step2Response.url,
    //                 file: currentFile,
    //                 type: currentFile.type,
    //             };

    //             let updatedFileName = '';

    //             if (step2Response) {
    //                 // const response = await uploadFileToS3(step3payload);
    //                 const response: any = await s3PutUrlWithProgress(step3payload,(progress) => {
    //                     console.log(progress,'progress%');
    //                     setBtnDisabled(true);
    //                     setFileProgress(progress);
    //                 });
    //                 console.log("🚀 ~ response ~ response:",response)

    //                 updatedFileName = data?.fileName || '';

    //                 if (response.status === 200) {

    //                     const { url,file_id,...otherPayload } = step2Response;


    //                     // step 4 
    //                     const step4response = await patentUploadStatus({ file_id,file_status: 'uploaded' });

    //                     if (step4response) {
    //                         setIsShow(false);
    //                         setIsAPICalled(false);
    //                         setBtnDisabled(true);
    //                         setSelectedFiles([]);
    //                         setFilePreviews([]);
    //                         Toast.success('Success','Patent added successfully');
    //                         setRefreshData(true);
    //                     }

    //                 } else {

    //                     const { url,file_id,...otherPayload } = step2Response;


    //                     // step 4 
    //                     const step4response = await patentUploadStatus({ file_id,file_status: 'pending' });

    //                     if (step4response) {
    //                         setIsShow(false);
    //                         setBtnDisabled(true);
    //                         setSelectedFiles([]);
    //                         setFilePreviews([]);
    //                         Toast.success('Success','Patent added successfully');
    //                         setRefreshData(true);
    //                     }

    //                 }
    //             }



    //         }


    //     }

    // }

    const submit = async (e: any) => {
        const userData: any = JSON.parse(localStorage.getItem('userData') as string);
        let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

        e?.preventDefault();
        setIsAPICalled(true);
        setUploadingFiles(true);

        if (toggle === 2 && selectedFiles.length > 0) {
            try {
                const uploadPromises = selectedFiles.map(async (currentFile) => {
                    // Step 1: Create patent
                    let payload = { 'patent_id': currentFile.name.split('.pdf')[0] };
                    const { data, status } = await createPatent(payload);

                    if (!data) {
                        throw new Error(`Failed to create patent for file ${currentFile.name}`);
                    }

                    // Step 2: Get presigned URL
                    const payload2 = {
                        "team_id": selectedTeam.currentTeamId,
                        "project_id": projectId,
                        "patent_id": data._id,
                        "file_name": currentFile.name,
                        "content_type": currentFile.type,
                        "patent_category": "compare",
                        "patent_type": "external"
                    };

                    const step2Response: any = await createPatentPresignedUrl(payload2);
                    if (!step2Response?.url) {
                        throw new Error(`Failed to get presigned URL for file ${currentFile.name}`);
                    }

                    // Step 3: Upload to S3
                    const step3payload: any = {
                        url: step2Response.url,
                        file: currentFile,
                        type: currentFile.type,
                    };

                    const response: any = await s3PutUrlWithProgress(step3payload, (progress) => {
                        setFileProgress(prev => ({
                            ...prev,
                            [currentFile.name]: Math.round(progress)
                        }));
                    });

                    // Step 4: Update upload status
                    const fileStatus = response.status === 200 ? 'uploaded' : 'pending';
                    const step4response = await patentUploadStatus({
                        file_id: step2Response.file_id,
                        file_status: fileStatus
                    });

                    return { success: true, fileName: currentFile.name };
                });

                const results = await Promise.all(uploadPromises);
                const allSuccessful = results.every(result => result.success);

                if (allSuccessful) {
                    Toast.success('Success', 'All patents added successfully');
                    setRefreshData(true);
                    resetModal();
                } else {
                    Toast.error('Error', 'Some files failed to upload');
                    setUploadingFiles(false);
                }
            } catch (error) {
                console.error('Upload error:', error);
                Toast.error('Error', 'Failed to upload files');
                setUploadingFiles(false);
            }
        }
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[700px]'} isShow={isShow}>
                <form onSubmit={submit}>
                    {/* Modal bg Shade */}

                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between pb-3'>
                            <img className='cursor-pointer' src={allImgPaths.addPatentLogo} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={allImgPaths.closeIcon} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-1'>Add Comparison Data</h2>
                        <p className='text-sm text-[#4F4D55]'>Add Files, patents, URLs to compare with the source patent</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-3'>
                        <div className="modal-container mt-3">
                            {toggle == 0 && <div className="">
                                <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                    Enter Patent #
                                    <img src={allImgPaths.questionCircle} alt='help' />
                                </div>
                                <div className="">
                                    <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Enter patent numbers separated by commas'} />
                                </div>
                            </div>}

                            {toggle == 1 && <div className="">
                                <div className="w-full">
                                    <FileExplorer />
                                </div>
                            </div>}

                            {toggle == 2 && <div className="bg-[#F9FAFB] px-3 pl-4 py-3 rounded-md">
                                <div
                                    className='relative border border-[#C9C9CC] hover:border-gray-400 border-dashed rounded-lg p-2 w-[99%]'
                                    id='dropzone'
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnter={(e) => e.preventDefault()}
                                    onDragLeave={() => { }}
                                    onDrop={handleDrop}>
                                    <input id='photo-dropbox' type='file' multiple accept=".pdf" className='absolute inset-0 w-full h-full opacity-0 z-50' onChange={handleFileChange} />
                                    <label className='flex cursor-pointer gap-1 w-full px-3 py-2 bg-[#F9FAFB] rounded-lg' htmlFor='photo-dropbox'>
                                        <input id='photo-dropbox' type='file' multiple accept=".pdf" className='sr-only' onChange={(e) => console.log(e.target.files)} />
                                        <img src={allImgPaths.uploadIcon} alt='upload.svg' />
                                        <span className='px-2'>
                                            Drag & drop patent file or <span className='text-[#1751D0]'>browse files</span>
                                        </span>
                                    </label>

                                </div>

                                {selectedFiles.length > 0 &&

                                    <FileUploadProgress files={selectedFiles} progress={fileProgress} />}
                            </div>}
                            {toggle == 3 && <div className="">
                                <div className='text-[#2D2B32] font-interMedium text-left  pb-1.5 flex gap-1'>
                                    URL
                                    <img src={allImgPaths.questionCircle} alt='help' />
                                </div>
                                <div className="">
                                    <input className='placeholder:text-[#7F7D83] placeholder:text-base text-base text-[#1D1C20] w-full  px-3 py-2 bg-[#FFFFFF] border border-[#E6E6E6] rounded-lg' name={'title'} placeholder={'Paste URL'} />
                                </div>
                            </div>}
                        </div>

                    </Body>
                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-8 px-6 pb-4'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            {!isAPICalled &&
                                <button className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${btnDisabled || uploadingFiles
                                    ? 'button-disabled'
                                    : ''
                                    }`} type='submit'>
                                    Upload
                                </button>}
                            {isAPICalled &&
                                <>
                                    <button disabled={true} className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1  ${btnDisabled || uploadingFiles
                                        ? 'button-disabled'
                                        : ''
                                        }`} type='submit'>
                                        Upload
                                    </button>
                                    <br />

                                </>
                            }

                        </div>
                        {/* {isAPICalled && <span>
                            Uploading file ...</span>} */}

                    </FooterModal>
                </form>
            </Modal>
        </>
    )

}
export default AddComparisonData;