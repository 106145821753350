import React,{ Dispatch,SetStateAction,useEffect,useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import { Body } from './Body';

import allImgPaths from "assets/images";
import { InputTextField } from 'components/InputField';
import Textarea from '../Textarea';
import { startVijilize } from "services/project";
import Toast from "../Toast";
interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
    selectedSource: any[],
    selectedComparePatent: any[],
    projectId: any,
    sourcePatents: any[],
    comparePatents: any[]
}

function VijiliseModal({ isShow,setIsShow,setRefreshData,selectedSource,selectedComparePatent,projectId, sourcePatents, comparePatents }: Iprops) {
    const [vijilizeDescription,setvijilizeDescription] = useState<string>('');

    const [vijilizeName,setVijilizeName] = useState<string>('');

    let filteredSourcePatents = sourcePatents.filter((item: any) => selectedSource.includes(item.patent_id));
    let filteredComparePatents = comparePatents.filter((item: any) => selectedComparePatent.includes(item.patent_id));

    const dataUsed = [
        { type: 'source',data: [...filteredSourcePatents] },
        { type: 'compare',data: [...filteredComparePatents] },
    ];

    const [activeIndex,setActiveIndex] = useState<number>(0);

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? 0 : index);
    };

    const handleProjectDescriptionChange = (e: any) => {
        setvijilizeDescription(e.target.value);

        let getTextArea = document.getElementById("project-desc");
        if (getTextArea) {
            if (e.target.value.length === 0) {
                e.target.style.height = "24px";
            } else {
                e.target.style.height = "auto";
                e.target.style.height = e.target.scrollHeight + "px";
            }
        }
    };
    const handleNameChange = (e: any) => {
        setVijilizeName(e.target.value);
    };
    const transformPatentDataArray = (
        inputDataArray: any[],
        additionalData: any
    ): any[] => {
        try {
            // Map through the array of input data and transform each object
            const transformedArray = inputDataArray.map(inputData => {
                // Extract the necessary parts from each input data object
                const { patent_id,file_id,s3_file_path,key } = inputData;
                const { team_id,project_id } = additionalData;

                // Construct the transformed object
                return {
                    team_id,
                    project_id,
                    file_id,
                    patent_id,
                    key,
                    s3_file_path
                };
            });

            return transformedArray;
        } catch (error) {
            console.error('Error transforming patent data array:',error);
            throw new Error('Failed to transform patent data array');
        }
    };
    const transformPatentDataArrayWithoutAdditional = (
        inputDataArray: any[]
    ): any[] => {
        try {
            // Map through the array of input data and transform each object
            const transformedArray = inputDataArray.map(inputData => {
                // Extract the necessary parts from each input data object
                const { patent_id,file_id,s3_file_path,key } = inputData;

                // Construct the transformed object
                return {
                    file_id,
                    patent_id,
                    key,
                    s3_file_path
                };
            });

            return transformedArray;
        } catch (error) {
            console.error('Error transforming patent data array:',error);
            throw new Error('Failed to transform patent data array');
        }
    };
    const startVijilizeProcess = async () => {
        let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

        let sourcePayload = [...filteredSourcePatents];
        // let comparePayload = [...comparePatents];
        let comparePayload = [...filteredComparePatents];

        let sourcevijilizeArr: any[];
        let compareVijilizeObj: any;
        let payload: any;


        let team_id: string = selectedTeam.currentTeamId;
        const project_id = projectId;
        if (comparePayload?.length) {
            compareVijilizeObj = { 'name': vijilizeName,"description": vijilizeDescription,source: transformPatentDataArrayWithoutAdditional([...sourcePayload]),compare: transformPatentDataArrayWithoutAdditional([...comparePayload]),"team_id": team_id,"project_id": project_id }
            payload = { type: 'comparer',data: compareVijilizeObj }
        } else {
            sourcevijilizeArr = transformPatentDataArray([...sourcePayload],{ team_id,project_id });
            payload = {
                type: 'analyzer',  // if both source and compare then 'comparer', if compare is empty 'analyzer'
                data: sourcevijilizeArr
            }
        }
        console.log("🚀 ~ startVijilizeProcess ~ payload:",payload);

        try {
            let response = await startVijilize(payload);
            Toast.success('Success','Process started');
            setRefreshData(true);
            setIsShow(false);

        } catch (error) {
            throw error;
        }

    }
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log('process of vijilizing');
        startVijilizeProcess();

    }
    const onCloseModal = () => {
        setIsShow(false);
    };
    const isSubmitDisabled = () => {
        return vijilizeName.trim() === '';
    };

    const renderAccordionItem = (item: any,index: number,type: string) => {
        const { itemType,data } = item;

        return (
            <div
                key={index}
                onClick={() => toggleAccordion(index)}
                className={`mb-3 last:mb-0 ${activeIndex === index ? '' : 'accordion-hide'}`}
            >

                {/* Header Section */}
                <div
                    className={`border border-[#F0F0F0] px-2 py-2 bg-[#FDFDFD] cursor-pointer accordion-heading flex items-center justify-between`}
                >
                    <div className="flex items-center gap-4 w-full justify-between">
                        <p className='text-[#555F6D] text-sm font-interMedium'>
                            {type.charAt(0).toUpperCase() + type.slice(1)} <span className='text-[#555F6D] text-sm font-interMedium'>({data.length})</span>
                        </p>
                        <img
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleAccordion(index);
                            }}
                            className="accordion-arrow cursor-pointer hover:bg-[#f2f4f7] rounded w-5"
                            src={allImgPaths.dropdownArrow}
                            alt="Toggle Dropdown"
                        />
                    </div>
                </div>

                {/* Content Section */}
                <div
                    className={`accordion-content border border-[#F0F0F0] border-t-0 ${activeIndex === index ? 'block' : 'hidden'}`}
                >
                    {data.map((fileData: any,fileIndex: any) => (

                        <div className="py-3 flex items-center">
                            <div className='ml-3 flex-1 cursor-pointer w-[202px]'>
                                <div className=''>
                                    <div className=''>
                                        <p className='text-sm font-interSemiBold text-[#1751D0]'>
                                            {fileData.file_name}
                                        </p>
                                        {/* <span className='text-sm font-interRegular text-[#4F4D55]'>
                                        {date}
                                    </span> */}
                                    </div>
                                    {/* <p
                                    title={description}
                                    className='text-sm font-interMedium text-[#7F7D83] line-clamp-2 max-w-64'
                                >
                                    {description}
                                </p> */}
                                </div>
                            </div>
                            {/* <div className='flex items-center justify-center w-14 h-14'>
                            <img
                                className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-[22px] p-1'
                                id='modal-toggle'
                                src={allImgPaths.closeIcon}
                                alt='close icon'
                            />
                        </div> */}
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    return (
        <>
            <Modal className={'modal-wrapper max-w-[780px]'} isShow={isShow}>
                <form onSubmit={handleSubmit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between'>
                            <img className='w-10 h-10' src={allImgPaths.serachIcon3} alt='remove' />
                            {/* Close Icon */}
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={allImgPaths.closeIcon} onClick={onCloseModal} alt='close icon' />
                        </div>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 pt-4'>
                        <div className="">
                            {/* Heading */}
                            <h2 className='text-base font-interSemiBold text-[#0A090B] pb-0.5'>Vijilize</h2>
                            <p className='text-sm text-[#4F4D55] mb-4'>Enter details for this vijilization</p>

                            {/* Notification */}
                            <div className="border border-[#ECECED] rounded-lg px-3 py-3 flex items-start gap-x-2 mb-3">
                                <img src={allImgPaths.InfoIconBlue} alt="" />
                                <p className='text-sm font-interMedium text-[#0A090B] leading-[1.6]'>Each vijilization takes some time to complete. You can find your past and active ones in the Vijilizations tab.</p>
                            </div>

                            {/* Content */}
                            <div className='flex gap-x-8'>
                                <div className="px-3 py-3 rounded-lg border border-[#E6E6E6] w-1/2">
                                    <h4 className='text-sm font-interMedium text-[#0A090B] pb-3'>Data Used</h4>

                                    {/* Accordion */}
                                    <div className="overflow-y-auto max-h-60 pr-2">
                                        {dataUsed.map((item,index) =>
                                            renderAccordionItem(item,index,item.type)
                                        )}
                                    </div>

                                </div>
                                <div className="w-1/2">
                                    <div className='mb-6'>
                                        <span className='text-[#2D2B32] font-interMedium text-sm inline-block pb-2'>Name</span>
                                        <InputTextField
                                            label={''}
                                            name={'vijiliseName'}
                                            placeholder={'Enter name'}
                                            onChange={handleNameChange}
                                            value={vijilizeName}
                                        />
                                        {/* <p className='text-red-600 text-base text-center pt-3'>{msg.err}</p> */}
                                    </div>

                                    <div className="">
                                        <div className="pb-2 flex items-center justify-between">
                                            <span className='text-[#2D2B32] font-interMedium text-sm inline-block '>Description</span>
                                            <span className='text-xs text-[#7F7D83]'>Optional</span>
                                        </div>
                                        <Textarea
                                            name="project-desc"
                                            placeholder="Enter description"
                                            value={vijilizeDescription}
                                            onChange={handleProjectDescriptionChange}
                                            id='project-desc'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-8 px-6 pb-4'>
                        <div className='flex items-center gap-x-3 justify-center' >
                            <button type="button" className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]' onClick={onCloseModal} >
                                Cancel
                            </button>

                            <button className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1 ${isSubmitDisabled()
                                ? 'button-disabled'
                                : ''
                                } `} disabled={isSubmitDisabled()} type='submit'>
                                Vijilize
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    )

}
export default VijiliseModal;