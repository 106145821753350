import React,{ useEffect,useRef,useState } from 'react';

import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';
import { useNavigate,useParams } from 'react-router-dom';
import { getPatentDetails } from "services/project";
import useOnClickOutside from "hooks/common/useOnClickOutside";
import ClaimAnalysisShimmerLoader from "pages/loaders/ClaimAnalysisLoader";
import useAppState from 'context/useAppState';
import ClaimData from 'components/ClaimData';
import CustomSelect from 'components/common/CustomSelect';
import CitationsSidebar from 'components/CitationsSidebar';

const ClaimAnalysis = () => {
    const [toggle,setToggle] = useState(0);
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<any>({
        patentId: null,
        claimIndex: null,
      });
    const [isOpen,setIsOpen] = useState(false);
    const { patentId: patentId } = useParams();
    const [showConfidence,setShowConfidence] = useState(false);
    const [activeMenu,setActiveMenu] = useState(null);
    const navigate = useNavigate();
    const [selectedClaim,setSelectedClaim] = useState<any>({})
    const [claimSelectionLoader,setClaimSelectionLoader] = useState<boolean>(false)
    const [isShowSideBarCitations, setIsShowSideBarCitations] = useState<boolean>(false);
    const [claimOptions, setClaimOptions] = useState<any[]>([]);
    const [selectedClaimOption, setSelectedClaimOption] = useState<string>('all');
    const [filteredClaimsData, setFilteredClaimsData] = useState<{ [key: string]: any }>({});
    
    
    
    const { setHeaderDataLocal } = useAppState('vijilize');


    const filterRef = useRef(null);
    useOnClickOutside(filterRef,() => {
        setShowConfidence(false);
        setIsOpen(false);
    });

    const [APIData,setAPIData] = useState<any>({});
    const [claimsData,setClaimsData] = useState<any>({});
    const [claimStructureData,setClaimStructureData] = useState<any>();

    const toggleAccordion = (
        patentId: string,
        claimIndex: number,
        claimKey: any
      ) => {
        setActiveIndex((prev:any) => ({
          patentId:
            prev.patentId === patentId && prev.claimIndex === claimIndex
              ? null
              : patentId,
          claimIndex:
            prev.patentId === patentId && prev.claimIndex === claimIndex
              ? null
              : claimIndex,
        }));
        setClaimSelectionLoader(true);
        setSelectedClaim(claimsData[patentId]?.claims[claimKey]);
        setTimeout(() => {
          setClaimSelectionLoader(false);
        }, 1000);
        setToggle(0);
      };

      const filterClaimsData = (sourceOption: string,type:string, claimOption:string) => {
        setClaimSelectionLoader(true);

        if(type === 'claim'){
            if (claimOption === 'all') {                
                setFilteredClaimsData(claimsData); 
            }else {
                const claimOptionData :any= claimOption.split('_');
                const claimKey = claimOptionData && claimOptionData[1];
                const sourceId = claimOptionData && claimOptionData[0];

                if(claimKey && sourceId){

                    const filteredClaims: any = {}; 
                    
                    if (claimsData[sourceId]) {
                    const patentClaims = claimsData[sourceId].claims;
                    
                    if (patentClaims[claimKey]) {
                        filteredClaims[sourceId] = {
                            claims: {
                                [claimKey]: patentClaims[claimKey],
                            },
                            patentId : claimsData[sourceId].patentId,
                            patentNumber : claimsData[sourceId].patentNumber,
                            structure : claimsData[sourceId].structure
                        };
                    }
                }

                const claimIndex = 0; 
                setActiveIndex((prev:any) => ({
                    patentId: sourceId ,
                    claimIndex: claimIndex
                }));
                setSelectedClaim(claimsData[sourceId]?.claims[claimKey]);
                setTimeout(()=>{
                    setFilteredClaimsData(filteredClaims);
                    const element:any = document.getElementById(`${sourceId}_${claimIndex}`);
                element.scrollIntoView({ behavior: 'smooth' });
                },1000)
            }
        }
        setTimeout(() => {
            setClaimSelectionLoader(false);
        }, 1000);
    }
};
    useEffect(() => {
        setIsLoading(true);
        (async () => {
            let patentData: any = await getPatentDetails(patentId || '0'); 

            setAPIData(patentData.data);
            setHeaderDataLocal((prev: any) => {
                return {
                  ...prev,
                  claimAnalysis: { title: patentData.data.patent_id, id: patentData.data._id },
                };
              });

              const claimsD = { [patentData.data._id] : {
                patentId : patentData.data._id,
                patentNumber : patentData.data.patent_id,
                claims : patentData.data.claims,
                structure : patentData.data.structure
              }}

            setClaimsData(claimsD || {});
            let claimKey = Object.keys(patentData?.data?.claims)[0];

            setActiveIndex({
                patentId: patentData?.data?._id,
                claimIndex: 0,
              });
            updateClaimOptions(patentData?.data?._id,claimsD);
            
            setSelectedClaim(patentData.data.claims[claimKey]);

            setIsLoading(false);

            setClaimSelectionLoader(true);
            setTimeout(() => {
                setClaimSelectionLoader(false);
            },1000);

        })();
    },[])

    useEffect(() => {
            filterClaimsData(activeIndex.patentId,'claim',selectedClaimOption);
        }, [claimsData, selectedClaimOption]);

    const handleViewCitations = (patentId: any, claimsKey: any) => {
        setIsShowSideBarCitations(true);
      };
    
    const handleClaimClick = (
        event: any,
        item: any,
        index: number,
        claimKey: string
      ) => {
        if (event.target.id === "viewCitations") {
          handleViewCitations(item.patentId, claimKey);
        } else {
          toggleAccordion(item.patentId, index, claimKey);
        }
      };

    const updateClaimOptions = (patentId: any,claimsData:any) => {
        setClaimOptions(createClaimOptions(patentId,claimsData));
    };

    const createClaimOptions = (patentId: any,claimsData:any) => {
        const claimOptions = [];
    
        claimOptions.push({
            value: 'all',
            label: 'All'
        });

            if((Object.keys(claimsData).length > 0)){
                
                const selectedPatentClaims = claimsData[patentId]?.claims;
                Object.keys(selectedPatentClaims).forEach(claimKey => {
                    claimOptions.push({
                        value: `${patentId}_${claimKey}`, 
                        label: claimKey
                    });
                });
            }
        // }
        return claimOptions;
    };

    const handleDropdownSelect = (event: React.ChangeEvent<HTMLInputElement>, type: 'source' | 'claim', data: any) => {
            if (type === 'claim') {
                const selectedValue = data.value;
                if(selectedValue === 'all'){
                    setActiveIndex((prev:any) => ({
                        patentId: activeIndex.patentId,
                        claimIndex: 0
                    }));

                    setTimeout(()=>{
                        const element:any = document.getElementById(`${activeIndex.patentId}_${activeIndex.claimIndex}`);
                        element.scrollIntoView({ behavior: 'smooth' });
                    },1000)
                }
                setSelectedClaimOption(selectedValue);
                filterClaimsData(activeIndex.patentId, 'claim', selectedValue);
            }
        };

    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />
            <div className='w-[calc(100%_-_72px)] ml-auto'>
                {/* Hedaer */}
                <Header title='Claim Analysis' count={0} isChildEle={true} />
                {isLoading ? <ClaimAnalysisShimmerLoader /> : <></>}
                {!isLoading ?
                    <div className='pt-4 px-4 h-[calc(100vh_-_69px)] overflow-y-auto scrollbar-adjust'>
                        {/* Heading */}
                        <div className='flex justify-between items-center w-full pb-10'>
                            <div className='flex items-start gap-x-2 pl-1'>
                                <img className='inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1' src={allImgPaths.goBack} alt='return' onClick={() => navigate(-1)} />
                                <div className=''>
                                    <h2 className='text-[#101828] text-xl font-interSemiBold'>Claim Analysis for Patent # {APIData?.patent_id}</h2>
                                    <p className='text-sm text-[#4F4D55] font-interRegular'>Description of the patent comes here</p>
                                </div>
                            </div>
                        </div>

                        <div className="py-2 px-3 flex gap-x-4 bg-white mb-2">                
                            <div className="">
                                <span className='text-[#2D2B32] text-sm font-interMedium block pb-1'>
                                    Claim
                                </span>
                                <div className="modal-select-box fete-settings-modals">
                                    <CustomSelect                 
                                        defaultValue={claimOptions[0]}
                                        options={claimOptions}
                                        onChange={(event:any) => handleDropdownSelect(event, 'claim', claimOptions.find(option => option.value === event.value))}
                                        extendedOption={false}
                                        className={`w-80`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className=''>
                            <ClaimData type="analysis" APIData={APIData} filteredClaimsData={filteredClaimsData} activeIndex={activeIndex} handleClaimClick={handleClaimClick} claimSelectionLoader={claimSelectionLoader} toggle={toggle} setToggle={setToggle} selectedClaim={selectedClaim} claimStructureData={claimStructureData} setClaimStructureData={setClaimStructureData} />

                        </div>
                    </div> : <></>}

            </div>
            <CitationsSidebar isShowSideBarCitations={isShowSideBarCitations} setIsShowSideBarCitations={setIsShowSideBarCitations}/>

        </div>
    );
};

export default ClaimAnalysis;
