import { useState, useEffect } from "react";

const useVijilize = () => {
  const [headerData, setHeaderData] = useState(() => {
    const storedData = localStorage.getItem("headerData");
    return storedData && storedData !== "undefined"
      ? JSON.parse(storedData)
      : {
          projectDetail: { title: "", description: "", id: "" },
          claimAnalysis: { title: "", id: "" },
          vijilize: { title: "", id: "" },
          claimChart: { title: "", id: "" },
        };
  });

  const [isActiveSection, setIsActiveSection] = useState(0);
  const [clickedHeatmapData, setClickedHeatmapData] = useState<any>(null);

  useEffect(() => {
    localStorage.setItem("headerData", JSON.stringify(headerData));
  }, [headerData]);

  const setVijilizeParamsData = (data: any) => {
    localStorage.setItem("vijilizeParamsData", JSON.stringify(data));
  };

  const getVijilizeParamsData = () => {
    const data = localStorage.getItem("vijilizeParamsData");
    return data ? JSON.parse(data) : null;
  };

  const setHeaderDataLocal = (data: any) => {
    setHeaderData(data);
  };

  return {
    getVijilizeParamsData,
    setVijilizeParamsData,
    isActiveSection,
    setIsActiveSection,
    setHeaderDataLocal,
    headerData,
    clickedHeatmapData,
    setClickedHeatmapData
  };
};

export default useVijilize;
