


import allImgPaths from "assets/images";
import { Dispatch,SetStateAction,useEffect,useState } from "react";
import { Modal } from "./Modal";
import { HeaderModal } from "./Header";
import { Body } from "./Body";
import CustomCheckBox from "components/CustomCheckBox";
import { FooterModal } from "./Footer";
import { getOtherTeamMemberList } from "services/team";
import { addInviteCollaborator } from "services/project";


interface Iprops {
    setOpenInviteCollaborators: Dispatch<SetStateAction<boolean>>;
    openInviteCollaborators: boolean;
    selectedProjectId: any;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}

const InviteCollaborator = ({ setOpenInviteCollaborators,openInviteCollaborators,selectedProjectId,setRefreshData }: Iprops) => {
    // const { setOpenInviteCollaborators,openInviteCollaborators,selectedProjectId } = props;
    const [error,setError] = useState("");
    const [invalidEmail,setInValidEmail] = useState(false)
    // const { updateEventCallApiFlag } = useAppState("eventData");
    // const { fetchCollaborators } = useAppState("boardData");

    const [filteredData,setFilteredData] = useState<any>([]);
    const currentTeam = JSON.parse(
        localStorage.getItem("selectedTeam") as string
    );
    // TODO: get id from the project list

    const [selectedRolesCheckbox,setSelectedRolesCheckbox] = useState<any[]>(
        []
    );
    const [selectedRolesDropdown,setSelectedRolesDropdown] = useState<any[]>([]);
    const [emailText,setEmailText] = useState("");
    const [emails,setEmails] = useState<string[]>([]);
    const [formData,setFormData] = useState<any>({
        selectedEmail: [],
        email: [],
    });

    useEffect(() => {
        //api call for filteredData to get
        (async () => {
            // const { data } = await getInviteTeamMemberList(
            //     params.id,
            //     currentTeam.currentTeamId
            // );
            // Team member
            const userData = JSON.parse(localStorage.getItem('userData') as string);
            let payload = {
                team_id: currentTeam.currentTeamId,
                user_id: userData.id
            };

            const teamMembers = await getOtherTeamMemberList(payload);


            let data: any[] = teamMembers.data;

            const filterRecords = data.map((item: any,index: any) => ({
                id: item._id,
                index: index + 1,
                profile_image: item.profile_image,
                name: item.name,
                userName: `@${item.email.split("@")[0]}`,
                email: item.email,
                teams: {
                    roleName: item.name,
                },
            }));


            setFilteredData(filterRecords);
        })();
    },[openInviteCollaborators]);

    const handleChangeRole = (index: any,selectedRole: any) => {
        const updatedRoles = [...selectedRolesDropdown];
        updatedRoles[index] = selectedRole;
        setSelectedRolesDropdown(updatedRoles);

        setFormData({ ...formData,email: updatedRoles });
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        setEmailText(value);
    };

    const handleAddEmail = () => {
        setError("");
        setInValidEmail(false);

        const trimmedText = emailText.replace(/[,\s]/g,"");

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(trimmedText);

        if (!trimmedText || emails.includes(trimmedText)) {
            // If textarea is empty or email already exists, do not add it
            return;
        }
        if (trimmedText && isValid) {
            const updatedEmails = [...emails,trimmedText];
            setEmails([...emails,trimmedText]);
            setFormData({ ...formData,selectedEmail: updatedEmails });
            setEmailText("");
        } else {
            setError("Please enter valid email");
            setInValidEmail(true);
        }
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleAddEmail();
        }
    };

    const handleDelete = (index: number) => {
        const updatedEmails = emails.filter((_,i) => i !== index);
        setEmails(updatedEmails);
        setFormData({ ...formData,selectedEmail: updatedEmails });
    };

    const handleChangeCheckbox = (index: number,isChecked: boolean) => {
        // Update the selectedRoles array based on checkbox change
        const updatedRoles = [...selectedRolesCheckbox];
        updatedRoles[index] = isChecked;
        setSelectedRolesCheckbox(updatedRoles);
    };

    const constructPayload = () => {
        const selectedEmails = filteredData
            .filter((_: any,index: any) => selectedRolesCheckbox[index])
            .map((item: any,index: number) => ({
                email: item.email,
            }));

        const emailFromTextarea = emails.map((email) => ({
            email
        }));

        const payload = {
            user_details: [...selectedEmails,...emailFromTextarea],
            team_id: currentTeam.currentTeamId,
        };

        return payload;
    };

    const postData = async () => {
        const payload = constructPayload();
        const { data,status } = await addInviteCollaborator(selectedProjectId,payload);
        // TODO; future to work flowlessly
        // updateEventCallApiFlag(true);
        // fetchCollaborators(params.id)
        setEmails([]);
        setEmailText("");
        setOpenInviteCollaborators(false);
        setError("")
        setRefreshData(true);
    };
    const handleSubmit = (event: any) => {
        event?.preventDefault();
        const payload = constructPayload();
        postData();
    };

    const handleUserImageError = (e: any) => {
        e.target.src = allImgPaths.defaultProfile
    }

    const isSubmitDisabled = () => {
        // Check if textarea is empty
        const isTextAreaEmpty = emailText.trim() === '';

        // Check if there are no emails added
        const noEmailsAdded = emails.length === 0;

        // Check if filteredData is empty or no checkboxes are selected
        const noTeamMembersSelected = filteredData.length === 0 ||
            !selectedRolesCheckbox.some(isSelected => isSelected === true);

        // Button should be disabled if textarea is empty AND 
        // (no emails added AND no team members are selected)


        return invalidEmail || (isTextAreaEmpty && noEmailsAdded && noTeamMembersSelected);
    };

    return (
        <Modal
            className={"modal-wrapper max-w-[480px]"}
            isShow={openInviteCollaborators}
        >
            <form onSubmit={handleSubmit}>
                <HeaderModal
                    title="Fete Settings"
                    isShowHeader={true}
                    className="modal-header px-6 pb-5"
                >
                    <div className="py-6">
                        <img
                            className="w-[120px] h-[56px] mx-auto"
                            src={allImgPaths.inviteTeamProjectIcon}
                            alt=""
                        />
                    </div>
                    <div className="">
                        <h2 className="text-lg text-[#101828] text-center font-interSemiBold leading-none pb-1.5">
                            Add to your project
                        </h2>
                    </div>

                    <p className="text-sm text-[#475467] text-center">
                        Invite colleagues
                    </p>

                    <div onClick={() => setOpenInviteCollaborators(false)} className="absolute top-6 right-6 cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1">
                        <img src={allImgPaths.closeIcon} alt="" />
                    </div>
                </HeaderModal>

                <Body title="" isShowBody={true} className="modal-body px-6">
                    <div className="mb-5">
                        <p className="text-sm text-[#344054] font-interMedium pb-2">
                            Invite with Email
                        </p>
                        <div className="border border-[#D0D5DD] rounded-lg w-full py-3 px-3.5 flex flex-col-reverse h-24 overflow-y-auto hover:border-[#1E0AF5] focus-within:border-[#1E0AF5]">
                            <textarea
                                className="resize-none mb-auto min-h-8 placeholder:text-base placeholder:text-[#667085]"
                                name="selectedEmail"
                                id="selectedEmail"
                                value={emailText}
                                onChange={handleEmailChange}
                                onKeyUp={handleKeyUp}
                                onBlur={handleAddEmail}
                                placeholder="Enter email addresses"
                            />

                            <div className="chips-container">
                                {emails.map((email: string,index: number) => (
                                    <div key={index} className="chip">
                                        {email}
                                        <button onClick={() => handleDelete(index)}>&times;</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <p className="text-sm text-[#475467] pt-1">
                            Invites sent out to non-team-members will have to be approved by
                            the project owners
                        </p>
                        <div className="text-red-500">
                            {error}
                        </div>
                    </div>

                    <div className="flex justify-between items-center mb-3">
                        <p className="text-[#344054] text-sm font-interMedium">
                            Select from Team Members
                        </p>
                    </div>

                    <div className=" mb-4">
                        {filteredData.length > 0 &&
                            filteredData.map((member: any,index: number) => (
                                <div className="" key={index}>
                                    <div className="flex justify-between mb-1">
                                        <div className="flex w-[75%] items-center checkbox-alignment">
                                            <span className="inline-block mr-3">
                                                <CustomCheckBox
                                                    id={`checkBox-${index}`}
                                                    name={`checkBox-${index}`}
                                                    checked={selectedRolesCheckbox[index] || false}
                                                    onChange={(e: any) =>
                                                        handleChangeCheckbox(index,e.target.checked)
                                                    }
                                                />
                                            </span>

                                            <img
                                                className="w-10 h-10 rounded-full mr-3 border border-[#ccc]"
                                                src={member?.profile_image || allImgPaths.defaultProfile}
                                                alt=""
                                                onError={handleUserImageError}
                                            />
                                            <div className="">
                                                <h6 className="text-[#101828] text-sm font-interSemiBold">
                                                    {member.name}
                                                </h6>
                                                <p className="text-sm text-[#475467]">
                                                    {member.email}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                    </div>
                </Body>

                <FooterModal
                    title=""
                    isShowFooter={true}
                    className="modal-footer pt-4 px-6 pb-4 border-t border-[#EAECF0]"
                >
                    <div className="flex items-center gap-x-3 justify-end">
                        <button onClick={() => setOpenInviteCollaborators(false)} className="text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base hover:bg-[#F2F4F7] w-40">
                            Cancel
                        </button>
                        <button disabled={isSubmitDisabled()} className={`!text-base text-white bg-[#1E0AF5] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 w-40 ${isSubmitDisabled()
                            ? 'button-disabled'
                            : ''
                            }`} >
                            Done
                        </button>
                    </div>
                </FooterModal>
            </form>
        </Modal>
    );
};

export default InviteCollaborator;
