import allImgPaths from "assets/images";

const DownloadClaimchart = () => {

  const AppendixData = [
    {
      main_heading: 'Source',
      sections: [
        {
          claim_id: 'US8844098B2',
          date: '2019-09-10',
          sub_heading: 'Apparatus for gripping handheld devices',
          date_by: '06/05/2024',
          author: 'by James Greer'
        },
        {
          claim_id: 'US8844098B2',
          date: '2019-09-10',
          sub_heading: 'Apparatus for gripping handheld devices',
          date_by: '06/05/2024',
          author: 'by James Greer'
        }
      ]
    },

    {
      main_heading: 'References/Compared With',
      sections: [
        {
          claim_id: 'US8844098B2',
          date: '2019-09-10',
          sub_heading: 'Apparatus for gripping handheld devices',
          date_by: '06/05/2024',
          author: 'by James Greer'
        },
        {
          claim_id: 'US8844098B2',
          date: '2019-09-10',
          sub_heading: 'Apparatus for gripping handheld devices',
          date_by: '06/05/2024',
          author: 'by James Greer'
        },
        {
          claim_id: 'US8844098B2',
          date: '2019-09-10',
          sub_heading: 'Apparatus for gripping handheld devices',
          date_by: '06/05/2024',
          author: 'by James Greer'
        }
      ]
    }

  ]

  return (
    <>
      <div className="">
        {/* Header */}
        <div className="px-6 py-6 border-b border-[#F0F0F0] bg-[#fefdfd] flex items-end justify-between">
          <div className="">
            <img className="mb-1.5" src={allImgPaths.mainLogo} alt="" />
            <p className="text-bse font-interSemiBold text-[#1A75FF] pb-2">Claim Chart - For Claim 1</p>

            <div className="flex items-center gap-x-1.5">
              <img src={allImgPaths.deviceHolderIcon} alt="" />
              <span className="text-[#344051] text-xs font-interMedium">Device Holder</span>
            </div>
          </div>

          <div className="flex items-center gap-x-4">
            <div className="flex items-center gap-x-1.5">
              <img src={allImgPaths.calenderIcon} alt="" />
              <span className="text-[#344051] text-xs font-interRegular">Dec 3 2023, Sunday 17:45 PM</span>
            </div>
            <div className="flex items-center gap-x-1.5">
              <img src={allImgPaths.userProfileIcon} alt="" />
              <span className="text-[#344051] text-xs font-interRegular">James Greer</span>
            </div>
          </div>

        </div>
        {/* Body */}
        <div className="">
          {/* <p>Body</p> */}
          <div className="px-8">
            <h4 className="text-lg font-interMedium text-[#141C24] pt-3">Appendix</h4>
            {
              AppendixData.map((item, key) => {
                return (
                  <>
                    <div key={key} className="">
                      <h5 className="text-base font-interMedium text-[#637083] py-4">{item.main_heading}</h5>

                      {item.sections.map((data, idx) => (
                        <div key={idx} className="border-[#F1F1F1] border-b pb-3 pt-3 first-of-type:pt-0 px-6">
                          <p className="items-center flex gap-x-3 pb-1">
                            <span className="text-sm font-interSemiBold text-[#1751D0]">{data.claim_id}</span>
                            <span className="text-sm font-interRegular text-[#4F4D55]">{data.date}</span>
                          </p>
                          <p className="text-sm font-interSemiBold text-[#7F7D83]">{data.sub_heading}</p>
                          <p>
                            <span className="text-[#ADACB0] text-xs font-interRegular">{data.date_by}</span>
                            <span className="text-[#ADACB0] text-xs font-interRegular"> {data.author}</span>
                          </p>
                        </div>
                      ))
                      }
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>

        {/* Footer */}
        <div className="px-6">
          <p className="text-xs italic text-[#7E7E7E] font-interRegular pb-2 mx-auto max-w-screen-lg">** All data has been generated using IPVijil’s AI Assist technology. It may need human verification before proceeding with legal action.**</p>

          <div className="flex justify-between items-center border-t border-[#CED4DA] pt-1">
            <p>
              <span className="text-[#1A75FF] font-interSemiBold text-xs"> IPVijil.ai</span>
              <span className="text-[#7E8C9A] font-interRegular text-xs pl-2"> AI powered IP Intelligence. © 2025 All Rights Reserved.</span>
            </p>
            <p className="text-[#7E8C9A] font-interRegular text-xs">Page 1 of 3</p>
          </div>

        </div>
      </div>
    </>
  )
}

export default DownloadClaimchart;