import React, { useEffect, useCallback, useRef } from 'react';
import mermaid from 'mermaid';
import svgPanZoom from 'svg-pan-zoom';

export interface MermaidProps {
  text: string;
  className?: string;
  height?: string;
  width?: string;
  panZoomEnabled?: boolean; 
}

export const Mermaid: React.FC<MermaidProps> = ({
  text,
  className = 'w-full h-[calc(100vh_-_300px)] flex justify-center items-center',
  panZoomEnabled = false, 
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const panZoomInstanceRef = useRef<any>(null);
  const renderIdRef = useRef(`mermaid-${Math.random().toString(36).slice(2)}`);

  const initializeMermaid = useCallback(async () => {
    try {
      await mermaid.initialize({
        startOnLoad: true,
        securityLevel: 'loose',
        theme: 'base',
        logLevel: process.env.NODE_ENV === 'development' ? 'debug' : 'error',
        themeVariables: {
          background: '#f0f0f0',
          primaryColor: '#fff',
          primaryBorderColor: '#000000',
          primaryBorderWidth: '2px',
          nodeBorder: '#CFD6DD',
          nodeBorderWidth: '2px',
          nodeTextColor: '#272E35',
          fontFamily: 'Inter',
          fontSize: '14px',
          borderRadius: '10px',
          nodeRadius: '8px',
        },
      });
    } catch (error) {
      console.error('Failed to initialize mermaid:', error);
    }
  }, []);

  const renderDiagram = useCallback(async () => {
    if (!containerRef.current || !text) return;

    try {
      // Clear previous content and destroy previous pan-zoom instance
      containerRef.current.innerHTML = '';
      if (panZoomInstanceRef.current) {
        panZoomInstanceRef.current.destroy();
        panZoomInstanceRef.current = null;
      }

      const { svg } = await mermaid.render(renderIdRef.current, text);

      if (containerRef.current) {
        containerRef.current.innerHTML = svg;

        if (panZoomEnabled) {
          const svgElement = containerRef.current.querySelector('svg');
          if (svgElement) {
            svgElement.style.height = '90%';
            panZoomInstanceRef.current = svgPanZoom(svgElement, {
              zoomEnabled: true,
              panEnabled: true,
              controlIconsEnabled: true,
              fit: true,
              center: true,
              minZoom: 1,
              maxZoom: 20,
            });
          }
        }
      }
    } catch (error) {
      console.error('Failed to render mermaid diagram:', error);

      if (containerRef.current) {
        containerRef.current.innerHTML = `
          <div style="color: red; padding: 1rem;">
            Failed to render diagram: ${
              error instanceof Error ? error.message : 'Unknown error'
            }
          </div>
        `;
      }
    }
  }, [text, panZoomEnabled]);

  // Initialize mermaid once
  useEffect(() => {
    initializeMermaid();
  }, [initializeMermaid]);

  // Render diagram when text or panZoomEnabled changes
  useEffect(() => {
    const renderTimer = setTimeout(renderDiagram, 100);
    return () => clearTimeout(renderTimer);
  }, [text, renderDiagram, panZoomEnabled]);

  return (
    <div
      ref={containerRef}
      className={`mermaid-diagram ${className}`.trim()}
      data-testid="mermaid-container mx-auto"
      // style={{ height, width }}
    />
  );
};

export default Mermaid;
