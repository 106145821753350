import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import {sliderSetting} from '../../utils/constant';
import {Link} from 'react-router-dom';
// import Footer from "../../components/Footer";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import allImgPaths from 'assets/images';

function MailSent() {
    const [userEmail, setUserEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail') as string;
        setUserEmail(userEmail);
    }, []);

    var settings = {
        dots: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
    };
    const sliderRef = useRef<any>(null);

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };
    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    return (
        <div className='flex'>
            {/* Login Left */}
            <div className='w-1/2'>
                <div className='rounded-2xl signup-slider-wrapper'>
                    <Slider {...settings} ref={sliderRef} {...settings} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.sliderImg} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#101828] pb-3'>
                                    Informed Decisions, Every <br /> Claim Analyzed
                                </h3>
                                <p className='text-base text-[#667085] w-full max-w-[500px] mx-auto'>
                                    Leverage AI Copilot to draw valuable insights from <br /> comprehensive analysis, empowering you to make well- <br />
                                    informed decisions.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            {/* Forgot Password Form */}
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto customHeight800:pt-8 customHeight700:pt-14'>
                    <div className='w-full max-w-[360px] mx-auto'>
                        {/* Logo */}
                        <div className='text-center pb-8 pt-0'>
                            <img className='inline-block' src={allImgPaths.mainLogo} alt='logo' />
                        </div>

                        {/* Content */}
                        <h1 className='font-interSemiBold text-2xl text-[#101828] pb-3.5 text-center'>{'Check your email'}</h1>
                        <p className='text-[#475467] pb-8 text-center'>
                            <span>
                                We sent a password reset link to <span className='font-interMedium'>{userEmail}</span>, follow the instructions on how to reset your password.
                            </span>
                        </p>

                        <p className='text-center'>
                            <span className='text-sm text-[#475467]'>Wrong email address? </span>
                            <Link className='text-sm font-interSemiBold text-[#4B3BF7]' to='/forgotpassword'>
                                re-enter email address
                            </Link>
                        </p>

                        <p className='text-sm text-[#475467] font-interSemiBold flex gap-x-3 justify-center mt-7 cursor-pointer hover:text-[#1e0af5] custom-group-hover' onClick={() => navigate('/login')}>
                            <img className='group-hover-child' src={allImgPaths.goBack} alt='arrow' />
                            {'Back to log in'}
                        </p>
                    </div>
                </div>

                {/* <Footer /> */}
                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MailSent;
