import ErrorPage from "./ErrorPage";
import { ErrorBoundary } from "react-error-boundary";
import Toast from "./Toast";

export default function ReactErrorBoundary(props: any) {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={(error,errorInfo) => {
                // log the error
                console.log("Error caught!");
                console.error(error);
                console.error(errorInfo);
                Toast.error('API error',error.message)

            }}
            onReset={() => {
                // reloading the page to restore the initial state
                // of the current page
                console.log("reloading the page...");
                window.location.reload();
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}