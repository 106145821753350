import { useMemo,useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import {
  getPendingInvitations,
  acceptPendingInvitations,
} from "../../services/auth/auth";
import { selectTeam } from "../../services/user";
import { ExtendedProps,optionProp } from "../../types";
import defaultProfileImage from '../../assets/images/profile-img-new.svg';
import { getUserTeams } from "services/team";

const useSelectTeam = () => {
  const navigate = useNavigate();
  const initialOption = { value: '',label: '',image: "" }
  const [userTeams,setUserTeams] = useState([]);
  const [isLoadingTeam,setIsLoadingTeam] = useState<boolean>(false);
  const [pendingInvitations,setPendingInvitations] = useState([]);
  const [currentSelectedTeam,setCurrentSelectedTeam] = useState(null);
  const [teamList,setTeamList] = useState<ExtendedProps[]>([]);
  const [selectedTeam,setSelectedTeam] = useState<optionProp>(initialOption);
  const [hasRetrievedTeams,setHasRetrievedTeams] = useState(false);
  const [isTeamChanged,setTeamChange] = useState(false)
  const { pathname } = useLocation();

  const resetStateToDefaults = () => {
    setUserTeams([]);
    setTeamList([]);
    setPendingInvitations([]);
    setSelectedTeam(initialOption);
    setCurrentSelectedTeam(null);
    setHasRetrievedTeams(false);
  }

  const handleChangeTeam = (selectionOption: any) => {
    if (selectionOption?.value === 'addNewTeam') {
      navigate('/team-setup?existing=true')
      return;
    }
    setSelectedTeam(selectionOption)
    setCurrentTeam(selectionOption?.value)
  }

  useMemo(() => {
    const result: ExtendedProps[] = [];
    userTeams.forEach((element: any) => {
      result.push(
        {
          value: element.team_id,label: element.team_name,
          image: element.logo ? element?.logo : defaultProfileImage
        }
      )
    });
    setTeamList(result);
    setSelectedTeam(result[0]);
    // onChange need to call API
    setHasRetrievedTeams(false);
  },[userTeams.length]);

  const retrieveUserTeams = async () => {
    try {
      setIsLoadingTeam(true);
      const response = await getUserTeams();
      setUserTeams(response.data);
      setHasRetrievedTeams(true);
      setIsLoadingTeam(false);
    } catch (error) {
      console.log("error while fetching the user teams =",error);
    }
  };

  const retrievePendingInvitations = async () => {
    try {
      const response = await getPendingInvitations();
      setPendingInvitations(response.data);
    } catch (error) {
      console.log("error while fetching the pending invitations =",error);
    }
  };

  const acceptInvitation = async (invitation_id: string) => {
    try {
      await acceptPendingInvitations({ invitation_id: invitation_id });
      window.location.reload();
    } catch (error) {
      console.log("error while accepting invitation =",error);
    }
  }

  const setCurrentTeam = async (team: any) => {
    updateCurrentTeam(team)
    if (pathname != '/') {
      navigate('/');
    }
  }

  const updateCurrentTeam = async (team: any) => {
    const selectedTeamRes: any = await selectTeam(team);
    const payload = {
      currentRole: selectedTeamRes.role.name,
      currentTeamId: selectedTeamRes.teamId
    }
    localStorage.setItem('selectedTeam',JSON.stringify(payload));
    setCurrentSelectedTeam(team);
    resetStateToDefaults();
    setTeamChange(false)
  }

  return {
    retrieveUserTeams,
    retrievePendingInvitations,
    acceptInvitation,
    setCurrentTeam,
    handleChangeTeam,
    userTeams,
    isLoadingTeam,
    teamList,
    selectedTeam,
    hasRetrievedTeams,
    setHasRetrievedTeams,
    pendingInvitations,
    currentSelectedTeam,
    updateCurrentTeam,
    isTeamChanged,
    setTeamChange
  };
};
export default useSelectTeam;
