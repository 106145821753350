import allImgPaths from 'assets/images';
import React from 'react';
interface FileUploadProgressProps {
    files: File[];
    progress: { [key: string]: number };  // Changed to track progress per file
}

const FileUploadProgress = ({ files, progress }: FileUploadProgressProps) => {
    return (
        <div className="mt-5 overflow-y-auto max-h-[calc(100vh_-_440px)] pr-1.5">
            {files.map((file, index) => {
                const fileProgress = progress[file.name] || 0;

                return (
                    <div key={file.name + index} className="rounded-md p-2.5 mb-5 last:mb-0 border border-[#E6E6E6]">
                        <div className="flex items-start justify-between">
                            <div className="flex items-start gap-x-3 w-full">
                                <img src={allImgPaths.pdfIcon} alt="" />
                                <div className='w-full'>
                                    <div className="flex items-center justify-between w-full">
                                        <p title={file.name} className="font-interMedium text-[#0A090B] text-sm pb-1 truncate max-w-96">{file.name}</p>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="text-sm text-[#4F4D55] font-interRegular">
                                            {(file.size / 1024 / 1024).toFixed(2)} MB
                                        </span>

                                        {fileProgress === 100 && (
                                            <span className='flex items-center justify-between text-[#4F4D55] font-interRegular text-sm gap-x-1'>
                                                <img src={allImgPaths.greenCheckIcon} alt="" />
                                                Complete
                                            </span>
                                        )}

                                        {fileProgress >= 1 && fileProgress !== 100 && (
                                            <span className="text-[#4F4D55] font-interRegular text-sm">
                                                {fileProgress}%
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {fileProgress >= 1 && (
                            <div className="mt-2.5 pl-8">
                                <div className="h-1.5 bg-[#ECECED] rounded-lg">
                                    <div
                                        className="h-1.5 bg-[#1751D0] transition-all duration-300 rounded-lg"
                                        style={{ width: `${fileProgress}%` }}
                                    />
                                </div>
                            </div>

                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default FileUploadProgress;