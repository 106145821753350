import React,{ Dispatch,SetStateAction,useState,useMemo } from 'react';
import { Modal } from './Modal';
import { HeaderModal } from './Header';
import { FooterModal } from './Footer';
import folderPlus from 'assets/images/folder-plus-blue.svg';
import close from 'assets/images/close-icon.svg';
import { Body } from './Body';
import { addProject,deleteProject } from "services/project";
import { InputTextField } from "components/InputField";
import Textarea from "../Textarea";
import Toast from "../Toast";
import allImgPaths from 'assets/images';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    redirectToHome: Dispatch<SetStateAction<boolean>>;
    projectId: string
    setRefreshData:any
}

const DeleteModal = ({ isShow,setIsShow,setToast,redirectToHome,projectId }: Iprops) => {

    const onCloseModal = () => {
        setIsShow(false);
    };

    const handleSubmit = async (event: any) => {
        event?.preventDefault();
        let delRes = await deleteProject(projectId);
        console.log(delRes,'delres');
        setIsShow(false);
        redirectToHome(true);
    };

    return (
        <>
            <Modal className={'modal-wrapper max-w-[420px]'} isShow={isShow}>
                <form onSubmit={handleSubmit}>
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header pt-6 px-6'>
                        <div className='flex items-center justify-between pb-5'>
                            <img className='h-10 w-10' src={allImgPaths.deletIconModal} alt='remove' />
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1 mb-4' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        <h2 className='text-base text-[#0A090B] font-interSemiBold pb-4'>Delete Project</h2>
                        <p className='text-sm text-[#4F4D55] font-interRegular'>Do you want to delete this project? Once you do it will be permanently be removed from the system and all the data built on this project including claim charts will be lost forever.</p>
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body'>
                        <>
                        </>
                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button
                                onClick={onCloseModal}
                                className='text-[#4F4D55] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'
                            >
                                Cancel
                            </button>
                            <button
                                className={`!text-base text-white font-interSemiBold bg-[#E12121] hover:bg-[#B42318] rounded-lg px-3.5 py-2.5 flex-1`}
                                type='submit'
                            >
                                Yes, Delete
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal>
        </>
    );
};

export default DeleteModal;