import React, { Dispatch, SetStateAction, useState } from 'react';

import { Modal } from './Modal';

import { HeaderModal } from './Header';
import { FooterModal } from './Footer';

import create from 'assets/images/create-claim.svg';
import close from 'assets/images/close-icon.svg';
import question from 'assets/images/help-circle-contained-filled.svg';
import dropArrow from 'assets/images/dropdown.svg';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';



import { Body } from './Body';
import { log } from 'console';

interface Iprops {
    isShow: boolean;
    setIsShow: Dispatch<SetStateAction<boolean>>;
    setToast: any;
    setScoringValue: Dispatch<SetStateAction<any>>;
    selectedSimilarity: any[];
    configurationobj: any;
    setConfigurationobj: any;
}

const ConfigureScoring = ({ isShow, setIsShow, setToast, setScoringValue, selectedSimilarity, configurationobj, setConfigurationobj }: Iprops) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sliderVal, setSliderval] = useState(0);

    const onCloseModal = (e: any) => {
        e.preventDefault();
        setIsShow(false);
    };
    const [sliderValues, setSliderValues] = useState({
        specification: 90,
        prosecution: 90,
        precedential: 50,
        dictionary: 55,
        articleAccuracy: 65,
    });
    const handleSliderChange = (name: string, value: number) => {


        setConfigurationobj((prev: any) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: any) => {
        event?.preventDefault();

        setScoringValue(configurationobj);
        setIsShow(false);
    };

    const handleSlider = (e: any) => {
        setSliderval(e);
    }

    const RangeSlider = ({ name, slug, value, isDisabed }: { name: string; slug: string; value: number; isDisabed?: any }) => (
        <div className='mb-2.5'>
            <div className='text-sm font-interRegular text-[#555F6D] mb-1.5'>{name}</div>
            <div className=''>
                <div className={`relative w-full cursor-default`}>
                    <input
                        type='range'
                        min='0'
                        max='100'
                        step='1'
                        value={value}
                        onChange={(e) => handleSliderChange(slug, parseInt(e.target.value))}
                        className='w-full h-2 cursor-pointer'
                        style={{
                            WebkitAppearance: 'none',
                            background: `linear-gradient(to right, #1751D0 ${value}%, #E5E7EB ${value}%)`,
                            borderRadius: '9999px',
                        }}
                    />
                </div>
                <div className="flex items-center justify-end">
                    {/* <span className='text-sm font-interMedium text-[#4F4D55]'>0</span> */}
                    <span className='text-sm font-interMedium text-[#4F4D55]'>{value}%</span>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <Modal className={'modal-wrapper max-w-[620px]'} isShow={isShow}>
                <form onSubmit={handleSubmit} >
                    <HeaderModal title={'test'} isShowHeader={true} className='modal-header py-5 px-6 border-b border-[#ECECED]'>
                        <div className='flex items-center justify-between pb-0.5'>
                            <h2 className='text-base text-[#0A090B] font-interSemiBold'>Configure Scoring Weightage</h2>
                            <img className='modal-close modal-toggle cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1' id='modal-toggle' src={close} onClick={onCloseModal} alt='close icon' />
                        </div>

                        {/* <p className='text-sm text-[#4F4D55]'>4 matches found</p>
                        {selectedSimilarity?.length && <p className='text-sm text-[#4F4D55]'>{selectedSimilarity?.length} matches found</p>} */}
                    </HeaderModal>

                    <Body title='' isShowBody={true} className='modal-body px-6 py-5'>
                        <div className='flex flex-col gap-y-4'>
                            <RangeSlider name='Specification' slug='specification' value={configurationobj.specification} />
                            {/* <div className="mb-4">
                                <p className='text-sm font-interRegular text-[#555F6D] mb-3'>Specification</p>
                                <Slider
                                    min={0}
                                    max={100}
                                    onChange={handleSlider}
                                />
                                <div className="flex items-center justify-end mt-2">
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>{sliderVal}%</span>
                                </div>
                            </div> */}

                            {/* Disabled */}
                            <div className="mb-6 disabled-rc-slider relative">
                                <span className="text-xs font-interMedium text-[#1751D0] bg-[#b5ccff80] border border-[#1751D0] px-2 py-0.5 rounded-full inline-block -top-2.5 right-4 absolute">Coming soon</span>
                                <p className='text-sm font-interRegular text-[#555F6D] mb-3'>Prosecution</p>
                                <Slider />
                                <div className="flex items-center justify-end mt-2">
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>0%</span>
                                    {/* <span className='text-sm font-interMedium text-[#4F4D55]'>100</span> */}
                                </div>
                            </div>

                            <div className="mb-6 disabled-rc-slider relative">
                                <span className="text-xs font-interMedium text-[#1751D0] bg-[#b5ccff80] border border-[#1751D0] px-2 py-0.5 rounded-full inline-block -top-2.5 right-4 absolute">Coming soon</span>
                                <p className='text-sm font-interRegular text-[#555F6D] mb-3'>Precedential</p>
                                <Slider />
                                <div className="flex items-center justify-end mt-2">
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>0%</span>
                                    {/* <span className='text-sm font-interMedium text-[#4F4D55]'>100</span> */}
                                </div>
                            </div>

                            <div className="mb-6 disabled-rc-slider relative">
                                <span className="text-xs font-interMedium text-[#1751D0] bg-[#b5ccff80] border border-[#1751D0] px-2 py-0.5 rounded-full inline-block -top-2.5 right-4 absolute">Coming soon</span>
                                <p className='text-sm font-interRegular text-[#555F6D] mb-3'>Dictionary</p>
                                <Slider />
                                <div className="flex items-center justify-end mt-2">
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>0%</span>
                                    {/* <span className='text-sm font-interMedium text-[#4F4D55]'>100</span> */}
                                </div>
                            </div>

                            <div className="disabled-rc-slider relative">
                                <span className="text-xs font-interMedium text-[#1751D0] bg-[#b5ccff80] border border-[#1751D0] px-2 py-0.5 rounded-full inline-block -top-2.5 right-4 absolute">Coming soon</span>
                                <p className='text-sm font-interRegular text-[#555F6D] mb-3'>Article Accuracy</p>
                                <Slider />
                                <div className="flex items-center justify-end mt-2">
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>0%</span>
                                    {/* <span className='text-sm font-interMedium text-[#4F4D55]'>100</span> */}
                                </div>
                            </div>
                        </div>

                    </Body>

                    <FooterModal title='' isShowFooter={true} className='modal-footer pt-5 px-6 pb-6'>
                        <div className='flex items-center gap-x-3 justify-center'>
                            <button onClick={onCloseModal} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide py-2.5 px-2.5 !text-base flex-1 hover:bg-[#f2f4f7]'>
                                Cancel
                            </button>
                            <button className={`!text-base text-white bg-[#1751D0] hover:bg-[#113B98] font-interSemiBold rounded-lg  px-3.5 py-2.5 flex-1`} type='submit'>
                                Update
                            </button>
                        </div>
                    </FooterModal>
                </form>
            </Modal >
        </>
    );
};

export default ConfigureScoring;
