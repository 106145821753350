import mainLogo from "./login_logo.svg";
import closeIcon from "./close-icon.svg";
import closeIconRed from "./close-icon-red.svg";
import logOutIcon from './logout-icon.svg';
import profileImgNew from "./profile-img-new.svg";
import settingsIcon from './settings.png';
import defaultTeamProfileImage from './team-profile-default-icon.png';
import arrowRightGrey from "./arrow-grey.svg";
import arrowRightBlue from "./arrow-blue.svg";
import circleBlack from "./plus-circle-black-icon.svg";
import searchIcon from "./search-small-icon.svg";
import threeVerticleDotsIcon from './three-dots-icon.svg';
import starGreyIcon2 from './star-grey2-icon.svg';
import trashIcon from './trash-01.svg';
import downloadIcon from './download-01.svg';
import editIcon from './edit-03.svg';
import printIcon from './print.svg';
import image1 from './Image1.png';
import image2 from './Image2.png';
import image3 from './Image3.png';
import structure from './Structure.png';
import dropdownArrow from './down-icon4.svg';
import goBack from './left-icon.svg';
import filter from './filter.svg';
import configure from './audio-settings-01.svg';
import configureWhite from './audio-settings-white.svg';
import confidence from './percent-03.svg';
import graphical from './Lead icon container.svg';
import right from './right-icon.svg';
import blueStar from './stars-01.svg';
import citeIcon from './cite.svg';
import vijilizeIcon from './vijilize.svg';
import homeIcon2 from './home-04.svg';
import boxIcon from './box.png';
import boxIconGrey from './box-icon-grey.svg';
import folderIcon from './folder-open-03.svg';
import arrowUp3 from './arrow-up-icon-3.svg';
import rightCurvedIcon from './arrow-curve-right-up.svg';
import eyeIcon from './eye-open.png';
import sliderImg from './Revenue-bro.svg';
import logo from './login_logo.svg';
import plusCircleBlueIcon from './plus-circle-blue-icon.svg';
import plusIcon from './plus-02.svg';
import folderPlusIcon from './folder-plus-01.svg';
import folderPlusWhiteIcon from './folder-plus-white.svg';

import rightSideIcon2 from './right-side-icon2.svg';
import rightSideIcon3 from './right-side-icon3.svg';
import mobileLogo from './login_logo.svg';
import miniLogo from './mini_logo.svg';
import greyHome from './home-04-filled.png';
import Image1 from './Image1.png';
import Image2 from './Image2.png';
import Image3 from './Image3.png';
import Structure from './Structure.png';
import Product1 from './product1.png';
import Product2 from './product2.png';
import Explanation1 from './exp1.png';
import Explanation2 from './exp2.png';

import confidenceWhite from './configure-white.svg';
import defaultProfile from './default-user.png';
import uploadFileIcon3 from './file-upload-icon-2.svg';
import breadCrumbHomeIcon from './breadcrumb_icon.svg';
import verticleDotsGrey from './vertical-dots-grey.svg';
import plusIconWhite from './plus-icon-white.svg';
import plusIcon3 from './plus-icon-3.svg';

import questionCircle from './help-circle-contained-filled.svg';
import uploadIcon from './upload-Icon.svg';
import filePlus2 from './file-plus-02.svg';
import inviteTeamProjectIcon from './invite-fete-icon.png';

import ChevronRight from './chevron-right.svg';
import ChevronDown from './angle-down-small.svg';
import FolderIcon from './folder.svg';
import FileIcon from './file.svg';
import backArrow from "./back-arrow.svg";
import backArrowBlue from "./back-arrow-blue.svg";
import EmptyStars from "./stars-empty.svg";

import pastFeteEmptyState from './past-fete-empty-state.svg';
import successToastIcon from './success-icon.svg';
import errorToastIcon from './error-icon.svg';
import warningToastIcon from './warning-icon.svg';
import infoToastIcon from './info-icon.svg';
import errorIcon2 from './error-icon2.svg';

import noProjects from './no-project-data.png';
import searchIcon2 from './search-lg.svg';
import searchIcon3 from './search-icon-border.svg';
import addPatentLogo from './add-patent-logo-bg.svg';
import starIntial from './stars-initial.svg';
import starFinal from './start-final.svg';
import loginSlider1 from './login-slider-1.svg';
import loginSlider2 from './login-slider-2.svg';
import loginSlider3 from './login-slider-3.svg';
import loginSlider4 from './login-slider-4.svg';
import teamPlaceholder from './team_placeholder.svg';
import serachIcon3 from './search-icon-4.svg';
import InfoIconBlue from './information-icon.svg';
import downloadIcon2 from './download-02.svg';
import dummyImg from './product-dummy-img.svg';
import EditIcon2 from './edit-icon-2.svg';
import deleteicon2 from './delete-icon-red.svg';
import analyzeIcon from './analyze-icon.svg';
import viewAnalysis from './view-analysis.svg';
import analyzingIcon from './analyzing-icon.svg';
import analyzingIcon1 from './analyze-icon1.svg';
import threedotsVerticles2 from './three-dots-verticles-2.svg';
import deleteIcon3 from './delete-icon-3.svg';
import editModal2 from './edit-icon-modal-2.svg';
import deletIconModal from './deleteModalicon.svg';
import pdfIcon from './pdf-icon.svg';
import greenCheckIcon from './green-check-icon.svg';
import plusIcon2 from './plus-icon2.svg';
import downIcon3 from './chevron-down2.svg';
import downIconWhite from './chevron-downWhite.svg';
import disabledVijiliseIcon from './disable-vijilise-icon.svg';
import enabledVijiliseIcon from './vijilise-enable-state.svg';
import enabledVijiliseIcon2 from './vijilise-enable-state2.svg';
import heatmapDummy from './heatmap-dummy.svg';
import logoWithIconAndText from './main_logo.png';
import saveClaimChartIcon from './save-claim-chart-icon.svg';
import arrowRightContained from './arrow-right-contained.png'
import heatmapNotFound from './heatmap_not_found.png'
import deviceHolderIcon from './device-holder-icon.svg';
import calenderIcon from './calendar-icon.svg';
import userProfileIcon from './user-profile-icon.svg';
import claimFigureNotFound from './claim-figure-not-found.png'


const allImgPaths = (() => ({
	InfoIconBlue,
	enabledVijiliseIcon2,
	analyzingIcon1,
	calenderIcon,
	userProfileIcon,
	deviceHolderIcon,
	saveClaimChartIcon,
	closeIconRed,
	heatmapDummy,
	disabledVijiliseIcon,
	enabledVijiliseIcon,
	downIconWhite,
	downIcon3,
	plusIcon2,
	greenCheckIcon,
	pdfIcon,
	deletIconModal,
	editModal2,
	deleteIcon3,
	threedotsVerticles2,
	analyzeIcon,
	viewAnalysis,
	analyzingIcon,
	deleteicon2,
	EditIcon2,
	dummyImg,
	configureWhite,
	downloadIcon2,
	errorIcon2,
	serachIcon3,
	backArrowBlue,
	teamPlaceholder,
	loginSlider1,
	loginSlider2,
	loginSlider3,
	loginSlider4,
	starIntial,
	starFinal,
	addPatentLogo,
	rightSideIcon3,
	searchIcon3,
	EmptyStars,
	backArrow,
	plusIcon3,
	verticleDotsGrey,
	plusIconWhite,
	mainLogo,
	breadCrumbHomeIcon,
	boxIconGrey,
	closeIcon,
	logOutIcon,
	profileImgNew,
	settingsIcon,
	defaultTeamProfileImage,
	arrowRightGrey,
	arrowRightBlue,
	circleBlack,
	searchIcon,
	threeVerticleDotsIcon,
	starGreyIcon2,
	trashIcon,
	editIcon,
	downloadIcon,
	printIcon,
	image1,
	image2,
	image3,
	structure,
	dropdownArrow,
	goBack,
	filter,
	configure,
	confidence,
	graphical,
	right,
	blueStar,
	citeIcon,
	vijilizeIcon,
	homeIcon2,
	boxIcon,
	folderIcon,
	arrowUp3,
	rightCurvedIcon,
	eyeIcon,
	sliderImg,
	logo,
	plusCircleBlueIcon,
	rightSideIcon2,
	mobileLogo,
	miniLogo,
	plusIcon,
	folderPlusIcon,
	folderPlusWhiteIcon,
	greyHome,
	Image1,
	Image2,
	Image3,
	Structure,
	Product1,
	Product2,
	Explanation1,
	Explanation2,
	confidenceWhite,
	defaultProfile,
	uploadFileIcon3,
	questionCircle,
	uploadIcon,
	filePlus2,
	inviteTeamProjectIcon,
	ChevronRight,
	ChevronDown,
	FolderIcon,
	FileIcon,
	pastFeteEmptyState,
	successToastIcon,
	errorToastIcon,
	warningToastIcon,
	infoToastIcon,
	noProjects,
	searchIcon2,
	logoWithIconAndText,
	arrowRightContained,
	heatmapNotFound,
	claimFigureNotFound

}))();
export default allImgPaths;
