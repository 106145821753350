import { useRef } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import { InputTextField } from '../../components/InputField';
// import Footer from "../../components/Footer";
import useAppState from '../../context/useAppState';
import { PasswordRulesComponent } from '../../components/Auth';
import { sliderSetting } from '../../utils/constant';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import allImgPaths from 'assets/images';

function ResetPassword() {
    const { formDetail, msg, error, passwordRule, handlePassEyeBtn, showContinueScreen, handleConfirmPassEyeBtn, showPasswordRule, setShowPasswordRule, handleShowPassWordRule, handleFormSubmit, showEyeBtn, handleInputChange } =
        useAppState('resetPassword');
    const navigate = useNavigate();

    var settings = {
        dots: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
    };
    const sliderRef = useRef<any>(null);

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };
    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    function ResetPasswordSuccessScreen() {
        return (
            <div>
                <div className='text-center pb-8 customHeight920:pb-8 customHeight920:pt-32 pt-0'>
                    <img className='inline-block' src={allImgPaths.mainLogo} alt='logo' />
                </div>

                {/* Content */}
                <h1 className='font-interSemiBold text-2xl text-[#101828] pb-3.5 text-center'>Reset complete!</h1>
                <p className='text-[#475467] pb-8 text-center'>All done! You can signin with your new credentials from the Sign In page</p>

                <button className='submit-btn-blue w-full mt-2 mb-4 blue-btn-hover' onClick={() => navigate('/login')}>
                Sign In
                </button>
            </div>
        );
    }

    const isFormValid = () => {
        return !!formDetail.password && !!formDetail.confirmPassword;
    };

    return (
        <div className='flex'>
            {/* Login Left */}
            <div className='w-1/2'>
                <div className='bg-[#F9FAFB] signup-slider-wrapper'>
                    <Slider {...sliderSetting} ref={sliderRef} {...sliderSetting} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider1} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Manage Large Portfolios
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Maintain a portfolio of patents to identify and assess and <br /> rank assets with the highest value
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider2} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Analyze Patent Vulnerabilites
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Uncover critical details to reveal strengths and <br /> vulnerabilities in a patent.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider3} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Auto-Generate Claim Charts
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Auto generate claim charts comparing claim elements with <br /> product features.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider4} alt='slider img' />
                            <div className='text-center pb-12'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Monetize on under-utilized Patents
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Find potential licenses and geneate revenue from unused <br /> assets.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            {/* Reset Password Form */}
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto customHeight800:pt-8 customHeight700:pt-14'>
                    <div className='w-full max-w-[360px] mx-auto'>
                        {showContinueScreen ? (
                            <ResetPasswordSuccessScreen />
                        ) : (
                            <>
                                {/* Logo */}
                                <div className='text-center pb-8 pt-0'>
                                    <img className='inline-block' src={allImgPaths.mainLogo} alt='logo' />
                                </div>

                                {/* Content */}
                                <h1 className='font-interSemiBold text-2xl text-[#101828] pb-2.5 text-center'>Choose  new password</h1>
                                <p className='text-[#475467] pb-8 text-center'>Almost done. Enter your new password and you’re all set.</p>

                                {/* Form */}
                                <form onSubmit={handleFormSubmit}>
                                    <div className='mb-5 custom-padding-right relative'>
                                        <InputTextField
                                            label={'Password'}
                                            name={'password'}
                                            placeholder={'Enter your password'}
                                            onChange={handleInputChange}
                                            onBlur={() => setShowPasswordRule(false)}
                                            value={formDetail.password}
                                            type={showEyeBtn.password ? 'text' : 'password'}
                                            err={error.password}
                                            handleShowEyeBtn={handlePassEyeBtn}
                                            iButton={true}
                                            showEyeBtn={showEyeBtn.password}
                                            onClick={handleShowPassWordRule}
                                        />
                                        <PasswordRulesComponent passwordRule={passwordRule} showPasswordRule={showPasswordRule} setShowPasswordRule={setShowPasswordRule} />
                                    </div>

                                    <div className='mb-5'>
                                        <InputTextField
                                            label={'Confirm Password'}
                                            name={'confirmPassword'}
                                            placeholder={'Re-enter Password'}
                                            onChange={handleInputChange}
                                            value={formDetail.confirmPassword}
                                            type={showEyeBtn.confirmPass ? 'text' : 'password'}
                                            err={error.confirmPassword}
                                            handleShowEyeBtn={handleConfirmPassEyeBtn}
                                            iButton={true}
                                            showEyeBtn={showEyeBtn.confirmPass}
                                        />
                                        <p className='text-red-600 text-base text-center pt-3'>{msg.err}</p>
                                    </div>

                                    {/* Didn't get code */}
                                    <button type='submit' className={`submit-btn-blue w-full mt-2 mb-4 blue-btn-hover ${!isFormValid() ? 'button-disabled' : ''}`}>
                                        Change Password
                                    </button>
                                </form>

                                <p className='text-sm text-[#1751D0] font-interSemiBold flex gap-x-3 justify-center mt-7 cursor-pointer hover:text-[#113B98] custom-group-hover' onClick={() => navigate('/login')}>
                                    <img className='group-hover-child' src={allImgPaths.backArrowBlue} alt='arrow' />
                                    Go Back
                                </p>
                            </>
                        )}
                    </div>
                </div>

                {/* <Footer /> */}
                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
