import { useRef,useState } from 'react';
import { Link } from 'react-router-dom';

import Otp from '../../components/Otp';
import useAppState from '../../context/useAppState';
// import Footer from './../../components/Footer';

import allImgPaths from 'assets/images';
import Slider from 'react-slick';
import { sliderSetting } from '../../utils/constant';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Toast from "components/common/Toast";

function VerifyUserScreen() {
    const { handleFormSubmit,handleOtpChange,resendOtp,msg,userEmail } = useAppState('verify');

    const sliderRef = useRef<any>(null);
    const [otpValue,setOtpValue] = useState('');

    const next = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext?.();
        }
    };
    const previous = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev?.();
        }
    };

    function customPaging(i: any) {
        return <span className='dot'>{i + 1}</span>;
    }

    function appendDots(dots: any) {
        return (
            <div>
                <li className='slick-arrow-left'>
                    <span onClick={previous}></span>
                </li>
                <ul className='slick-custom-dots'>{dots}</ul>
                <li className='slick-arrow-right'>
                    <span onClick={next}></span>
                </li>
            </div>
        );
    }

    const handleOtp = (e: any) => {
        setOtpValue(e);
        handleOtpChange(e);
    };

    const handleResend = (e: any) => {
        resendOtp();
        if (msg.success) {
            Toast.success('Success',msg.success);
        }

    }
    return (
        <div className='flex'>
            {/* Account Type Left */}
            <div className='w-1/2'>
                <div className='bg-[#F9FAFB] signup-slider-wrapper'>
                    <Slider {...sliderSetting} ref={sliderRef} {...sliderSetting} customPaging={customPaging} appendDots={appendDots}>
                        <div className=''>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider1} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Manage Large Portfolios
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Maintain a portfolio of patents to identify and assess and <br /> rank assets with the highest value
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider2} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Analyze Patent Vulnerabilites
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Uncover critical details to reveal strengths and <br /> vulnerabilities in a patent.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider3} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Auto-Generate Claim Charts
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Auto generate claim charts comparing claim elements with <br /> product features.
                                </p>
                            </div>
                        </div>

                        <div>
                            <img className='inline-block mx-auto customHeight920:mb-8 mt-8 customHeight920:w-96 mb-6' src={allImgPaths.loginSlider4} alt='slider img' />
                            <div className='text-center pb-8'>
                                <h3 className='font-interSemiBold text-2xl text-[#455468] pb-2'>
                                    Monetize on under-utilized Patents
                                </h3>
                                <p className='text-base font-interMedium text-[#455468] w-full max-w-[500px] mx-auto'>
                                    Find potential licenses and geneate revenue from unused <br /> assets.
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

            {/* Account type right */}
            <div className='w-1/2 flex flex-col'>
                {/* Upper Content */}
                <div className='w-full h-[calc(100vh_-_69px)] flex items-center overflow-y-auto customHeight800:pt-8 customHeight700:pt-14'>
                    <div className='w-full max-w-[524px] mx-auto'>
                        {/* Logo */}
                        <div className='text-center pb-12 pt-0'>
                            <img className='inline-block' src={allImgPaths.mainLogo} alt='logo' />
                        </div>

                        {/* Content */}
                        <div className='w-full px-3'>
                            <h1 className='font-interSemiBold text-2xl text-[#101828] pb-1 text-center'>Check your email</h1>

                            <form className='w-full px-6 py-6' onSubmit={handleFormSubmit}>
                                <p className='pb-8 text-center'>
                                    <span className='text-sm text-[#475467] inline-block pb-4'>
                                        We've sent a 6-character code to <b className='text-sm text-[#475467]'>{userEmail}</b>
                                        {/* <span className='text-[#4B3BF7] hover:text-[#0C0462] cursor-pointer text-sm font-interSemiBold'> Change</span> */}
                                    </span>

                                    <p className='text-sm text-[#475467]'>
                                        The code expires shortly, so please <br /> enter it soon.
                                    </p>
                                    {/* {t('verify.codesenttxt')} {userEmail} */}
                                </p>

                                {/* OTP */}
                                <span className='font-interMedium text-[#344054] text-sm inline-block pb-1.5'>Verification code</span>
                                <div className='flex gap-x-3 justify-center'>
                                    <Otp onChangeOtp={handleOtp} borderColor={msg.error && '#D92D20'} />
                                </div>

                                {/* Didn't get code */}
                                <p className='mt-2 whitespace-nowrap'>
                                    <span className='text-sm text-[#667085]'>{msg.error ? <p className='text-[#D92D20] text-sm'>{msg.error}</p> : <p className='text-sm text-[#667085]'>Can't find your code? Check your spam folder!</p>} </span>
                                </p>
                                <div className='justify-center gap-x-3 flex mt-8'>
                                    <button className={`submit-btn-blue blue-btn-hover w-full max-w-[360px] !px-9 ${!otpValue || otpValue.length < 6 ? 'button-disabled' : ''} `} type='submit'>
                                        Verify email
                                    </button>
                                </div>

                                <p className='text-center mt-8'>
                                    <span className='text-[#475467] text-sm'>Didn’t receive the email?</span>
                                    <button type="button"
                                        className="text-sm font-interSemiBold text-[#1E0AF5] hover:text-[#0C0462]"
                                        onClick={handleResend}
                                    >
                                        Click to resend
                                    </button>

                                </p>
                            </form>
                        </div>
                        {msg.success && <p className='text-green-600 text-center text-xl'>{msg.success}</p>}
                    </div>
                </div>

                {/* Footer branding */}
                <div className='flex items-center justify-between px-10 py-[22px] bg-white'>
                    <span className='text-[#475467] text-sm'>© ipvijil 2024</span>
                    <div className='flex items-center gap-x-2'>
                        <Link className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]' to='/privacy-policy'>
                            {' '}
                            Privacy & Terms
                        </Link>
                        <span>|</span>
                        <Link to='/contact-us' className='text-[#475467] text-sm flex items-center gap-x-1 hover:text-[#1e0af5]'>
                            {' '}
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerifyUserScreen;
