import ClaimGraphics from "components/ClaimGraphics";
import React from "react";

const ClaimChartDownload = ({ newData }: any) => {
  return (
    <div id="claim-chart-download" className="flex flex-wrap gap-4 items-start">
      {newData.patents.map((patent: any) =>
        Object.entries(patent.claims).map(([claimKey, claim]: any) => (
          <div
            key={`${patent.patentId}-${claimKey}`}
            className="claim flex w-full gap-x-4"
          > 
            <div className="flex w-1/3 border border-[#CFD6DD]  rounded-lg flex-col mb-3 pl-4 pr-3.5">

              <div className="overflow-y-scroll hide-scrollbar mt-4  pr-3 pl-4">
              <h3 className="text-lg font-interSemiBold text-[#000000] mb-2">
                {patent.patentNumber}
              </h3>
              <span className="text-lg font-interSemiBold text-[#000000]">
                Claim {claimKey.replace("claim", "")}
              </span>
              <div className="border border-[#F0F0F0] mt-2 px-5 py-5 bg-[#FDFDFD]">
                <div className="px-5 py-4">
                  {claim.elements &&
                    claim.elements.map((element: any, elemIndex: any) => (
                      <span key={elemIndex} style={{ color: element.hex_code }}>
                        {element.description}
                      </span>
                    ))}
                </div>
              </div>
            </div>
            </div>
          
            <div className="border w-1/3 border-[#CFD6DD] rounded-lg py-4">
              <ul className="flex items-center justify-center pb-4">
                <li
                  className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] text-[#4F4D55] rounded-lg flex items-center gap-x-2 cursor-pointer bg-[#fff]`}
                >
                  <span className="text-sm font-interMedium text-[#4F4D55]">
                    Claim Graphics
                  </span>
                </li>
              </ul>
              <div className="">
                <div className="h-[calc(100vh_-_286px)] overflow-y-auto hide-scrollbar3">
                  <ClaimGraphics claim={claim} />
                </div>
              </div>
            </div>

            <div className="w-1/3 px-4 py-4 border border-[#CFD6DD]  rounded-lg">
              <p className="text-base font-interSemiBold text-[#0A090B] pb-1">
                Covered Patents
              </p>
              {patent.summarized_similarity_results &&
              patent.summarized_similarity_results[claimKey] ? (
                patent.summarized_similarity_results[claimKey].map(
                  (item: any, index: any) => (
                    <div
                      key={item.targetDocumentId + index}
                      className="mt-2 px-3 mb-4 last:mb-0"
                    >
                      <div className="border border-[#EAEDF0] rounded-sm">
                        <div className="px-3 py-3 relative">
                          <div className="relative flex items-center gap-x-2 mb-3">
                            <p className="text-sm text-[#71717A]">
                              {item.targetDocumentId}
                            </p>
                          </div>
                          {item.matched_items &&
                            item.matched_items.length > 0 && (
                              <>
                                <p className="text-sm font-interBold text-[#71717A] mb-3">
                                  Matched Items :{" "}
                                </p>
                                {item.matched_items.map(
                                  (matchedItem: any, index: any) => (
                                    <p
                                      key={matchedItem + index}
                                      className="text-sm text-[#71717A] mb-3"
                                    >
                                      {matchedItem}
                                    </p>
                                  )
                                )}
                              </>
                            )}
                          {item.non_matched_items &&
                            item.non_matched_items.length > 0 && (
                              <>
                                <p className="text-sm font-interBold text-[#71717A] mb-3 mt-3">
                                  Unmatched Items :{" "}
                                </p>
                                {item.non_matched_items.map(
                                  (unmatchedItem: any, index: any) => (
                                    <p
                                      key={unmatchedItem + index}
                                      className="text-sm text-[#71717A] mb-3"
                                    >
                                      {unmatchedItem}
                                    </p>
                                  )
                                )}
                              </>
                            )}
                          <div className="bg-[#FAFAFA] pb-3 pt-1 px-2 mb-4">
                            <span className="text-xs text-[#7E8C9A] font-interMedium inline-block pb-3">
                              Overlap score
                            </span>
                            <div className="flex items-center gap-x-3 justify-between">
                              <div className="bg-[#E4E4E7] h-2 w-[97%] relative">
                                <div
                                  className="bg-[#005BC4] h-full absolute top-0 left-0"
                                  style={{ width: `${Math.round(item.score)}%` }}
                                ></div>
                              </div>
                              <span className="font-interSemiBold text-black text-sm">
                                {Math.round(item.score)}%
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <p className="flex justify-center items-center h-full mx-auto text-sm text-[#71717A] mb-3">
                  No Covered Patents Found
                </p>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ClaimChartDownload;
