/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Sidebar from 'components/common/Sidebar';
import Header from 'components/common/Header';
import allImgPaths from 'assets/images';


import { usePagination } from 'hooks/paginate/usePagination';
import ClaimTable from './ClaimTable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AddSource from "components/common/modals/AddSource";
import AddComparisonData from "components/common/modals/AddComparisonData";
import SearchBar from 'components/common/SearchBar';

import { useLocation } from 'react-router-dom';
import { deletePatentById, getClaimChartByProjectId, getProjectDetails, getVijilization, startVijilize } from "services/project";
import Toast from "components/common/Toast";
import CustomCheckBox from "components/CustomCheckBox";
import ProjectDetailShimmerLoader from "pages/loaders/ProjectDetailLoader";
import VijiliseModal from 'components/common/modals/VijiliseModal';
import Checkbox from 'components/CheckboxWithList';
import useAppState from 'context/useAppState';
import useOnClickOutside from "hooks/common/useOnClickOutside";
import AddFileWhite from "components/common/AddFileWhite";
import AddFileBlue from "components/common/AddFileBlue";
import DeleteModal from "components/common/modals/DeleteModal";
import EditProject from "components/common/modals/EditProject";
import VijilizeShimmerLoader from "pages/loaders/VijilizeShimmerLoader";
import { formatDate } from 'utils/function';



function FindCoveredProducts() {
    var toggle = 1;
    var comparisonCount = 0;
    var claimCount = 0;

    const location = useLocation();
    const { id: projectId } = useParams();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [arrowUp, setArrowUp] = useState(false);
    const [columnCount, setColumnCount] = useState({ patents: 0, files: 0, urls: 0, publications: 0 })
    const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
    const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
    const columns = [
        { name: 'Patents', count: columnCount.patents },
        { name: 'Files', count: columnCount.files },
        { name: 'URLs', count: columnCount.urls },
        // { name: 'Publications',count: columnCount.publications },
    ];

    // Patent table

    const [sourcePatents, setSourcePatents] = useState<any[]>([]);
    const [comparePatents, setComparePatents] = useState<any[]>([]);

    const [vijilizedData, setVijilizedData] = useState<any[]>([]);
    const [claimChartData, setClaimChartData] = useState<any[]>([]);

    const [isLoader, setIsLoader] = useState<boolean>(true);
    const [compareFiles, setCompareFiles] = useState<any[]>([]);
    const [compareURLs, setCompareURLs] = useState<any[]>([]);
    const [srcRefData, setSrcRefData] = useState<any>({});
    // const [comparePublications,setComparePublications] = useState<any[]>([]);
    const [refreshData, setRefreshData] = useState(false);
    const { setVijilizeParamsData, isActiveSection, setIsActiveSection, setHeaderDataLocal } = useAppState('vijilize');
    const [isShowSideBar, setIsShowSideBar] = useState(false);
    const [toggledDataUsed, setToggledDataUsed] = useState<any>(0);
    // Patent table
    const patentData: any[] = [

    ];
    let patentColumns: any[] = [
        {
            name: 'PatentId',
            ref: 'file_name',
            hide: false,
            position: 1,
        },
    ];

    // claim charts

    const [isActiveColumn, setIsActiveColumn] = useState<number>(0);
    const [showMenu, setSowMenu] = useState(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [selectedCompareFiles, setSelectedCompareFiles] = useState<any[]>([]);
    const [selectedComparePatent, setSelectedComparePatent] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [dataLength, setDataLength] = useState(1);
    const [projectDetail, setProjectDetail] = useState<any>()
    // set state for selected source
    const [selectedSource, setSelectedSource] = useState<any[]>([]);

    const [vijilizeModalStatus, setVijilizeModalStatus] = useState<boolean>(false);
    const [vijilizationsCount, setVijilizationsCount] = useState(0);
    const [isShowDropdown, setisShowDropdown] = useState<any>(0);
    const [isShowAddDropdown, setisShowAddDropdown] = useState<any>(false);
    const [isShowAddBlueDropdown, setisShowAddBlueDropdown] = useState<any>(false);

    const [vijilizeLoader, setVijilizeLoader] = useState<boolean>(false);
    const [isClaimTableLoading, setIsClaimTableLoading] = useState<boolean>(false);
    const [tabCount, setTabCount] = useState({
        comparisonLib: 0,
        vijilizations: 0,
        claimCharts: 0
    });
    const [selectAllSource, setSelectAllSource] = useState(false);
    const [selectAllCompare, setSelectAllCompare] = useState(false);

    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => setisShowDropdown(false));

    const addDropdownSrcRef = useRef(null);
    const addDropdownCmpRef = useRef(null);
    // useOnClickOutside(addDropdownRef,() => setisShowAddDropdown(false));

    const addDropdownBlueRef = useRef(null);
    useOnClickOutside(addDropdownBlueRef, () => setisShowAddBlueDropdown(false));


    const navigate = useNavigate();

    // const [sourceSearch, setSourceSearch] = useState();
    // const [patentsSearch, setPatentsSearch] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [openComparisonModal, setOpenComparisonModal] = useState(false);

    const [paginatedData, setPaginatedData] = useState<any[]>([]);
    const [toast, setToast] = useState({
        show: false,
        heading: '',
        message: '',
    });

    const totalItems = patentData.length;
    const itemsPerPage = 5;

    // const { currentPage,totalPages,startItem,endItem,nextPage,prevPage,currentData } = usePagination({
    //     totalItems: dataLength,
    //     itemsPerPage,
    //     initialPage: 1,
    // });

    useEffect(() => {
        (async () => {
            try {
                setIsLoader(true);
                setDataLength(patentData.length)
                setSelectedItems([]);
                setSelectedSource([]);
                setSelectedCompareFiles([]);
                setSelectedComparePatent([]);
                setSelectAll(false);
                setSelectAllCompare(false);
                setSelectAllSource(false);
                const detailResponse: any = await getProjectDetails(projectId || '0');

                // setVijilizationsCount(detailResponse?.vijilizations);
                setTabCount((prevData) => { return { ...prevData, vijilizations: detailResponse?.vijilizations } })
                setTabCount((prevData) => { return { ...prevData, claimCharts: detailResponse?.claim_charts } })

                // header 
                setProjectDetail({ title: detailResponse.name || location.state.title, description: detailResponse.description || location.state.description, id: detailResponse._id || projectId});
                setHeaderDataLocal((prev: any) => {
                    return {
                      projectDetail: {
                        title: detailResponse.name || location.state.title,
                        description:
                          detailResponse.description ||
                          location.state.description,
                        id: detailResponse._id || projectId,
                      },
                      claimAnalysis: { title: "", id: "" },
                      vijilize: { title: "", id: "" },
                      claimChart: { title: "", id: "" }
                    };
                  });
                // source data
                setSourcePatents([...detailResponse.source.external, ...detailResponse.source.patent]);
                setComparePatents([...detailResponse.compare.external]);
                // setCompareFiles([...detailResponse.compare.external]);
                setColumnCount({ ...columnCount, patents: [...detailResponse.compare.external]?.length, files: []?.length });
                const compareTot = Object.keys(detailResponse.compare).reduce((acc: any, key: any) => {
                    acc[key] = detailResponse.compare[key].length;
                    return acc;
                }, {});
                const grandTotal: any = Object.values(compareTot).reduce((sum: any, count: any) => sum + count, 0);


                setTabCount((prevData) => { return { ...prevData, comparisonLib: grandTotal } });
                setIsLoader(false);
                callVijilizationList();
                callClaimChartList();
            } catch (error) {
                setProjectDetail({ title: location.state.title, description: location.state.description, id: projectId });
                setHeaderDataLocal((prev: any) => {
                  return {
                    projectDetail: {
                      title: location.state.title,
                      description: location.state.description,
                      id: projectId,
                    },
                    claimAnalysis: { title: "", id: "" },
                    vijilize: { title: "", id: "" },
                    claimChart: { title: "", id: "" }
                  };
                });
                throw error;
            }
        })();
        // 
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoader(true);
                setDataLength(patentData.length)
                setSelectedItems([]);
                setSelectedSource([]);
                setSelectedCompareFiles([]);
                setSelectedComparePatent([]);
                setSelectAll(false);
                setSelectAllCompare(false);
                setSelectAllSource(false);
                const detailResponse: any = await getProjectDetails(projectId || '0');
                const compareTot = Object.keys(detailResponse.compare).reduce((acc: any, key: any) => {
                    acc[key] = detailResponse.compare[key].length;
                    return acc;
                }, {});
                const grandTotal: any = Object.values(compareTot).reduce((sum: any, count: any) => sum + count, 0);
                // setVijilizationsCount(detailResponse?.vijilizations);
                setTabCount((prevData) => { return { ...prevData, vijilizations: detailResponse?.vijilizations, comparisonLib: grandTotal } })

                // header 
                setProjectDetail({ title: detailResponse.name || location.state.title, description: detailResponse.description || location.state.description, id: detailResponse._id || projectId});
                setHeaderDataLocal((prev: any) => {
                  return {
                    projectDetail: {
                      title: detailResponse.name || location.state.title,
                      description:
                        detailResponse.description ||
                        location.state.description,
                      id: detailResponse._id || projectId,
                    },
                    claimAnalysis: { title: "", id: "" },
                    vijilize: { title: "", id: "" },
                    claimChart: { title: "", id: "" }
                  };
                });
                
                // source data
                setSourcePatents([...detailResponse.source.external, ...detailResponse.source.patent]);
                setComparePatents([...detailResponse.compare.external]);
                // setCompareFiles([...detailResponse.compare.external]);
                setColumnCount({ ...columnCount, patents: [...detailResponse.compare.external]?.length, files: []?.length })
                setIsLoader(false);
                callVijilizationList();
                callClaimChartList();
            } catch (error) {
                setProjectDetail({ title: location.state.title, description: location.state.description, id: projectId });
                setHeaderDataLocal((prev: any) => {
                    return {
                      projectDetail: {
                        title: location.state.title,
                        description: location.state.description,
                        id: projectId,
                      },
                      claimAnalysis: { title: "", id: "" },
                      vijilize: { title: "", id: "" },
                      claimChart: { title: "", id: "" }
                    };
                  });
                throw error;
            }


        })();


    }, [refreshData])

    const callVijilizationList = () => {
        setVijilizeLoader(true);
        (async () => {
            try {
                const data: any = await getVijilization(projectId || '0');
                setVijilizedData([...data.data]);
                setVijilizeLoader(false);
            } catch (error) {
                throw error;
            }


        })();


    }
    const callClaimChartList = () => {
        setIsClaimTableLoading(true);
        (async () => {
            try {
                const data: any = await getClaimChartByProjectId(projectId || '0');
                setClaimChartData([...data.data]);
                setIsClaimTableLoading(false);
            } catch (error) {
                throw error;
            }


        })();


    }


    const handleSelectItem = useCallback(
        (index: number) => {

            setSelectedItems((prev) => {
                const newSelected = prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index];

                // Update selectAll state based on if all items in current page are selected
                setSelectAll(newSelected.length === paginatedData.length);

                return newSelected;
            });

        },
        [paginatedData]
    );

    // Select All Handler for Source
const handleSelectAllSource = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectAllSource(e.target.checked);
        if (e.target.checked) {
            const allSourceIds = sourcePatents.map(source => source.patent_id);
            setSelectedSource(allSourceIds);
        } else {
            setSelectedSource([]);
        }
    },
    [sourcePatents]
);

// Individual Source Select Handler
const handleSourceSelect = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const isChecked = event.target.checked;
    setSelectedSource(prev => {
        const updatedSelection = isChecked
            ? [...prev, data.patent_id] 
            : prev.filter(item => item !== data.patent_id); 

            setSelectAllSource(updatedSelection.length === sourcePatents.length);

        return updatedSelection;
    });
};

// Select All Handler for Compare
const handleSelectAllCompare = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectAllCompare(e.target.checked);
        if (e.target.checked) {
            const allComparisonIds = comparePatents.map(compare => compare.patent_id);
            setSelectedComparePatent(allComparisonIds);
        } else {
            setSelectedComparePatent([]);
        }
    },
    [comparePatents]
);

// Individual Compare Select Handler
const handleSelectedCompare = (event: React.ChangeEvent<HTMLInputElement>, data: any) => {
    const isChecked = event.target.checked;
    setSelectedComparePatent(prev => {
        const updatedSelection = isChecked
            ? [...prev, data.patent_id] 
            : prev.filter(item => item !== data.patent_id); 

        setSelectAllCompare(updatedSelection.length === comparePatents.length);

        return updatedSelection;
    });
};

    

    const selectColumn = async (index: number) => {
        setIsActiveColumn(index);
        // TODO: api calls need to bind
        // if (index == 0) {
        // } else {
        //     console.log('Others');
        // }
    };

    // const handleSearchValue = (event: any) => {
    //     getFormValue(event.target.value);
    //     console.log(formValue);
    // };

    // const handleFocus = () => {
    //     setIsFocused(true);
    //     console.log('test');
    // };

    // const handleBlur = () => {
    //     setIsFocused(false);
    // };

    const handleMenuClick = () => {
        setSowMenu(!showMenu);
    };
    const createColumnClassName = (field: string) => {
        if (field == 'file_name') {
            return 'table-col w-full flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        }
        // if (field == 'patentTitle') {
        //     return 'table-col w-[50%]  flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        // }
        // if (field == 'publication_date') {
        //     return 'table-col w-[20%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        // }
        // if (field == 'action') {
        //     return 'table-col w-[10%] flex-shrink-0 bg-[#fff] border border-[#E6E6E6] border-r-0 border-y py-3 px-6 border-t-0';
        // }
    };


    const handleSort = (type: string) => {
        // const enabledRows = taskViewRows.filter((row: any) => !row.isCardDisabled);
        // const disabledRows = taskViewRows.filter((row: any) => row.isCardDisabled);
        const sortedEnabledRows = [...paginatedData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[type].localeCompare(b[type]);
            } else {
                return b[type].localeCompare(a[type]);
            }
        });
        const finalSortedData = sortedEnabledRows;
        setPaginatedData(finalSortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setArrowUp(!arrowUp);
    };

    const handlePatentClick = (patentObj: any) => {
        navigate(`/project/${projectId}/patent/${patentObj.patent_id}/claim-analysis`);
    }
    const transformPatentDataArray = (
        inputDataArray: any[],
        additionalData: any
    ): any[] => {
        try {
            // Map through the array of input data and transform each object
            const transformedArray = inputDataArray.map(inputData => {
                // Extract the necessary parts from each input data object
                const { patent_id, file_id, s3_file_path, key } = inputData;
                const { team_id, project_id } = additionalData;

                // Construct the transformed object
                return {
                    team_id,
                    project_id,
                    file_id,
                    patent_id,
                    key,
                    s3_file_path
                };
            });

            return transformedArray;
        } catch (error) {
            console.error('Error transforming patent data array:', error);
            throw new Error('Failed to transform patent data array');
        }
    };

    const startVijilizeProcess = async () => {
        let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);

        let filteredSourcePatents = sourcePatents.filter((item: any) => selectedSource.includes(item.patent_id));
        let filteredComparePatents = comparePatents.filter((item: any) => selectedComparePatent.includes(item.patent_id));


        let sourcePayload = [...filteredSourcePatents];
        let comparePayload = [...filteredComparePatents];
        // let compareFilePayload = [...selectedCompareFiles];

        let sourcevijilizeArr: any[];
        let compareVijilizeObj: any;
        let payload: any;


        let team_id: string = selectedTeam.currentTeamId;
        const project_id = projectId;
        if (comparePayload?.length) {
            setVijilizeModalStatus(true);
        } else {
            sourcevijilizeArr = transformPatentDataArray([...sourcePayload], { team_id, project_id });
            payload = {
                type: 'analyzer',  // if both source and compare then 'comparer', if compare is empty 'analyzer'
                data: sourcevijilizeArr
            }
            console.log("🚀 ~ startVijilizeProcess ~ payload:", payload)
            try {
                let response = await startVijilize(payload);
                Toast.success('Success', 'Process started');
                setRefreshData(prev => !prev);

            } catch (error) {
                throw error;
            }

        }
    }


    const checkBoxdata = [
        { title: 'US4211445', value: 'US4211445', date: '1980-07-08', content: 'Soap bar holder', selected: false },
        { title: 'US5396399', value: 'US4211445', date: '1995-03-07', content: 'Multi-purpose handle/prop for pen-based computer having overhanging front surface', selected: false },
        { title: 'US4211445', value: 'US6016248A', date: '1998-02-18', content: 'A collapsible sound conduit for mobile ', selected: false },
    ];

    const setVijilizeData = (data: any) => {
        const patent_ids = data?.source.map((item: any) => item.patent_id);
        const compare_id = data?._id;
        setVijilizeParamsData({ patent_ids, compare_id })
    }

    const startIndivijualAnalyzation = async (data: any) => {
        let selectedTeam: any = JSON.parse(localStorage.getItem('selectedTeam') as string);
        let team_id: string = selectedTeam.currentTeamId;
        const project_id = projectId;
        const payload = {
            type: 'analyzer',  // if both source and compare then 'comparer', if compare is empty 'analyzer'
            data: transformPatentDataArray([{ ...data }], { team_id, project_id })
        }
        try {
            let response = await startVijilize(payload);
            Toast.success('Success', 'Process started');
            setRefreshData(prev => !prev);

        } catch (error) {
            throw error;
        }

    }

    const redirectToHome = () => {
        navigate('/');
    }

    const deletePatent = async (id: string) => {

        let res: any = await deletePatentById(id);
        Toast.success('Success', res.message);

        setRefreshData(prev => !prev);
    }



    return (
        <div className='dashboard-wrapper flex'>
            {/* Sidebar */}
            <Sidebar />

            {/* dashboard main */}
            <div className='w-[calc(100%_-_72px)] ml-auto'>
                {/* Hedaer */}
                <Header title='Project Details' count={0} isChildEle={true} />
                <div>
                    {/* Page Header Part */}

                    <div className='flex justify-between py-4 px-4'>
                        <div className='flex items-start gap-x-2 pl-1'>
                            <img className='inline-block cursor-pointer on-hover-bg-grey w-5 h-5 p-1 rounded relative top-1' src={allImgPaths.goBack} alt='return' onClick={() => {navigate(-1); setIsActiveSection(0); }} />
                            <div className=''>
                                <h2 className='text-[#0A090B] text-xl font-interSemiBold'>{projectDetail?.title}</h2>
                                <p title={projectDetail?.description} className='text-sm text-[#7F7D83] font-interRegular truncate max-w-[calc(100vw_-_1100px)]'>{projectDetail?.description}</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-x-4 h-12'>
                            {isActiveSection == 0 && (
                                // TODO: add condition of compare patent afterwards
                                <button
                                    onClick={() => startVijilizeProcess()}
                                    disabled={!(selectedSource.length > 0 && selectedComparePatent.length > 0)}
                                    className={`!text-sm text-white outline-none flex items-center gap-1.5 font-interMedium rounded-lg  ${!(selectedSource.length > 0 && selectedComparePatent.length > 0)
                                        ? 'button-disabled bg-[#1751D0] px-3.5 py-2.5'
                                        : ''
                                        }`}
                                >
                                    
                                    {!(selectedSource.length > 0 && selectedComparePatent.length > 0) ? <><img className='outline-none' src={allImgPaths.disabledVijiliseIcon} alt="" /> {'Vijilize'}</> : <img className='cursor-pointer outline-none' onClick={() => startVijilizeProcess()} src={allImgPaths.enabledVijiliseIcon2} alt="" /> }
                                </button>
                            )}

                            {/* {selectedSource.length !== 0 && <img className='cursor-pointer' onClick={() => startVijilizeProcess()} src={allImgPaths.enabledVijiliseIcon} alt="" />} */}

                            <SearchBar
                                img_url={allImgPaths.searchIcon}
                                onChange={() => { }}
                                width={'w-64'}
                                placeholder={'Search'}
                            />

                            <ul className='flex items-center'>
                                <li
                                    onClick={() => setIsActiveSection(0)}
                                    className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] rounded-tl-lg rounded-bl-lg flex items-center gap-x-2 cursor-pointer  ${isActiveSection === 0 ? 'bg-[#EBF0FB] pointer-none text-[#113B98]' : 'bg-[#fff] text-[#4F4D55]'}`}>
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>Comparison Library ({tabCount.comparisonLib})</span>
                                </li>

                                <li
                                    onClick={() => { setIsActiveSection(1); callVijilizationList(); }}
                                    className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] flex items-center gap-x-2 cursor-pointer  ${isActiveSection === 1 ? 'bg-[#EBF0FB] pointer-none text-[#113B98]' : 'bg-[#fff]'}`}>
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>Vijilizations ({tabCount.vijilizations || 0})</span>
                                </li>

                                <li
                                    onClick={() => { setIsActiveSection(2); callClaimChartList() }}
                                    className={`font-interSemiBold text-base py-2.5 px-4 border border-[#E6E6E6] flex items-center gap-x-2 cursor-pointer rounded-tr-lg rounded-br-lg ${isActiveSection === 2 ? 'bg-[#EBF0FB] pointer-none text-[#113B98]' : 'bg-[#fff] text-[#4F4D55]'}`}>
                                    <span className='text-sm font-interMedium text-[#4F4D55]'>Vijil Charts ({tabCount.claimCharts})</span>
                                </li>
                            </ul>
                            {/* Dropdown 1 */}
                            <div ref={dropdownRef} className="relative">
                                <img onClick={() => setisShowDropdown(!isShowDropdown)} className='cursor-pointer on-hover-bg-grey w-6 h-6 p-1 rounded' src={allImgPaths.threeVerticleDotsIcon} alt="" />
                                <div className={`absolute bg-white w-36 top-0 right-full shadow-customShadow14 rounded-lg mt-8 -mr-5 z-[100] ${isShowDropdown ? "block" : "hidden"}`}>
                                    <ul>
                                        <li className='flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer' onClick={() => setIsShowEdit(true)}>
                                            <img className='w-[18px]' src={allImgPaths.EditIcon2} alt="" />
                                            <span className='text-sm font-interMedium text-[#0A090B]'>Edit Project</span>
                                        </li>
                                        <li className='flex items-center gap-x-1.5 px-2 py-2.5 hover:bg-[#F5F8FD] cursor-pointer' onClick={() => setIsShowDelete(true)}>
                                            <img src={allImgPaths.deleteicon2} alt="" />
                                            <span className='text-sm font-interMedium text-[#C53434]'>Delete Project</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Comparison Library */}
                    {isActiveSection == 0 && (
                        <div className=''> {/*flex gap-x-5 px-6 h-[calc(100vh_-_170px)] */}
                            {isLoader ? (<ProjectDetailShimmerLoader />) : <></>}
                            {!isLoader ? (<div className='flex gap-x-5 px-6 h-[calc(100vh_-_170px)]'>
                                <div className=' bg-white shadow-sm border-solid border-[1px] border-[#E6E6E6] w-[332px] flex-shrink-0 rounded-lg'>
                                    <div className='flex items-center justify-between py-3.5 px-5 border-b border-[#E6E6E6]'>
                                        <div className='flex items-center gap-x-2'>
                                            <div className='relative leading-none'>
                                                <input
                                                    type='checkbox'
                                                    id={'selectAllSource'}
                                                    name='checkbox-group'
                                                    checked={selectAllSource}
                                                    onChange={handleSelectAllSource}
                                                    className='peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer'
                                                />
                                                <svg
                                                    className='absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white'
                                                    viewBox='0 0 16 16'
                                                    fill='none'
                                                    stroke='currentColor'
                                                    strokeWidth='2'
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'>
                                                    <polyline points='4 8 7 11 12 5'></polyline>
                                                </svg>
                                            </div>
                                            <h2 className='text-sm font-interMedium text-black'>Source</h2>
                                        </div>
                                        {/* <img className='inline-block cursor-pointer on-hover-bg-grey w-7 h-7 p-1 rounded '   src={allImgPaths.circleBlack} alt='' /> */}

                                        {/* <div ref={addDropdownRef} className="relative">
                                            <div onClick={() => setisShowAddDropdown(!isShowAddDropdown)} className={`flex items-center gap-x-2 border rounded-md px-2 py-2 cursor-pointer hover:border-[#1751D0] ${isShowAddDropdown ? "border-[#1751D0]" : "border-[#E6E6E6]"}`}>
                                                <img src={allImgPaths.plusIcon2} alt="" />

                                                <span className='text-sm font-interMedium text-[#4F4D55]'>Add</span>
                                                <img className={`-ml-1 transform ${isShowAddDropdown ? "rotate-180" : "rotate-0"}`} src={allImgPaths.downIcon3} alt="" />
                                            </div>
                                            <div className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${isShowAddDropdown ? "block" : "hidden"}`}>
                                                <ul>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>With patent #</li>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>From Portfolio</li>
                                                    <li onClick={() => setOpenModal(true)} className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>External Upload</li>
                                                    <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>Paste URLs</li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <AddFileWhite
                                            addDropdownRef={addDropdownSrcRef}
                                            setOpenModal={(flag: any) => {
                                                setOpenModal(true);
                                            }}
                                            type="source"
                                        />
                                    </div>

                                    {/* No Source Patent  */}
                                    {
                                        !sourcePatents.length && (
                                            <div className='pt-14 text-center'>
                                                <h2 className='text-base text-[#101828] font-interSemiBold pb-0.5'>No source patents</h2>
                                                <p className='text-sm text-[#475467] pb-4'>Add your source patents</p>
                                                <AddFileBlue addDropdownRef={addDropdownBlueRef} setOpenModal={() => setOpenModal(true)} />
                                                {/* <div className="relative">
                                                    <button ref={addDropdownBlueRef} className={`!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5 mx-auto`} onClick={() => {
                                                        setisShowAddBlueDropdown(!isShowAddBlueDropdown);
                                                    }}>
                                                        <img className='w-4' src={allImgPaths.plusIconWhite} alt="" />
                                                        Add New
                                                        <img className={`-ml-1 transform ${isShowAddBlueDropdown ? "rotate-180" : "rotate-0"}`} src={allImgPaths.downIconWhite} alt="" />
                                                    </button>
                                                    <div className={`absolute bg-white w-36 top-full left-1/2 -translate-x-1/2 shadow-customShadow14 rounded-lg mt-1 z-20 block ${isShowAddBlueDropdown ? "block" : "hidden"}`}>
                                                        <ul>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>With patent #</li>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>From Portfolio</li>
                                                            <li onClick={() => setOpenModal(true)} className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>External Upload</li>
                                                            <li className='flex items-center gap-x-1.5 px-3 py-2.5 hover:bg-[#F5F8FD] cursor-pointer text-sm font-interMedium text-[#0A090B]'>Paste URLs</li>
                                                        </ul>
                                                    </div>
                                                </div> */}


                                            </div>
                                        )
                                    }
                                    <div className='overflow-y-auto h-[calc(100vh_-_240px)]'>
                                        {sourcePatents?.length > 0 &&
                                            sourcePatents.map((dt, index) => (
                                                <li className='px-5 border-b border-[#F1F1F1] flex justify-between py-3 last:border-b-0' key={index}>
                                                    <div className='flex'>
                                                        <div className='relative'>
                                                            <input
                                                                type='checkbox'
                                                                id={dt.value}
                                                                name='checkbox-group'
                                                                checked={selectedSource.includes(dt.patent_id)}
                                                                onChange={(e) => handleSourceSelect(e, dt)}
                                                                className='peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer'
                                                            />
                                                            <svg
                                                                className='absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white'
                                                                viewBox='0 0 16 16'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'>
                                                                <polyline points='4 8 7 11 12 5'></polyline>
                                                            </svg>
                                                        </div>
                                                        <label htmlFor={dt.value} className='ml-2 flex-1 cursor-pointer w-[202px]'>
                                                            <div className='flex flex-col'>
                                                                <div className='flex items-center justify-between'>
                                                                    <Link to={dt.file_url} target="_blank" className=''>
                                                                        <p title={dt.file_name} className='text-sm font-interSemiBold text-[#1751D0] pb-1 truncate max-w-[180px]'>{dt.file_name}</p>
                                                                    </Link>
                                                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{dt.date}</span> */}
                                                                </div>
                                                                {/* <p title={dt.content} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2 max-w-60'>{dt.content}</p> */}
                                                            </div>
                                                        </label>
                                                    </div >
                                                    <div className='flex items-center justify-center gap-x-1.5'>
                                                        {/* {dt.patent_status === 'completed' &&
                                                            <img
                                                                onClick={() => handlePatentClick(dt)}
                                                                src={allImgPaths.starIntial}
                                                                alt='star icon'
                                                                className='w-7 h-7 cursor-pointer object-contain'
                                                                onMouseOver={(e: any) => e.target.src = allImgPaths.starFinal}
                                                                onMouseOut={(e: any) => e.target.src = allImgPaths.starIntial}
                                                            />
                                                        } */}

                                                        <div className="relative group">
                                                            {(dt.patent_status === 'not_started' || dt.patent_status === 'failed') && <img onClick={() => startIndivijualAnalyzation(dt)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzeIcon} alt="" />}
                                                            {dt.patent_status === 'completed' && <img onClick={() => handlePatentClick(dt)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.viewAnalysis} alt="" />}
                                                            {dt.patent_status === 'in_progress' && <img className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzingIcon1} alt="" />}
                                                            <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 right-9 top-0 mb-3 transform  cursor-default hidden group-hover:block">
                                                                <span className="arrow-bottom2 w-0 h-0 block absolute  transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                <ul className="text-center">
                                                                    {(dt.patent_status === 'not_started' || dt.patent_status === 'failed') && <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                        Analyze
                                                                    </li>}
                                                                    {dt.patent_status === 'completed' && <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                                        View Analysis
                                                                    </li>}
                                                                    {dt.patent_status === 'in_progress' && <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                        Analyzing...
                                                                    </li>}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="relative group">
                                                            <img className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.threedotsVerticles2} alt="" />
                                                            <div className="bg-white shadow-customShadow14 rounded-lg absolute z-[100] -right-3 transform cursor-default hidden group-hover:block">
                                                                <li className="flex items-center gap-x-2 w-max cursor-pointer hover:bg-[#F5F8FD] py-2 px-2 " onClick={() => deletePatent(dt.patent_id)} >
                                                                    <img className='w-3 h-3' src={allImgPaths.closeIconRed} alt="" />
                                                                    <span className="text-sm font-interMedium text-[#C53434]">Remove</span>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li >
                                            ))
                                        }
                                    </div >
                                </div >

                                <div className='bg-white rounded-lg shadow-sm border-solid border border-[#E6E6E6] w-[calc(100%_-_346px)]'>
                                    <div className='flex px-3 py-2 justify-between items-center border-b border-[#E6E6E6]'>
                                        <span className='text-sm font-interMedium text-black'>Compare with</span>

                                        <div className='flex gap-x-4 items-center'>
                                            {/* <button className='!text-sm text-white bg-[#1751D0] hover:bg-[#113B98] flex items-center gap-1.5 font-interMedium rounded-lg  px-3.5 py-2.5' onClick={() => setOpenComparisonModal(true)}>
                                                Add
                                            </button> */}
                                            <AddFileWhite
                                                addDropdownRef={addDropdownCmpRef}
                                                setOpenModal={(flag: any) => {
                                                    setOpenComparisonModal(true);
                                                }}
                                                type="compare"
                                            />
                                            <SearchBar
                                                img_url={allImgPaths.searchIcon}
                                                width={'w-full'}
                                                placeholder={'Search'}
                                            />
                                        </div>
                                        {/* <div className="text-sm">
                                            Dropdown
                                        </div> */}
                                    </div>
                                    {/* Tabs */}
                                    <div className='bg-[#FFF] border-b border-[#EAECF0]'>
                                        <div className='flex items-center justify-center w-full'>
                                            <div id='scrollBoardContainer' ref={scrollContainerRef} className='flex items-center no-scrollbar gap-[16px] overflow-x-auto h-[43px] w-[calc(100vw_-_225px)]'>
                                                {columns?.length > 0 &&
                                                    columns.map((column: any, cIndex: any) => (
                                                        <div className='w-1/3 text-center relative'>
                                                            <span
                                                                key={cIndex}
                                                                onClick={() => {
                                                                    selectColumn(cIndex);
                                                                }}
                                                                className={`${isActiveColumn === cIndex ? 'text-[#1751D0] planning-board-border-bottom after:bg-[#1751D0]' : 'text-[#7F7D83]'
                                                                    }  text-sm font-interMedium pointer-cursor mx-10 flex-shrink-0 hover:text-[#1751D0]`}>
                                                                {column.name}
                                                                <span className='text-sm font-interMedium ml-1'>({column.count})</span>
                                                            </span>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='bg-white'>
                                            {/* Tabs Head */}
                                            {/* <div className='flex flex-nowrap sticky top-0 z-10 checkbox-alignment2'>
                                                    {isActiveColumn === 0 &&
                                                        patentColumns?.length > 0 &&
                                                        comparePatents.length > 0 &&
                                                        patentColumns.map((column: any, columnIndex: number) => (
                                                            <React.Fragment key={columnIndex}>
                                                                {
                                                                    <div className={createColumnClassName(column.ref)}>
                                                                        
                                                                        {column.name === 'PatentId' && (
                                                                            <span className=' font-interMedium text-sm text-[#4F4D55] flex items-center justify-normal gap-x-2 w-[100%]'>
                                                                                <div className='relative inline-block'>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        id={'select-all-' + columnIndex}
                                                                                        name='checkbox-group'
                                                                                        checked={selectAll}
                                                                                        onChange={handleSelectAll}
                                                                                        className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                checked:bg-indigo-600 checked:border-0 
                                                                                transition-colors cursor-pointer'
                                                                                    />
                                                                                    <svg
                                                                                        className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                          inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                        viewBox='0 0 16 16'
                                                                                        fill='none'
                                                                                        stroke='currentColor'
                                                                                        strokeWidth='2'
                                                                                        strokeLinecap='round'
                                                                                        strokeLinejoin='round'>
                                                                                        <polyline points='4 8 7 11 12 5'></polyline>
                                                                                    </svg>
                                                                                </div>
                                                                                {column.name}
                                                                                <img className={`w-4 cursor-pointer transform ${sortOrder == 'asc' ? 'rotate-0' : 'rotate-180'}`} src={allImgPaths.arrowUp3} alt='' onClick={() => handleSort(column.ref)} />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        ))}

                                                </div > */}

                                            {/* Body */}
                                            {/* Patents */}
                                            {
                                                isActiveColumn === 0 && (
                                                    <>
                                                        <div className={'mt-4'}>

                                                            {comparePatents && comparePatents.length &&
                                                                <div className="rounded-bl-lg rounded-br-lg overflow-hidden">
                                                                    {/* Table Head */}
                                                                    {<div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                                                                        <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                                                            <div className="flex items-center gap-x-2 cursor-pointer">
                                                                                <div className='relative leading-none'>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        id={'selectAllCompare'}
                                                                                        name='checkbox-group'
                                                                                        checked={selectAllCompare}
                                                                                        onChange={handleSelectAllCompare}
                                                                                        className='peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                            checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                            transition-colors cursor-pointer'
                                                                                    />
                                                                                    <svg
                                                                                        className='absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white'
                                                                                        viewBox='0 0 16 16'
                                                                                        fill='none'
                                                                                        stroke='currentColor'
                                                                                        strokeWidth='2'
                                                                                        strokeLinecap='round'
                                                                                        strokeLinejoin='round'>
                                                                                        <polyline points='4 8 7 11 12 5'></polyline>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="flex items-center gap-x-1.5">
                                                                                    <span className="font-interMedium text-sm text-[#0A090B]">File Name</span>
                                                                                    <img className='w-4 cursor-pointer transform rotate-180' src={allImgPaths.arrowUp3} alt="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                                                            <div className="flex items-center gap-x-2 cursor-pointer">
                                                                                <span className="font-interMedium text-sm text-[#0A090B]">Last Update</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                                                            <div className="flex items-center gap-x-2 cursor-pointer">
                                                                                <span className="font-interMedium text-sm text-[#0A090B]">Action</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>}

                                                                    {/* Table body */}
                                                                    <div className='max-h-[calc(100vh_-_332px)] overflow-y-auto rounded-bl-lg rounded-br-lg'>
                                                                        {comparePatents.map((row: any, rowIndex: number) => (
                                                                            <div className="h-auto">
                                                                                <div className="flex">
                                                                                    <div className="2xl:w-[70%] w-[60%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex">
                                                                                        <div className="flex items-center gap-x-2 cursor-pointer">

                                                                                            <div className='relative'>
                                                                                                <input
                                                                                                    type='checkbox'
                                                                                                    id={'item' + rowIndex}
                                                                                                    name='checkbox-group'
                                                                                                    checked={selectedComparePatent.includes(row.patent_id)}
                                                                                                    onChange={(e) => handleSelectedCompare(e, row)}
                                                                                                    className='peer appearance-none h-[18px] w-[18px] border border-gray-300 rounded-md 
                                                                                                    checked:bg-[#1751D0] hover:border-[#1751D0] checked:border-0 
                                                                                                    transition-colors cursor-pointer'
                                                                                                />
                                                                                                <svg
                                                                                                    className='absolute w-[18px] h-[18px] pointer-events-none hidden peer-checked:block inset-0 stroke-white'
                                                                                                    viewBox='0 0 16 16'
                                                                                                    fill='none'
                                                                                                    stroke='currentColor'
                                                                                                    strokeWidth='2'
                                                                                                    strokeLinecap='round'
                                                                                                    strokeLinejoin='round'>
                                                                                                    <polyline points='4 8 7 11 12 5'></polyline>
                                                                                                </svg>
                                                                                            </div>
                                                                                            <Link to={row.file_url} target="_blank" className='font-interMedium inline-block'>
                                                                                                <div className=' font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0] underline'>{row.file_name}</div>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6">
                                                                                        <div>
                                                                                            <p className="text-sm text-[#4F4D55] font-interRegular"> {formatDate(row.uploaded_at)}</p>
                                                                                            <p className="text-sm text-[#ADACB0] font-interRegular"> {row?.uploaded_by?.user_name} </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="2xl:w-[10%] w-[20%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-2.5 pb-2.5 px-6 flex justify-center">
                                                                                        <div className='flex items-center gap-x-2 justify-center'>
                                                                                            {
                                                                                                (row.patent_status === 'not_started' || row.patent_status === 'failed') &&
                                                                                                <div className="relative group">
                                                                                                    <img onClick={() => startIndivijualAnalyzation(row)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzeIcon} alt="" />

                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                                                                Analyze
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                row.patent_status === 'completed' &&
                                                                                                <div className="relative group">
                                                                                                    <img onClick={() => handlePatentClick(row)} className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.viewAnalysis} alt="" />
                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0 whitespace-nowrap">
                                                                                                                View Analysis
                                                                                                            </li>
                                                                                                            {/* {dt.patent_status === 'in_progress' && <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                                                                Analyzing...
                                                                                                            </li>} */}
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                row.patent_status === 'in_progress' &&
                                                                                                <div className="relative group">
                                                                                                    <img className='cursor-pointer on-hover-bg-grey w-[26px] h-[26px] p-1 rounded' src={allImgPaths.analyzingIcon1} alt="" />
                                                                                                    <div className="bg-[#0C111D] rounded-lg py-1.5 px-2 min-w-fit absolute z-10 mb-3 transform right-9 top-0 cursor-default hidden group-hover:block">
                                                                                                        <span className="arrow-bottom2 w-0 h-0 block absolute transform -right-[9px] top-2.5 -rotate-90"></span>
                                                                                                        <ul className="text-center">
                                                                                                            <li className="text-xs font-interMedium text-white pb-1 last:pb-0">
                                                                                                                Analyzing...
                                                                                                            </li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            <img className='cursor-pointer on-hover-bg-grey w-[28px] h-[28px] p-1 rounded' src={allImgPaths.deleteIcon3} alt="" onClick={() => deletePatent(row.patent_id)} />

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>}

                                                            {/* Empty State */}
                                                            {comparePatents.length === 0 &&
                                                                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                                                    <div className="text-center">
                                                                        <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                                        <p className="font-interSemiBold text-base text-[#101828]">No data found</p>
                                                                        <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* Pagi */}
                                                            {/* <div className="flex items-center justify-center w-full px-5 py-6">
                                                                    <div className="text-base font-interRegular text-[#4F4D55]">
                                                                        Showing <span className="text-[#0A090B] font-interMedium">1</span> to{" "}
                                                                        <span className="text-[#0A090B] font-interMedium">4</span> of{" "}
                                                                        <span className="text-[#0A090B] font-interMedium">4</span> Results
                                                                    </div>
                                                                </div> */}
                                                        </div>
                                                    </>

                                                )
                                            }
                                            {/* Files */}
                                            {
                                                isActiveColumn === 1 && (
                                                    <>
                                                        <div className={'h-[calc(100vh_-_356px)] mt-4'}>
                                                            {compareFiles.length > 0 &&
                                                                compareFiles.map((row: any, rowIndex: number) => (
                                                                    <React.Fragment key={rowIndex}>

                                                                        <div className={`flex flex-nowrap`}>
                                                                            {patentColumns?.length > 0 &&
                                                                                patentColumns.map((column: any, columnIndex: number) => (
                                                                                    <React.Fragment key={columnIndex}>
                                                                                        {column.ref == 'file_name' && (
                                                                                            <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                                <span className='text-sm font-interSemiBold text-[#1751D0] flex items-center gap-x-2 '>
                                                                                                    <div className='relative inline-block'>
                                                                                                        <input
                                                                                                            type='checkbox'
                                                                                                            id={'item' + rowIndex}
                                                                                                            name='checkbox-group'
                                                                                                            checked={selectedItems.includes(rowIndex)}
                                                                                                            onChange={(e) => { handleSelectItem(rowIndex); handleSelectedCompare(e, row) }}
                                                                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                                checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                                                                transition-colors cursor-pointer'
                                                                                                        />
                                                                                                        <svg
                                                                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                                  inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                                            viewBox='0 0 16 16'
                                                                                                            fill='none'
                                                                                                            stroke='currentColor'
                                                                                                            strokeWidth='2'
                                                                                                            strokeLinecap='round'
                                                                                                            strokeLinejoin='round'>
                                                                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    {row[column.ref]}
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                        {/* {column.ref == 'patentTitle' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className=' flex items-center gap-x-2 text-neutral-700 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref == 'publication_date' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className='text-neutral-900 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref === 'action' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <img className='cursor-pointer hover:bg-[#f2f4f7]' src={allImgPaths.trashIcon} alt='' />
                                                                                        </div>
                                                                                    )} */}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                        </div>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                            {
                                                                compareFiles.length === 0 &&
                                                                <div className="flex justify-center items-center h-full">
                                                                    <div className="text-center">
                                                                        <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                                        <p className="font-interSemiBold text-base text-[#101828]">No Files found</p>
                                                                        <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>

                                                                        {/* <div className="text-sm mt-4">
                                                                            Dropdown
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div ></>
                                                )
                                            }
                                            {/* URL */}
                                            {
                                                isActiveColumn === 2 && (
                                                    <>
                                                        <div className={'h-[calc(100vh_-_356px)] mt-4'}>
                                                            {compareURLs.length > 0 &&
                                                                compareURLs.map((row: any, rowIndex: number) => (
                                                                    <React.Fragment key={rowIndex}>

                                                                        <div className={`flex flex-nowrap`}>
                                                                            {patentColumns?.length > 0 &&
                                                                                patentColumns.map((column: any, columnIndex: number) => (
                                                                                    <React.Fragment key={columnIndex}>
                                                                                        {column.ref == 'file_name' && (
                                                                                            <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                                <span className='text-sm font-interSemiBold text-[#1751D0] flex items-center gap-x-2 '>
                                                                                                    <div className='relative inline-block'>
                                                                                                        <input
                                                                                                            type='checkbox'
                                                                                                            id={'item' + rowIndex}
                                                                                                            name='checkbox-group'
                                                                                                            checked={selectedItems.includes(rowIndex)}
                                                                                                            onChange={(e) => { handleSelectItem(rowIndex); handleSelectedCompare(e, row) }}
                                                                                                            className='peer appearance-none h-5 w-5 border border-gray-300 rounded-md 
                                                                                                checked:bg-indigo-600 hover:border-indigo-600 checked:border-0 
                                                                                                transition-colors cursor-pointer'
                                                                                                        />
                                                                                                        <svg
                                                                                                            className='absolute w-5 h-5 pointer-events-none hidden peer-checked:block 
                                                                                                  inset-0 stroke-white mt-[1px] ml-[1px]'
                                                                                                            viewBox='0 0 16 16'
                                                                                                            fill='none'
                                                                                                            stroke='currentColor'
                                                                                                            strokeWidth='2'
                                                                                                            strokeLinecap='round'
                                                                                                            strokeLinejoin='round'>
                                                                                                            <polyline points='4 8 7 11 12 5'></polyline>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    {row[column.ref]}
                                                                                                </span>
                                                                                            </div>
                                                                                        )}
                                                                                        {/* {column.ref == 'patentTitle' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className=' flex items-center gap-x-2 text-neutral-700 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref == 'publication_date' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <span className='text-neutral-900 font-inter text-sm font-normal leading-5 tracking-tight'> {row[column.ref]} </span>
                                                                                        </div>
                                                                                    )}
                                                                                    {column.ref === 'action' && (
                                                                                        <div className={`${createColumnClassName(column.ref)} table-row`}>
                                                                                            <img className='cursor-pointer hover:bg-[#f2f4f7]' src={allImgPaths.trashIcon} alt='' />
                                                                                        </div>
                                                                                    )} */}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                        </div>
                                                                    </React.Fragment>
                                                                ))}
                                                            {compareURLs.length === 0 &&
                                                                <div className="flex justify-center items-center h-full">
                                                                    <div className="text-center">
                                                                        <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                                        <p className="font-interSemiBold text-base text-[#101828]">No URLs found</p>
                                                                        <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div></>
                                                )
                                            }
                                        </div >
                                    </div >
                                </div >

                            </div >) : <></>
                            }
                        </div >
                    )
                    }

                    {/* Vijilizations */}
                    {
                        isActiveSection === 1 && (
                            <div className="">
                                {vijilizeLoader ? <VijilizeShimmerLoader /> :
                                    <div>
                                        {vijilizedData.length !== 0 &&
                                            <div className="flex bg-white sticky top-0 z-10 border-t border-[#F1F1F1]">
                                                <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                                                    <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                        {/* <Checkbox
                                            data={checkBoxdata}
                                            /> */}
                                                        <div className="flex items-center gap-x-1 cursor-pointer">
                                                            <span className="font-interMedium text-sm text-[#0A090B]">
                                                                Name
                                                            </span>
                                                            <img
                                                                className="w-4 cursor-pointer transform rotate-180"
                                                                src={allImgPaths.arrowUp3}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                                                    <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                        <span className="cursor-pointer font-interMedium text-sm text-[#0A090B]">
                                                            Last Update
                                                        </span>
                                                        <img
                                                            className="w-4 cursor-pointer transform rotate-180"
                                                            src={allImgPaths.arrowUp3}
                                                            alt=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6">
                                                    <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                        <span className="cursor-pointer font-interMedium text-sm whitespace-nowrap text-[#0A090B]">
                                                            Data Used
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-[10%] flex flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 justify-center">
                                                    <div className="flex items-center justify-start gap-x-2 pt-0.5">
                                                        <span className="cursor-pointer font-interMedium whitespace-nowrap text-sm text-[#0A090B]">
                                                            Status
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div className="w-[10%] flex flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 justify-center">
                                                    <div className="font-interMedium text-sm text-[#0A090B] inline-block">
                                                        Action
                                                    </div>
                                                </div> */}
                                            </div>}

                                        <div className="max-h-[calc(100vh_-_187px)] overflow-y-auto">

                                            {vijilizedData.map((data: any, index) => (

                                                <div className="flex">
                                                    <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 table-row !justify-start">
                                                        <div className="">
                                                            <label htmlFor="item0" className="flex items-center gap-x-2">
                                                                <div className="">
                                                                    {/* <a
                                                                    className="font-interMedium inline-block smoothness"
                                                                    href={`project/${projectId}/vijilize`}
                                                                > */}
                                                                    {data.status === 'completed' && <Link className="font-interMedium inline-block smoothness" state={{ source: data.source, compare: data.compare }} to={`/project/${projectId}/vijilize/${data._id}/heatmap`} onClick={() => setVijilizeData(data)}>
                                                                        <div className=" font-interMedium text-sm pb-0.5 hover:text-[#113B98] text-[#1751D0]">
                                                                            {data.name}
                                                                        </div>
                                                                    </Link>}
                                                                    {data.status !== 'completed' &&
                                                                        <div className=" font-interMedium text-sm pb-0.5 cursor-not-allowed hover:text-[#113B98] text-[#1751D0]">
                                                                            {data.name}
                                                                        </div>
                                                                    }

                                                                    {/* </a> */}
                                                                    <div
                                                                        title="dddd"
                                                                        className=" max-w-[24vw] truncate text-[#7F7D83] font-interMedium text-sm"
                                                                    >
                                                                        {data.description}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center">
                                                        <div>
                                                            <p className="text-sm text-[#4F4D55] font-interRegular">
                                                                {formatDate(data.updatedAt)}
                                                            </p>
                                                            <p className="text-sm text-[#ADACB0] font-interRegular">
                                                                {data?.updated_by?.user_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => { setIsShowSideBar(true); setSrcRefData({ source: data.source, compare: data.compare }); }} className="w-[30%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center gap-x-1">
                                                        <span className="font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer">
                                                            {data?.source?.length} Source
                                                        </span>
                                                        <span className='font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer'> | </span>
                                                        <span className='font-interMedium group-hover:text-[#1E0AF5] text-sm text-[#006FEE] inline-block cursor-pointer'>{data?.compare?.length} Reference</span>
                                                    </div>
                                                    <div className="w-[10%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 pt-1.5 pb-2 px-6 flex items-center justify-center">
                                                        {data.status === 'in_progress' && <span className='text-sm font-interMedium text-[#113B98] bg-[#EBF0FB] rounded px-2 py-1'>Running</span>}

                                                        {data.status === 'completed' && <span className='text-sm font-interMedium text-[#016626] bg-[#E1FAEA] rounded px-2 py-1'>Success</span>}

                                                        {data.status === 'failed' && <span className='text-sm font-interMedium text-[#961616] bg-[#FFE3E3] rounded px-2 py-1'>Failed</span>}
                                                    </div>
                                                    {/* {data.status === 'completed' && <div className="w-[10%] flex-shrink-0 border-b border-[#F1F1F1] border-t-0 py-2 px-6 flex items-center justify-center">
                                                        <img
                                                            src={allImgPaths.downloadIcon2}
                                                            alt="download icon"
                                                            className="inline-block cursor-pointer on-hover-bg-grey w-7 p-1 rounded"
                                                        />
                                                    </div>} */}
                                                </div>
                                            ))}

                                            {vijilizedData.length === 0 &&
                                                <div className="flex justify-center items-center h-[calc(100vh_-_356px)]">
                                                    <div className="text-center">
                                                        <img className="mb-2.5 ml-auto mr-auto" src={allImgPaths.searchIcon3} alt="" />
                                                        <p className="font-interSemiBold text-base text-[#101828]">No vijilisation found</p>
                                                        <p className="font-interRegular text-sm text-[#475467]">Please add patents, files, URLs, publications to <br /> compare with your source patent</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>}


                                {/* Pagination */}

                                {false && <div className="flex items-center justify-center gap-x-2 py-6">
                                    <span className='text-sm text-[#4F4D55] font-interMedium'>Showing</span>
                                    <span className='text-[#0A090B] font-interSemiBold'>1</span>
                                    <span className='text-sm text-[#4F4D55] font-interMedium'>to</span>
                                    <span className='text-[#0A090B] font-interSemiBold'>1</span>
                                    <span className='text-sm text-[#4F4D55] font-interMedium'>of</span>
                                    <span className='text-[#0A090B] font-interSemiBold'>1</span>
                                    <span className='text-sm text-[#4F4D55] font-interMedium'>Results</span>
                                </div>}

                            </div>
                        )
                    }

                    {/* Claim chart */}
                    {
                        isActiveSection === 2 && (
                            <div className='w-full'>{<ClaimTable claimData={claimChartData} projectId={projectId || '0'} isClaimTableLoading={isClaimTableLoading} />}</div>
                        )
                    }
                </div >
            </div >
            {openModal && <AddSource isShow={openModal} setIsShow={setOpenModal} setToast={setToast} projectId={projectId || ''} setRefreshData={() => setRefreshData(prev => !prev)} />}
            {openComparisonModal && <AddComparisonData isShow={openComparisonModal} setIsShow={setOpenComparisonModal} setToast={setToast} projectId={projectId || ''} setRefreshData={() => setRefreshData(prev => !prev)} />}
            {vijilizeModalStatus && <VijiliseModal isShow={vijilizeModalStatus} setIsShow={setVijilizeModalStatus} setRefreshData={() => setRefreshData(prev => !prev)}  selectedSource={selectedSource} selectedComparePatent={selectedComparePatent} projectId={projectId} sourcePatents={sourcePatents} comparePatents={comparePatents} />}

            {/* Sidebar */}
            <div className={`w-full h-full fixed bg-[#07031154] z-[100] ${isShowSideBar ? "right-0" : "right-full"}`}>
                <div onClick={() => setIsShowSideBar(false)} className="h-full w-[calc(100%_-_360px)]"></div>
                <div className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${isShowSideBar ? "right-0" : "right-full"}`}>
                    <div className="pb-6 px-6 pt-4">
                        <div className="pb-0.5 flex items-center justify-between">
                            <span className="text-lg font-interSemiBold text-[#101828] inline-block ">Data Used</span>
                            <img onClick={() => setIsShowSideBar(false)} className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1" src={allImgPaths.closeIcon} alt="" />
                        </div>

                        <p className="text-[#475467] text-sm ">List of source and compare with data used for this vijilization.</p>
                    </div>

                    <div className="h-[calc(100vh_-_130px)]">
                        <div className="">
                            <div className="bg-[#F8F8F8] rounded-lg p-1.5 mx-6 mb-4">
                                <div className="flex items-center gap-x-1">
                                    <p onClick={() => setToggledDataUsed(0)} className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${toggledDataUsed === 0 ? "bg-white shadow-customShadow12 text-[#0A090B]" : "text-[#4F4D55]"}`}>Source ({srcRefData?.source?.length})</p>
                                    <p onClick={() => setToggledDataUsed(1)} className={`text-sm font-interMedium rounded-lg py-1.5 px-2 flex-1 flex justify-center cursor-pointer ${toggledDataUsed === 1 ? "bg-white shadow-customShadow12 text-[#0A090B]" : "text-[#4F4D55]"}`}>Compare With ({srcRefData?.compare?.length})</p>
                                </div>
                            </div>
                            <div className="overflow-y-auto h-[calc(100vh_-_180px)] px-6">
                                {/* Source */}
                                {
                                    toggledDataUsed === 0 && srcRefData?.source &&
                                    (
                                        <>
                                            {
                                                srcRefData?.source.map((item: any, id: number) => {
                                                    return (
                                                        <>
                                                            <div key={id} className='border-b px-5 py-3'>
                                                                <div className='flex items-center gap-x-4'>
                                                                    <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                                                                        <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                                                                    </Link>
                                                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                                                                </div>
                                                                {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )

                                }

                                {/* Compare with */}
                                {
                                    toggledDataUsed === 1 && srcRefData?.compare &&
                                    (
                                        <>
                                            {
                                                srcRefData?.compare.map((item: any, id: number) => {
                                                    return (
                                                        <>
                                                            <div key={id} className='border-b px-5 py-3'>
                                                                <div className='flex items-center gap-x-4'>

                                                                    <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                                                                        <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                                                                    </Link>
                                                                    {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                                                                </div>
                                                                {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )

                                }
                            </div>
                        </div>
                    </div>

                    {/* <div className="">
                    <div className="flex gap-x-3 justify-end z-10 mt-4 px-5 py-4 border-t border-[#E4E7EC]">
                        <button onClick={() => setIsShowSideBar(false)} className='text-[#344054] font-interSemiBold border border-[#D0D5DD] rounded-lg tracking-wide w-40 py-2.5 px-2 !text-base hover:bg-[#f2f4f7]'>Cancel</button>
                        <button className={`font-interSemiBold text-base text-[#667085] py-2 px-2 w-40 border border-[#1E0AF5] rounded-lg flex items-center justify-center cursor-pointer shadow-customShadow3 bg-white hover:bg-[#E8E6FE]`}>
                            <span className="text-[15px] text-[#1E0AF5] font-interSemiBold">Apply</span>
                        </button>
                    </div>
                </div> */}
                </div>
            </div>
            {/* Edit Project */}
            {
                isShowEdit && <EditProject
                    isShow={isShowEdit}
                    setIsShow={() => setIsShowEdit(false)}
                    setToast={setToast}
                    setRefreshData={() => setRefreshData(prev => !prev)}
                    projectId={projectId || '0'}
                    projectName={projectDetail.title}
                    projectDesc={projectDetail.description}
                />
            }

            {/* Delete Project */}
            {
                isShowDelete && <DeleteModal
                    isShow={isShowDelete}
                    setIsShow={() => setIsShowDelete(false)}
                    setToast={setToast}
                    redirectToHome={() => redirectToHome()}
                    setRefreshData={() => setRefreshData(prev => !prev)}
                    projectId={projectId || '0'}
                />
            }
        </div >

    );
}
export default FindCoveredProducts;
