import allImgPaths from "assets/images";

const CitationsSidebar = ({
  isShowSideBarCitations,
  setIsShowSideBarCitations,
}: any) => {
  return (
    <div
      className={`w-full h-full fixed bg-[#07031154] z-[100] ${
        isShowSideBarCitations ? "right-0" : "right-full"
      }`}
    >
      <div
        onClick={() => setIsShowSideBarCitations(false)}
        className="h-full w-[calc(100%_-_360px)]"
      ></div>
      <div
        className={`border-l border-[#E4E7EC] w-[360px] h-screen fixed z-50 bg-white top-0 shadow-customShadow15 ${
          isShowSideBarCitations ? "right-0" : "right-full"
        }`}
      >
        <div className="pb-6 px-6 pt-4">
          <div className="pb-0.5 flex items-center justify-between">
            <span className="text-lg font-interSemiBold text-[#101828] inline-block ">
              Citation
            </span>
            <img
              onClick={() => setIsShowSideBarCitations(false)}
              className="cursor-pointer hover:bg-[#f2f4f7] rounded w-5 p-1"
              src={allImgPaths.closeIcon}
              alt=""
            />
          </div>

          <p className="text-[#475467] text-sm ">
            Reference to the source file
          </p>
        </div>

        <div className="h-[calc(100vh_-_130px)]">
          <div className="">
            <div className="overflow-y-auto h-[calc(100vh_-_180px)] px-6">
              {/* Source */}
              {
                // toggledDataUsed === 0 && APIData?.source &&
                // (
                //     <>
                //         {
                //             APIData?.source.map((item: any, id: number) => {
                //                 return (
                //                     <>
                //                         <div key={id + item.file_url} className='border-b px-5 py-3'>
                //                             <div className='flex items-center gap-x-4'>
                //                                 <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                //                                     <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                //                                 </Link>
                //                                 {/* <span className='text-sm font-interRegular text-[#4F4D55]'>{item}</span> */}
                //                             </div>
                //                             {/* <p title={'Apparatus for gripping handheld devices'} className='text-sm font-interMedium text-[#7F7D83] line-clamp-2'>{item}</p> */}
                //                         </div>
                //                     </>
                //                 )
                //             })
                //         }
                //     </>
                // )
              }

              {/* Covered Products */}
              {
                // toggledDataUsed === 1 && APIData?.compare &&
                // (
                //     <>
                //         {
                //             APIData?.compare.map((item: any, id: number) => {
                //                 return (
                //                     <>
                //                         <div key={id} className='border-b px-5 py-3'>
                //                             <div className='flex items-center gap-x-4'>
                //                                 <Link to={item.file_url} target="_blank" className='cursor-pointer'>
                //                                     <p className='text-sm font-interSemiBold text-[#1751D0] pb-1'>{item.file_name}</p>
                //                                 </Link>
                //                             </div>
                //                         </div>
                //                     </>
                //                 )
                //             })
                //         }
                //     </>
                // )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CitationsSidebar;
