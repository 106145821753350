import useSignupHook from "../hooks/auth/useSignupHook";
import useVerifyHook from "../hooks/auth/useVerifyHook";
import useRegisterHook from "../hooks/auth/useRegisterHook";
import useSigninHook from "../hooks/auth/useSigninHook";
import useForgotPassword from "../hooks/auth/useForgotPassword";
import useResetPassword from "../hooks/auth/useResetPassword";
import useTeamSetup from "../hooks/auth/useTeamSetup";
import useSelectTeam from "../hooks/auth/useSelectTeam";
import useSettingsHook from "../hooks/auth/useSettingsHook";
import useSideBar from "../hooks/sidebar/useSideBar";
import useVijilize from "hooks/vijilize/useVijilize";
// import { usePagination } from "hooks/paginate/usePagination";

const containers = {
  signup: useSignupHook,
  register: useRegisterHook,
  verify: useVerifyHook,
  login: useSigninHook,
  resetPassword: useResetPassword,
  forgotPassword: useForgotPassword,
  teamSetup: useTeamSetup,
  selectTeam: useSelectTeam,
  settings: useSettingsHook,
  sidebar: useSideBar,
  vijilize:useVijilize
};




export default containers;
