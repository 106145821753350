import React from 'react';

const ProjectShimmerLoader = () => {
    return (
        <div className="w-full mx-auto space-y-4 p-4">
            {/* Table Header */}
            <div className="flex items-center gap-4 pb-4 border-b">
                <div className="w-48 h-6 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-32 h-6 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-24 h-6 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-32 h-6 bg-gray-200 rounded animate-pulse"></div>
            </div>

            {/* Project Rows */}
            {[1,2,3,4,5,6].map((item) => (
                <div key={item} className="flex items-center justify-between py-4 border-b gap-4">
                    <div className="flex flex-col gap-2 flex-1">
                        <div className="w-64 h-5 bg-gray-200 rounded animate-pulse"></div>
                        <div className="w-48 h-4 bg-gray-100 rounded animate-pulse"></div>
                    </div>

                    <div className="w-32 h-5 bg-gray-200 rounded animate-pulse"></div>

                    <div className="w-8 h-5 bg-gray-200 rounded animate-pulse"></div>

                    <div className="w-32 flex items-center gap-2">
                        <div className="w-6 h-6 bg-gray-200 rounded-full animate-pulse"></div>
                        <div className="w-6 h-6 bg-gray-200 rounded-full animate-pulse"></div>
                    </div>

                    <div className="flex gap-3">
                        <div className="w-8 h-8 bg-gray-200 rounded animate-pulse"></div>
                        <div className="w-8 h-8 bg-gray-200 rounded animate-pulse"></div>
                        <div className="w-8 h-8 bg-gray-200 rounded animate-pulse"></div>
                        <div className="w-8 h-8 bg-gray-200 rounded animate-pulse"></div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectShimmerLoader;