import React from 'react';

const ProjectDetailShimmerLoader = () => {
    return (
        <div className="w-full">

            <div className='flex gap-x-5 px-6 h-[calc(100vh_-_170px)]'>
                {/* Left sidebar shimmer */}
                <div className='bg-white shadow-sm border border-[#E6E6E6] w-[332px] flex-shrink-0 rounded-lg'>
                    {/* Header */}
                    <div className='flex items-center justify-between py-3.5 px-3 border-b border-[#E6E6E6]'>
                        <div className='h-6 w-32 bg-gray-200 animate-pulse rounded'></div>
                        <div className='w-7 h-7 bg-gray-200 animate-pulse rounded'></div>
                    </div>

                    {/* Search bar shimmer */}
                    <div className="px-3 pt-3 pb-4">
                        <div className='h-10 bg-gray-200 animate-pulse rounded-lg w-full'></div>
                    </div>

                    {/* Patent list items shimmer */}
                    <div className='px-6'>
                        {[1,2,3,4].map((item) => (
                            <div key={item} className='py-3 border-b border-[#F1F1F1] last:border-b-0'>
                                <div className='flex items-center gap-3'>
                                    <div className='w-5 h-5 bg-gray-200 animate-pulse rounded'></div>
                                    <div className='flex-1'>
                                        <div className='h-4 w-3/4 bg-gray-200 animate-pulse rounded mb-2'></div>
                                        <div className='h-3 w-1/2 bg-gray-200 animate-pulse rounded'></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Main content shimmer */}
                <div className='bg-white rounded-lg shadow-sm border border-[#E6E6E6] w-[calc(100%_-_346px)]'>
                    {/* Header */}
                    <div className='flex px-3 py-2 justify-between items-center border-b border-[#E6E6E6]'>
                        <div className='h-5 w-24 bg-gray-200 animate-pulse rounded'></div>
                        <div className='flex gap-x-4 items-center'>
                            <div className='h-10 w-20 bg-gray-200 animate-pulse rounded'></div>
                            <div className='h-10 w-64 bg-gray-200 animate-pulse rounded'></div>
                        </div>
                    </div>

                    {/* Tabs shimmer */}
                    <div className='bg-white border-b border-[#EAECF0] px-6'>
                        <div className='flex gap-x-8 py-3'>
                            {[1,2,3,4].map((tab) => (
                                <div key={tab} className='h-5 w-24 bg-gray-200 animate-pulse rounded'></div>
                            ))}
                        </div>
                    </div>

                    {/* Table header shimmer */}
                    <div className='flex px-6 py-4 border-b border-[#E6E6E6]'>
                        <div className='w-5 h-5 bg-gray-200 animate-pulse rounded mr-3'></div>
                        <div className='h-5 w-32 bg-gray-200 animate-pulse rounded'></div>
                    </div>

                    {/* Table rows shimmer */}
                    <div className='px-6'>
                        {[1,2,3,4,5].map((row) => (
                            <div key={row} className='py-4 border-b border-[#E6E6E6] last:border-b-0'>
                                <div className='flex items-center gap-3'>
                                    <div className='w-5 h-5 bg-gray-200 animate-pulse rounded'></div>
                                    <div className='flex-1'>
                                        <div className='h-4 w-full bg-gray-200 animate-pulse rounded'></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>


    );
};

export default ProjectDetailShimmerLoader;