import Select from 'react-select';
import { ExtendedProps, optionProp } from "../../types";
// import addTeamIcon from '../../assets/images/add-team.png';
// import profileIcon from '../../assets/images/profile-icon.png';
// import allImgPaths from 'assets/images';
// import SidebarPendingInvitations from 'pages/dashboard/SidebarPendingInvitations';

interface CustomSelectProps {
	options: optionProp[];
	defaultValue?: optionProp;
	selectedValue?: optionProp | null;
	onChange: (selectedOption: optionProp) => void;
	extendedOption?: boolean;
	formatLabeleOption?: boolean;
	formatOptionLabel?:any;
	className?: string;
	menuPlacement?:string
	placeholder?:string;
	isDisabled?:boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	options,
	defaultValue,
	selectedValue,
	extendedOption,
	className,
	menuPlacement,
	onChange,
	isDisabled = false,
	...props
}) => {
	const handleChange = (selectedOption: any) => {
		onChange(selectedOption);
	};

	// const extendedOptions: ExtendedProps[] = [
	// 	...options,
	// 	{ value: 'addNewTeam', label: 'Add new Team', image: addTeamIcon, },
	// 	{ value: 'pendingInvitation', label: '', },
	// ];

	const customDropdownStyle = {
		singleValue: (styles: any) => ({
			...styles,
			color: "#344054",
		}),
		valueContainer: (styles: any) => ({
			...styles,
			backgroundColor: "#f9fafb",
			padding: "0px",
			fontSize: "14px",
			cursor: "pointer",
		}),
		control: (styles: any) => ({
			...styles,
			backgroundColor: "#f9fafb",
			boxShadow: "none",
			padding: "8px 12px",
			border: "none",
			borderRadius: "8px",
			cursor: "pointer",
		}),
		indicatorContainer: (styles: any) => ({
			...styles,
			padding: "0px 0px",
		}),
		indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
		dropdownIndicator: (styles: any) => ({
			...styles,
			color: "#85888E",
			cursor: "pointer",
			"&:hover": { color: "#85888E" },
			paddingRight: "0px",
		}),
		menu: (styles: any) => ({
			...styles,
			left: "0",
			boxShadow: "0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)",
			backgroundColor: "#fff",
			borderRadius: "12px",
			margin: "0px",
			overflow: "hidden"
		}),
		menuList: (styles: any) => ({
			...styles,
			backgroundColor: "#fff",
			maxHeight: "210px",
			padding: "4px 4px"
		}),
		option: (styles: any, { isFocused }: any) => ({
			...styles,
			cursor: "pointer",
			fontSize: "14px",
			fontWeight: "400",
			color: "#344054",
			padding: "6px 6px 6px 6px",
			borderRadius: "12px",
			marginBottom: "10px",
			backgroundColor: isFocused ? "#fff" : "#fff",
			"&:active": { color: "#1751D0", backgroundColor: '#fff' },
			"&:hover": { color: "#1751D0" }
		}),
		placeholder: (styles: any) => ({
			...styles,
			fontWeight: "500",
			fontSize: "16px",
		}),
	};

	const handleImageError = (e: any) => {
		e.target.src =  '' // allImgPaths.defaultProfile
	}

	return (
		<div>
			<Select
				isDisabled={isDisabled}
				placeholder={'Select'}
				className={ `react-select-wrapper ${className}`}
				classNamePrefix="react-select"
				defaultValue={defaultValue}
				value={selectedValue}
				onChange={handleChange}
				options={options}
				styles={customDropdownStyle}
				// menuIsOpen={true}
				isSearchable={false}
				menuPlacement="auto"
				formatOptionLabel={(value: optionProp) => (
					<>
						<div className="flex items-center">
							{value?.image && <img className="w-[32px] h-[32px] object-cover rounded-full relative z-10 flex-shrink-0" src={value.image} onError={(e: any) => handleImageError(e)} />}
							<div className="ml-2.5 truncate text-base" title={value.label}>{value.label}</div>
						</div>

						{/* {
							value.value == 'pendingInvitation' &&
							<SidebarPendingInvitations />
						} */}

					</>
				)}

				{...props}
			/>

		</div>

	);
};

export default CustomSelect;